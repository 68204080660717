import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import io from 'socket.io-client';
import { useIdleTimer } from 'react-idle-timer';

// Import views/components
import Login from './views/login';
import Signup from './views/signup';
import Manifesto from './views/manifesto';
import NavBar from './views/navbar';
import LandingPage from './views/landingpage';
import Dashboard from './views/dashboard/index';
import MyDashboard from './views/mydashboard';
import RatingDash from './views/ratingdash';
import Leaderboard from './views/leaderboard';
import ContactUS from './views/contactus';
import UpdateProfile from './views/updateprofile';
import Search from './views/dashboard';
import Terms from './views/terms';
import EmailVerification from './views/emailverification';
import UpdateImage from './views/dashboard/uploadimage';
import PleaseVerifyEmail from './views/pleaseverifyemail';
import ContactUsSuccess from './views/contactussuccess';
import CompleteProfile from './views/completeprofile';
import ForgotPassword from './views/forgotpassword';
import ResetPassword from './views/resetpassword';
import RateUnclaimed from './views/rate-unclaimed';
import RateUnclaimedExisting from './views/rate-unclaimed-existing';
import QuickRatingDash from './views/quick/ratingdash';
import QuickSearch from './views/quick/dashboard';
import QuickDash from './views/quick/quick-land/';
import Settings from './views/settings/';
import Messages from './views/messages';
import AboutUs from './views/aboutus';
import RateMe from './views/rateme';
import AdminDash from './views/admin-dashboard/admin-dashboard';
import ManageUsers from './views/admin-dashboard/manage-user';

// Connect to your backend server using the correct URL
const socket = io.connect('https://api.upstar.co.za', { withCredentials: true, });
//const socket = io.connect('http://localhost:1233', { withCredentials: true, });

const App = () => {
  const navigate = useNavigate();

  const logoutAndRedirect = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('msgs');
    localStorage.removeItem('vote');
    localStorage.removeItem('astrangething');
    socket.close();
    navigate('/'); // Use navigate to redirect within the app
  };

  // Idle timer setup
  const handleOnIdle = () => {
    console.log('User is idle');
    logoutAndRedirect();
  };

  useIdleTimer({
    timeout: 1000 * 60 * 5, // 5 minutes
    onIdle: handleOnIdle,
    debounce: 500,
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    const loginID = localStorage.getItem('astrangething');
    // const r = new Rehive({ apiToken: token });

    const handleSocketEvents = (user, loginID) => {
      socket.emit('am i disconnected', { user, loginId: Number(loginID) });

      socket.on('are you disconnected', (data) => {
        if (data.id === user.id && data.status === true) {
          logoutAndRedirect();
        }
      });
    };

    if (token && loginID) {
      r.user.get()
        .then(user => {
          if (!user.id) {
            logoutAndRedirect();
          } else {
            handleSocketEvents(user, loginID);
          }
        })
        .catch(() => {
          logoutAndRedirect();
        });
    }
  }, []);

  const token = localStorage.getItem('token');
  const user = localStorage.getItem('user');

  const NoMatchPage = () => <h3>404 - Not found</h3>;

  return (
    <div>
      <Routes>
        <Route
          path="*"
          element={<NavBar state2={{ socket }} setState={(p) => { }} />}
        />
      </Routes>

      {token && user ? (
        <div className="body">
          <Routes>
            <Route path="/" element={<Dashboard socket={socket} />} />
            <Route path="/settings" element={<Settings socket={socket} />} />
            <Route path="/mydashboard" element={<MyDashboard socket={socket} />} />
            <Route path="/unclaimed-rate" element={<RateUnclaimed socket={socket} />} />
            <Route path="/unclaimed-rate/:id" element={<RateUnclaimedExisting socket={socket} />} />
            <Route path="/leaderboard" element={<Leaderboard socket={socket} />} />
            <Route path="/contactus" element={<ContactUS socket={socket} />} />
            <Route path="/user/:id" element={<RatingDash socket={socket} />} />
            <Route path="/update" element={<UpdateProfile socket={socket} />} />
            <Route path="/search" element={<Search socket={socket} />} />
            <Route path="/update/image" element={<UpdateImage socket={socket} />} />
            <Route path="/email/verification" element={<EmailVerification socket={socket} />} />
            <Route path="/manifesto" element={<Manifesto socket={socket} />} />
            <Route path="/messages" element={<Messages state={{ socket }} />} />
            <Route path="/completeprofile" element={<CompleteProfile socket={socket} />} />
            <Route path="/messages/:id" element={<Messages state={{ socket }} />} />
            <Route path="/rateme" element={<RateMe socket={socket} />} />
            <Route path="/aboutus" element={<AboutUs socket={socket} />} />
            {JSON.parse(user).account_type === 'admin' && (
              <Route path="/admin-dashboard" element={<AdminDash state={{ socket }} setState={(p) => { }} />} />
            )}
            {JSON.parse(user).account_type === 'admin' && (
              <Route path="/manageUsers" element={<ManageUsers />} />
            )}
            <Route path="/please_verify_email" element={<PleaseVerifyEmail />} />
            <Route path="/success" element={<ContactUsSuccess />} />
            <Route path="/reset" element={<ResetPassword />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="*" element={<NoMatchPage />} />
          </Routes>
        </div>
      ) : (
        <div className="body">
          <Routes>
            <Route path="/quick-land" element={<QuickDash socket={socket} />} />
            <Route path="/quick-rate" element={<QuickSearch socket={socket} />} />
            <Route path="/quick-rate/:id" element={<QuickRatingDash socket={socket} />} />
            <Route path="/invite/:x" element={<LandingPage socket={socket} />} />
            <Route path="/" element={<LandingPage socket={socket} />} />
            <Route path="/email/verification" element={<EmailVerification socket={socket} />} />
            <Route path="/login" element={<Login socket={socket} />} />
            <Route path="/login/:x" element={<Login socket={socket} />} />
            <Route path="/signup" element={<Signup socket={socket} />} />
            <Route path="/signup/:x" element={<Signup socket={socket} />} />
            <Route path="/terms" element={<Terms socket={socket} />} />
            <Route path="/manifesto" element={<Manifesto socket={socket} />} />
            <Route path="/please_verify_email" element={<PleaseVerifyEmail />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/reset" element={<ResetPassword />} />
            <Route path="/aboutus" element={<AboutUs socket={socket} />} />
            <Route path="*" element={<NoMatchPage />} />
          </Routes>
        </div>
      )}

      <div
        style={{
          position: 'relative',
          height: '200px',
        }}
      >
        <div
          style={{
            position: 'absolute',
            marginTop: '50px',
            top: 0,
            left: 0,
            right: 0,
            background: 'rgb(46, 149, 198)',
            height: '200px',
            zIndex: 2,
          }}
        >
          <div className="container">
            <br />
            <img
              alt=""
              style={{
                width: '100px',
                height: 'auto',
              }}
              src="/img/logo2sm.png"
            />
            <br />
            <Button
              onClick={() => {
                window.location.href = 'mailto:hello@upstar.co.za';
              }}
              color="primary" // Ensure you specify the color attribute correctly
            >
              hello@upstar.co.za
            </Button>
            <br />
            <span>2024 UpSTAR</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
