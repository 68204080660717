import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import StarComponent from '../components/star_component';
import { TextField, Button, Dialog, FormControl, FormControlLabel, Radio, RadioGroup, CircularProgress, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { voteUnclaimedExisting, unclaimedVotes } from '../utils';

const RateProfile = () => {
  const [rating, setRating] = useState('0');
  const [review, setReview] = useState('');
  const [name, setName] = useState('');
  const [relationship, setRelationship] = useState('');
  const [numVotes, setNumVotes] = useState('');
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(null);
  const [status, setStatus] = useState(null);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);

    unclaimedVotes(id)
      .then(res => {
        setLoading(false);
        setName(res.user.name);
        setNumVotes(res.num_votes);
      });
  }, [id]);

  const handleRatingChange = (nextValue) => {
    setRating(nextValue);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'review') {
      setReview(value);
    } else if (name === 'relationship') {
      setRelationship(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const user = JSON.parse(localStorage.getItem('user'));

    const data = {
      rating,
      review,
      created_by: user.id,
      profile: user.profile,
      created_by_name: `${user.first_name} ${user.last_name}`,
    };

    voteUnclaimedExisting(id, data)
      .then(res => {
        setLoading(false);
        setMsg(res.msg);
        setStatus(res.status);
      });
  };

  const upstarActions = [
    <Button
      key="rate-else"
      variant="contained"
      color="secondary"
      onClick={() => navigate('/search')}
    >
      Rate someone else
    </Button>,
    <Button
      key="check-reviews"
      variant="contained"
      color="secondary"
      onClick={() => navigate('/mydashboard')}
    >
      Check your latest reviews
    </Button>,
  ];

  return (
    <div className='container center'>
      {loading ? (
        <CircularProgress />
      ) : (
        <div>
          <h2>Rate {name}</h2>
          <p>(Unclaimed profile with {numVotes} vote{numVotes !== 1 ? 's' : ''})</p>

          <br />

          <div className='row'>
            <div className='col-6'>
              <p>How do you know {name}?</p>
              <FormControl component="fieldset">
                <RadioGroup
                  name="relationship"
                  value={relationship}
                  onChange={handleChange}
                >
                  <FormControlLabel value="friend" control={<Radio />} label="Friend" />
                  <FormControlLabel value="customer" control={<Radio />} label="Customer" />
                  <FormControlLabel value="boss" control={<Radio />} label="I am his/her manager or boss" />
                  <FormControlLabel value="colleague" control={<Radio />} label="I work with this person" />
                  <FormControlLabel value="subordinate" control={<Radio />} label="He/She is my manager or boss" />
                  <FormControlLabel value="family" control={<Radio />} label="Family" />
                  <FormControlLabel value="other" control={<Radio />} label="Other" />
                </RadioGroup>
              </FormControl>
            </div>
            <div className='col-6'>
              <h3>Rating</h3>
              <p>Not selecting a star will result in a zero rating</p>
              <StarComponent rating={rating} onRatingChange={handleRatingChange} />
              <TextField
                name='review'
                placeholder='Review'
                value={review}
                onChange={handleChange}
                multiline
                rows={2}
                maxRows={4}
                fullWidth
              />
              <br /><br />
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={handleSubmit}
              >
                Submit
              </Button>
              <br /><br />
              <Button
                variant="contained"
                onClick={() => navigate('/search')}
              >
                Back
              </Button>
            </div>
          </div>
          <Dialog
            open={Boolean(msg)}
            onClose={() => {
              setMsg(null);
              navigate('/');
            }}
          >
            <DialogTitle>{msg}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {status === 'success' ? 'Your rating has been submitted successfully.' : 'There was an error submitting your rating.'}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {upstarActions}
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
};

export default RateProfile;
