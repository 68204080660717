import React, { Component } from 'react';
import { Stage, Layer, Rect, Transformer, Image } from 'react-konva';
import { fileUpload } from '../../utils';

import AvatarEditor from 'react-avatar-editor';
import { Button, Dialog, CircularProgress } from '@mui/material/';
import { toCanvas } from 'html-to-image';

class Rectangle extends React.Component {

    handleChange = e => {
        const shape = e.target;
        // take a look into width and height properties
        // by default Transformer will change scaleX and scaleY
        // while transforming
        // so we need to adjust that properties to width and height

        this.props.onTransform({
            x: shape.x(),
            y: shape.y(),
            width: shape.width() * shape.scaleX(),
            height: shape.height() * shape.scaleY(),
            rotation: shape.rotation()
        });
    };

    render() {

        return (
            <Rect
                x={this.props.x}
                y={this.props.y}
                width={this.props.width}
                zIndex={99}
                height={this.props.height}
                // force no scaling
                // otherwise Transformer will change it
                scaleX={1}
                scaleY={1}
                fill={this.props.fill}
                cornerRadius={5}
                name={this.props.name}
                // save state on dragend or transformend
                onDragEnd={this.handleChange}
                onTransformEnd={this.handleChange}
                draggable={this.props.draggable}
                dragBoundFunc={(pos) => {
                    return {
                        x: pos.x,
                        y: pos.y
                    }
                }}
            />
        );
    }
}

class TransformerComponent extends React.Component {
    componentDidMount() {
        this.checkNode();
    }
    componentDidUpdate() {
        this.checkNode();
    }
    checkNode() {
        // here we need to manually attach or detach Transformer node
        const stage = this.transformer.getStage();
        const { selectedShapeName } = this.props;

        const selectedNode = stage.findOne('.' + selectedShapeName);
        // do nothing if selected node is already attached
        if (selectedNode === this.transformer.node()) {
            return;
        }

        if (selectedNode) {
            // attach to another node
            this.transformer.attachTo(selectedNode);
        } else {
            // remove transformer
            this.transformer.detach();
        }
        this.transformer.getLayer().batchDraw();
    }
    render() {
        return (
            <Transformer
                ref={node => {
                    this.transformer = node;
                }}
                rotateEnabled={false}
            />
        );
    }
}

class AvatarImage extends Component {

    state = {
        image: null
    }

    componentDidMount() {
        const image = new window.Image();
        image.src = '/img/avatar.png';
        image.onload = () => {
            this.setState({
                image: image
            })
        }
    }

    render() {
        return <Image image={this.state.image} />;
    }
}

class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showSuccess: false,
            wordbox: {
                x: 50,
                y: 110,
                z: 999,
                width: 200,
                height: 152,
                fill: '#fff',
                name: 'wordbox'
            },

            image: {
                x: 0,
                y: 0,
                z: 999,
                width: 300,
                height: 300,
                fill: '#fff',
                name: 'image'
            },

            selectedShapeName: '',
            loading: false,
            processing: false,

            file: '',
            imagePreviewUrl: null,

            width: 200,
            height: 200,
            border: 50,
            color: [255, 255, 255, 0.6],
            scale: 1,
            rotate: 0,
            croppedImage: '',
            size_exceeded: false
        }
    }




    goBack() {
        this.setState({ croppedImage: '', scale: 1, processing: false });
    }

    handleScale(e) {
        this.setState({
            [e.target.name]: parseInt(e.target.value, 0)
        })
    }

    _handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            if (formatBytes(file.size).includes('MB') && parseInt(formatBytes(file.size).replace("MB", ""), 10) >= 4) {
                this.setState({
                    file: file,
                    imagePreviewUrl: reader.result,
                    displayed: true,
                    size: formatBytes(file.size),
                    size_exceeded: true
                });
            } else {
                this.setState({
                    file: file,
                    imagePreviewUrl: reader.result,
                    displayed: true,
                    size: formatBytes(file.size),
                    size_exceeded: false
                });
            }

        }

        if (file) {
            reader.readAsDataURL(file)
        } else {
            return null;
        }
    }

    handleScale = (e) => {
        this.setState({
            [e.target.name]: parseFloat(e.target.value, 0)
        })
    }

    _handleSubmit(e) {
        e.preventDefault();
        // TODO: do something with -> this.state.file
        console.log('CROPPED IMAGE', this.state.croppedImage);

    }

    onClickSave = (e) => {
        e.preventDefault();
        console.log("we got the imaage");
        const canvas = this.editor.getImage().toDataURL();
        this.setState({ processing: true });
        console.log("processing");

        fetch(canvas)
            .then(res => res.blob())
            .then(blob => {
                console.log("we got the blob imaage");
                const image = new window.Image();
                image.src = canvas

                console.log("done with the imaage");
                this.setState({
                    croppedImage: window.URL.createObjectURL(blob),
                    image: {
                        file: image, x: 0, y: 0, width: 300, height: 300,
                        processing: false
                    }
                })
            });
    }

    setEditorRef = (editor) => this.editor = editor

    handleStageMouseDown = e => {
        // find clicked rect by its name
        const name = e.target.name();
        if (name && name !== 'wordbox') {
            this.setState({
                selectedShapeName: name
            });
        } else {
            this.setState({
                selectedShapeName: ''
            });
        }
    };

    handleRectChange = (type, data) => {
        if (type === 'wordbox') {
            data['fill'] = this.state.wordbox.fill
            data['name'] = this.state.wordbox.name
            this.setState({ wordbox: data });
        } else if (type === 'image') {
            const image = this.state.image

            image['x'] = data.x
            image['y'] = data.y
            this.setState({ image });
        }

    };

    handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            // console.log("READER RESULT", reader.result);
            const image = new window.Image();
            image.src = reader.result;
            image.onload = () => {
                // setState will redraw layer
                // because "image" property is changed
                this.setState({
                    image: { file: image, x: 0, y: 0, width: 300, height: 300 }
                });
            }
        }

        if (file) {
            reader.readAsDataURL(file)
        } else {
            return null;
        }
    }



    async onSubmit(e) {
        e.preventDefault();
        console.log("defaut prevented");
        // console.log( 'Image', this.stage.getStage().toDataURL())
        const canvas = await getCanvas()
        console.log("console created");
        const uri = this.stage.getStage().toDataURL();

        console.log("uri gotten");

        this.setState({ loading: true });

        fetch(uri)
            .then(res => res.blob())
            .then(blob => {
                console.log("blob created");
                let formData = new FormData();
                formData.append('profile', blobToFile(blob, 'profile'))

                fileUpload(formData)
                    .then(results => {
                        console.log("file uploaded ?");
                        console.log('result of upload', results);

                        if (results && results.status === 'success') {
                            console.log("upload success");
                            this.setState({ loading: false, showSuccess: true })
                            document.getElementById('success').appendChild(canvas);
                            var user = JSON.parse(localStorage.getItem('user'));
                            this.props.socket.emit("user-click-event", { event: "profile-image-change", user: localStorage.getItem('user'), status: true });
                            user.profile = results.data.profile
                            localStorage.setItem('user', JSON.stringify(user))
                        }

                        if (results.status === 400) {

                            var user = JSON.parse(localStorage.getItem('user'));
                            this.props.socket.emit("user-click-event", { event: "profile-image-change", user: localStorage.getItem('user'), status: false, msg: results.message });

                            this.setState({
                                err: results.message
                            })
                            console.log(results.message);
                        }
                    })
            });
    }

    render() {

        let { imagePreviewUrl } = this.state;

        const user = JSON.parse(localStorage.getItem('user'));

        const stage = <Stage
            ref={node => { this.stage = node }}
            width={300}
            height={300}
            style={{ boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px', width: '300px' }}
            onMouseDown={this.handleStageMouseDown}
        >
            <Layer>
                {
                    this.state.image ?
                        <Image
                            draggable={true}
                            image={this.state.image.file}
                            x={this.state.image.x}
                            y={this.state.image.y}
                            width={this.state.image.width}
                            height={this.state.image.height}
                            onDragEnd={e => {
                                console.log(e.target.attrs.y)
                                const { x, y } = e.target.attrs

                                if ((x >= -100 && x <= 0) && (y >= -100 && y <= 0)) {
                                    this.handleRectChange('image', {
                                        x,
                                        y
                                    });
                                } else {
                                    this.handleRectChange('image', {
                                        x: 0,
                                        y: 0
                                    });
                                }

                            }}
                        /> :
                        <AvatarImage />
                    // null
                }
                <TransformerComponent
                    selectedShapeName={this.state.selectedShapeName}
                />
                <Rectangle
                    onTransform={() => null}
                    x={this.state.wordbox.x}
                    y={this.state.wordbox.y}
                    width={this.state.wordbox.width}
                    height={this.state.wordbox.height}
                    fill={this.props.card_type === 'page' ? this.state.wordbox.fill : 'transparent'}
                    name={this.state.wordbox.name}
                    draggable={false}
                >
                </Rectangle>


            </Layer>
        </Stage>

        return (
            <div className='container center'>
                {
                    this.state.loading ?

                        <p>Uploading your image</p> :

                        <div >
                            <h3>Add your profile picture</h3>
                            <p style={{ fontSize: '18px' }}>Choose an image of yourself to go behind your word card. A photo of you holding a white card works best, but if you don't have time any pic will do.</p>
                            {
                                this.state.croppedImage ?
                                    <small>
                                        <i>If you cannot see your image, click on the image area to view the image</i>
                                    </small> : null
                            }

                            {
                                !this.state.croppedImage ?
                                    <div className='center'>
                                        {
                                            this.state.imagePreviewUrl ?
                                                <AvatarEditor
                                                    ref={this.setEditorRef}
                                                    image={imagePreviewUrl}
                                                    width={this.state.width}
                                                    height={this.state.height}
                                                    border={this.state.border}
                                                    color={this.state.color}
                                                    scale={this.state.scale}
                                                    rotate={this.state.rotate}
                                                /> :
                                                null
                                        }
                                        <br />
                                        {
                                            this.state.size_exceeded ?
                                                <p>Image size exceeds 4MB, please select a smaller image</p> :
                                                this.state.imagePreviewUrl ?
                                                    <div>
                                                        <div>
                                                            Rotate:
                                                            <br /><br />
                                                            <Button
                                                                label="Left"
                                                                onClick={() => {
                                                                    this.setState({ rotate: this.state.rotate - 90 })
                                                                }}
                                                            />
                                                            <Button
                                                                label="Right"
                                                                onClick={() => {
                                                                    this.setState({ rotate: this.state.rotate + 90 })
                                                                }}
                                                            />
                                                        </div>
                                                        <br />
                                                        <div >Zoom:
                                                            <input
                                                                type="range"
                                                                value={this.state.scale}
                                                                min='1'
                                                                max='10'
                                                                step='0.1'
                                                                name='scale'
                                                                onChange={this.handleScale}
                                                            />
                                                        </div>
                                                        <br />
                                                    </div> :
                                                    null
                                        }<br />

                                        {
                                            !this.state.imagePreviewUrl ?
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                    <input className='custom-file-input' type='file' onChange={this._handleImageChange} />
                                                </div> :
                                                <div>
                                                    <Button
                                                        style={{ marginBottom: '20px' }}
                                                        type='submit'
                                                        label='preview image'
                                                        disabled={!this.state.imagePreviewUrl || this.state.size_exceeded}
                                                        secondary={true}
                                                        onClick={this.onClickSave}
                                                    />
                                                    <br />
                                                    <Button
                                                        style={{ marginBottom: '20px' }}
                                                        type='submit'
                                                        label='Remove'
                                                        primary={true}
                                                        onClick={() => this.setState({ imagePreviewUrl: '', file: '', size_exceeded: false })}
                                                    />
                                                </div>
                                        }
                                        <br />


                                        {
                                            this.state.processing ?
                                                <CircularProgress />
                                                : null
                                        }
                                    </div> :
                                    <div>
                                        <div id="capture">
                                            <div style={{
                                                display: 'flex',
                                                position: 'relative',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                <div style={{
                                                    position: 'absolute',
                                                    paddingTop: '5%',
                                                    zIndex: 102,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-around',
                                                    textAlign: 'center',
                                                    flexDirection: 'column',
                                                    top: '120px',
                                                    width: '100%'
                                                }}>
                                                    {
                                                        user && user.five_words && user.five_words.map((word, index) => (
                                                            <span style={{
                                                                fontSize: '12px',
                                                                color: '#000',
                                                                userSelect: 'none'
                                                            }} key={index}>{word}<br /></span>
                                                        ))
                                                    }
                                                </div>
                                                {stage}
                                            </div>
                                        </div>

                                        <br /><br />
                                        <Button
                                            style={{ marginBottom: '20px' }}
                                            type='submit'
                                            label='Back'
                                            secondary={true}
                                            onClick={() => this.goBack()}
                                        />
                                        <Button
                                            style={{ paddingLeft: 15 }}
                                            type='submit'
                                            label='Upload Image'
                                            secondary={true}
                                            onClick={(e) => this.onSubmit(e)}
                                        />
                                        <br />
                                        <br />
                                    </div>
                            }
                        </div>
                }
                <Dialog
                    className='center'
                    title="Your update has been successful"
                    actions={[
                        <Button
                            label="Cool"
                            primary={true}
                            onClick={() => {
                                window.location.reload()
                            }}

                        />
                    ]}
                    modal={false}
                    open={this.state.showSuccess}
                    onRequestClose={() => {
                        this.setState({ showSuccess: false });
                    }}
                >


                    <div style={{
                        visibility: 'hidden',
                        height: 0,
                        width: 0
                    }} id='success'></div>
                    {/* <p>SAVE YOUR UPSTAR CARD TO SHARE WITH THE WORLD</p>
                <a style={{
                        border: "10px",
                        boxSizing: "border-box",
                        display: "inline-block",
                        fontFamily: "Roboto, sans-serif",
                        cursor: "pointer",
                        textDecoration: "none",
                        margin: "0px",
                        padding: "0px",
                        outline: "none",
                        fontSize: "inherit",
                        fontWeight: "inherit",
                        position: "relative",
                        height: "36px",
                        lineHeight: "36px",
                        width: "100%",
                        borderRadius: "2px",
                        transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
                        backgroundColor: "rgb(255, 64, 129)",
                        textlign: "center"
                }} id='btn-download' download="my-upstar-card.png" onClick={() => {
                    var button = document.getElementById('btn-download');
                    const canvas = document.getElementById('success').firstChild;
                    var dataURL = canvas.toDataURL('image/png');
                    button.href = dataURL;
                }}>DOWNLOAD</a>  */}
                </Dialog>
            </div>
        );
    }
}

export default class extends React.Component {

    constructor(props) {

        super(props);
        var x = localStorage.getItem("user");
        this.props.socket.emit("user-click-event", { event: "update-profile-upload-image page", user: x });


    }

    state = {
        has_paper: false,
        card_type: 'page'
    }

    render() {
        return (
            <div className='container center'>
                <App cancel={() => this.setState({ card_type: null })} card_type={this.state.card_type} socket={this.props.socket} />
            </div>
        )
    }
}

function blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
}

function getCanvas() {
    const node = document.getElementById('capture');
    return toCanvas(node)
      .then(canvas => {
        // Optionally, you can append the canvas to the document body or manipulate it as needed
        // document.body.appendChild(canvas);
        return canvas;
      })
      .catch(error => {
        console.error('Error capturing canvas:', error);
      });
  }

function formatBytes(a, b) { if (0 === a) return "0 Bytes"; var c = 1024, d = b || 2, e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"], f = Math.floor(Math.log(a) / Math.log(c)); return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f] }
