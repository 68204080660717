import React, { Component } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import {getUser} from '../utils'
export default class extends Component {

    state = {
        loading: false,
        push: false,
        user: {}
    }

    componentDidMount() {
       if (this.props.user_id) {
            this.setState({ loading: true })
            getUser(this.props.user_id)
            .then(user => {
                this.setState({ loading: false, user })
            })
       }
    }

    render() {
        const { user, loading } = this.state;
        const { push } = this.props

        const variable_window_height = this.state.mobile ? window.innerWidth/2 : window.innerWidth / 4;

        return (
            <div onClick={() => {
                push && push('/user/' + user.id)
            }} style={{display: 'flex', justifyContent: 'center', alignContent: 'center', position: 'relative'}}>
                {
                    loading ?
                    <div className='center'><CircularProgress size={40} thickness={5} /></div> :
                    <div style={{
                        position: 'relative',
                        width: '100%',
                        borderRadius: '100%',
                        maxWidth: '80px'
                    }}>
                        <div style={{
                            position: 'absolute',
                            paddingTop: '5%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            flexDirection: 'column',
                            top: '38px',
                            maxWidth: '80px',
                            width: '100%',
                            textAlign: 'center'
                        }}>
                            {
                                user && user.profile && user.five_words && user.five_words.map((word, index) => (
                                    word && <p style={{
                                        color: "#000",
                                        fontSize: '3.4px',
                                        lineHeight: '0.01px'
                                    }} key={index}>{word}</p>
                                ))
                            }
                        </div>
                        <img 
                            style={{
                                marginTop : '10px',
                                height: '80px',
                                maxWidth: '80px',
                                width: '80px',
                                borderRadius: '100%',
                                objectFit: 'cover'
                            }} 
                            src={user.profile || 'img/avatar.png'} 
                            className='container' 
                            alt=''
                        />
                    </div>
                }
            </div>
        )
    }
}