import React, { Component } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { userlist } from '../utils';
import PicNWordsSmall from '../components/pic-n-words-small';

export default class Leaderboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            loading: false
        };
        var x = localStorage.getItem("user");
        this.props.socket.emit("user-click-event", { event: "leaderboard page", user: x });
    }

    componentDidMount() {
        this.setState({ loading: true });
        userlist().then(results => {
            this.setState({
                loading: false,
                users: results
            });
        });
    }

    render() {
        const top_users = this.state.users
            .sort((a, b) => parseFloat(b.votes) - parseFloat(a.votes))
            .filter((user, index) => index < 20 && user.show_profile === true);

        const logged_user = JSON.parse(localStorage.getItem('user'));
        const user_id = logged_user.id;

        return (
            <div className=''>
                <div className='center'>
                    <div className='row'>
                        <div>
                            <h3>Leaderboard</h3>
                            {this.state.loading ? (
                                <div className='center' style={{ marginTop: '100px' }}>
                                    <CircularProgress size={90} thickness={6} />
                                </div>
                            ) : (
                                <Paper style={{ backgroundColor: '#fff', color: '#343434', display: 'block', overflow: 'hidden', width: '100%' }}>
                                    <TableContainer>
                                        <Table
                                            style={{ backgroundColor: 'rgb(255, 255, 255)', tableLayout: 'auto', width: '100%', overflowX: 'hidden' }}
                                            sx={{ minWidth: 650 }}
                                        >
                                            <TableHead>
                                                <TableRow style={{ backgroundColor: '#fff', color: '#343434' }}>
                                                    <TableCell>Ranking</TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Rating</TableCell>
                                                    <TableCell>Company</TableCell>
                                                    <TableCell>Suburb</TableCell>
                                                    <TableCell>Position</TableCell>
                                                    <TableCell>This month</TableCell>
                                                    <TableCell>Rated</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {top_users.map((user, index) => {
                                                    const avg = user.avg_last_month !== null && user.avg_last_month !== undefined
                                                        ? (user.votes.toFixed(1) - user.avg_last_month.toFixed(1)).toFixed(1)
                                                        : user.votes || 0;

                                                    return (
                                                        <TableRow key={index} style={{ color: '#343434' }}>
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>
                                                                <PicNWordsSmall user_id={user.id} />
                                                            </TableCell>
                                                            <TableCell>{user.first_name + ' ' + user.last_name}</TableCell>
                                                            <TableCell>
                                                                {user.number_of_votes === 0 ? (
                                                                    'Not Rated'
                                                                ) : (
                                                                    <>
                                                                        <StarIcon style={{ color: '#2E95C6', height: '20px', marginBottom: '-3px' }} />
                                                                        {user.votes && user.votes.toFixed(1)}
                                                                    </>
                                                                )}
                                                            </TableCell>
                                                            <TableCell>{user.user_company}</TableCell>
                                                            <TableCell>{user.suburb}</TableCell>
                                                            <TableCell>{user.position}</TableCell>
                                                            <TableCell>
                                                                {avg}{' '}
                                                                {avg > 0 ? (
                                                                    <ArrowUpwardIcon style={{ color: '#2E95C6', marginBottom: '-10px' }} />
                                                                ) : avg < 0 ? (
                                                                    <ArrowDownwardIcon style={{ color: '#2E95C6', marginBottom: '-10px' }} />
                                                                ) : null}
                                                            </TableCell>
                                                            <TableCell>{user.number_of_votes}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
