import React, { Component } from 'react';
import { userlist, unclaimedUserlist } from '../utils';
import { Avatar, TextField, CircularProgress } from '@mui/material';

export default class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            unclaimed_users: [],
            loading: false,
            selectedPerson: null,
            logged_in_user : JSON.parse(localStorage.getItem('user')),
            search_value: ''
        }
        this.handleChange = this.handleChange.bind(this);

        var x = localStorage.getItem("user");
        this.props.socket.emit("user-click-event" , {event:"search page", user:x} );

    }

    getUsers() {
        this.setState({ loading: true })
        userlist()
        .then((results) => {
            // console.log('USERS', results)
            unclaimedUserlist()
            .then(unclRes => {

            
                this.setState({ 
                    loading: false,   
                    users: results,
                    unclaimed_users: unclRes
                })
            })
        })
    }

    handleChange = (e) => {
        if (this.state.search_value.length === 1 && (this.state.users.length < 1 || !this.state.users) && !this.state.loading) {
            this.getUsers();
        }
        this.setState({ [e.target.name]: e.target.value})
    }

    handleClose = () => {
        this.setState({ selectedPerson: null })
    } 

    render() {
        console.log(this.state.users);

        if (this.state.search_value){
            var x = localStorage.getItem("user");
            this.props.socket.emit("search-event" , {event:"search", user:x, value: this.state.search_value } );
        }

        const result = (this.state.users.concat(this.state.unclaimed_users)).filter(user => {
            
            let search = this.state.search_value && this.state.search_value.toLowerCase().trim()
            let email = user.email && user.email.toLowerCase().trim()
            let name = user.first_name && user.first_name.toLowerCase().trim();
            let last_name = user.last_name && user.last_name.toLowerCase().trim();

            if (search.split(" ").lenght > 1) {

            }
            
            let unclaimed_name = user && user.name && user.name.toLowerCase().trim();
            return (
                (user && unclaimed_name && unclaimed_name.toLowerCase().includes(search)) || 
                (user && name && name.toLowerCase().includes(search)) || 
                (user && last_name && last_name.toLowerCase().includes(search)) ||
                (user && email && email.toLowerCase().includes(search)) || 
                (user && last_name && last_name.toLowerCase().includes(search)) ||
                ((user && name && search && search.split(" ")[0] && name.toLowerCase().includes(search.split(" ")[0])) &&
                (user && last_name && search && search.split(" ")[1] && last_name.toLowerCase().includes(search.split(" ")[1])) )
            );
        });


        return (
            <div className='container'>
                 <div className='center'>
                    <h1>Search</h1>
                    <p>Search for someone to rate</p>
                    <TextField 
                            autoComplete="off"
                            placeholder='Search for a person'
                            name='search_value'
                            type='text'
                            value={this.state.search_value}
                            onChange={this.handleChange}
                        />
                </div>
                {
                    this.state.loading ?
                    <div className='center'>
                        <br/><br/>
                        Please be patient while we search for {this.state.search_value}...
                        <br/><br/>
                        <CircularProgress size={80} thickness={5} />
                    </div> :
                        <div>
                            {
                            this.state.search_value.length >= 2 ? 
                            <div>
                                {
                                    result.map((user, index) => {
                                        if(user.id === this.state.logged_in_user.id) {
                                            return null
                                        }
                                        return (
                                        <div key={index}>
                                            {
                                                renderUsers(user, () => {
                                                if (user.name) {
                                                    this.props.history.push('/unclaimed-rate/' + user._id)
                                                } else {
                                                    this.props.history.push('/user/' + user.id)
                                                }
                                            }, index)
                                            }
                                        </div>
                                        )
                                    })
                                }
                            
                                <div style={{
                                    cursor: 'pointer'
                                }} onClick={() => this.props.history.push('/unclaimed-rate')} className='row'>
                                    <div className='col-4-sm center'>
                                        <Avatar color={""} size={80}>?</Avatar>
                                    </div>
                                    <div className='col-4-sm center'>
                                        <br/>
                                        Can't find who youre looking for?
                                        <br/>
                                        <small>Click to create a profile</small>
                                    </div>
                                    <div className='col-4-sm right'>
                                        <br/>
                                    </div>
                                    <div className='col-12-sm'>
                                        <hr/>
                                    </div>
                                </div>
                            </div>
                                :
                            null
                            
                            }
                        </div>
                }
            </div>
        )
    }
}

const renderUsers = (user, push, index) => (
    <div style={{
        cursor: 'pointer'
    }} onClick={push} className='row' key={index}>
        <div className='col-8-sm left'>
            <h4>{user.name || (user.first_name + ' ' + user.last_name)}</h4>
            {
                user.user_company ? <span>Works at {user.user_company}</span> :
                user.company ? <span>Works at {user.company}</span> : null
            }
            <br/>
            {
                //<small>{user.email}</small>//
            }
        </div>
        <div className='col-4-sm right'>
            <br/>
            <Avatar size={50} backgroundColor='#2E95C6' color='#FFF'> {user.votes && user.votes.toFixed(1)} </Avatar>
        </div>
        <div className='col-12-sm'>
            <hr/>
        </div>
    </div>
)