import React, { useState, useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress';

import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
// import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import UserActivity from './useractivity';
import { userActions } from '../../utils';


import BlockIcon from '@mui/icons-material/Block';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import FlagIcon from '@mui/icons-material/Flag';
import { getUser, changeAccountStatus, adminSendMail } from '../../utils';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog'

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '95%',
  },
}));

export default function FullWidthTabs(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState(null);
  const [changes, setChanges] = useState(false);
  const [open, setOpen] = useState(false);
  const [res, setRes] = useState(false);

  var userO = JSON.parse(localStorage.getItem('user'));

  const actions = [
    <Button
      label="Okay"
      primary={true}
      onClick={handleClose}
    />
  ];


  const handleClose = () => {
    setOpen(false);
  }


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const changeActiveStatus = function (e) {

    if (props.user) {

      setLoading(true);
      getUser(props.user).
        then((data) => {

          if (data) {
            var status = !props.enabled;
            var user = JSON.parse(localStorage.getItem('user'));
            //setChanges(true);
            //setOpen(true);
            changeAccountStatus({ id: props.user, account_status: status, by: user.id }).
              then(data3 => {

                if (data3.status === true) {
                  adminSendMail({
                    b_id: user.id,
                    by: user.email,
                    to_id: props.user,
                    to: data.email,
                    name: data.first_name,
                    subject: "Your account has been " + (status ? "Activated" : "Deactivated"),
                    message: "We would like to inform you that your account has been " + (status ? "Activated" : "Deactivated") + " by us.",
                    message2: (status ? "You may now use your account again and this time please abide by our rules." :
                      "If you believe this is an error or would like to know why you've been blocked, please contact us at: hello@upstar.co.za .")
                  }).
                    then(data2 => {
                      if (data2.status === true) {
                        //
                        //               setRes(true);
                        //             setChanges(false);
                      }
                    })

                  props.setEnabled(!props.enabled);
                  setLoading(false);


                }
                else {

                  // setRes(false);
                  //setChanges(false);
                }

              })

          }





        });



    }



  }

  useEffect(() => {

    if (props.user) {

      setLoading(true);
      userActions({ query: { by: props.user }, limit: 50 }).
        then(data2 => {

          if (data2.err) {

            setListData(["This user has not yet done anything"])

          }
          else {

            setListData(data2);

          }
          setLoading(false);

        })


    }

  }, [props.user])

  const handleChangeIndex = index => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Summary" {...a11yProps(0)} />
          <Tab label="Activity" {...a11yProps(1)} />
          <Tab label="Actions" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      {/* <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      > */}
        <TabPanel value={value} index={0} dir={theme.direction}>
          <UserActivity user={props.user} setEnabled={props.setEnabled} />
        </TabPanel>

        <TabPanel value={value} index={1} dir={theme.direction}>

          <List>
            {loading ?
              <div className='center'><CircularProgress size={40} thickness={5} /></div>
              :

              listData && listData.map(u => (
                typeof (u) === "String" ?
                  <ListItem button>
                    <ListItemText primary={u} />
                  </ListItem>
                  :
                  <ListItem button>
                    <ListItemText primary={u.action || u.event} />
                  </ListItem>

              ))
            }
          </List>

        </TabPanel>

        <TabPanel value={value} index={2} dir={theme.direction}>
          {loading ?
            <div className='center'><CircularProgress size={40} thickness={5} /></div>
            :

            <Grid container justifyContent="space-between">

              {!props.user && !props.enabled ?
                <Grid item>
                  <IconButton
                    color="primary"
                    aria-label="add"
                    style={{ backgroundColor: "red" }}
                    onClick={changeActiveStatus}
                    size="large">
                    <BlockIcon />
                  </IconButton>
                  Block User
                </Grid>
                :

                userO.id !== props.user ?
                  (props.user && props.enabled && props.enabled == true) ?
                    <Grid item>
                      <IconButton
                        color="primary"
                        aria-label="add"
                        style={{ backgroundColor: "red" }}
                        onClick={changeActiveStatus}
                        size="large">
                        <BlockIcon />
                      </IconButton>
                      Block Account
                    </Grid>
                    :
                    <Grid item>
                      <IconButton
                        color="primary"
                        aria-label="add"
                        style={{ backgroundColor: "green" }}
                        size="large">
                        <CheckIcon />
                      </IconButton>
                      Activate Account
                    </Grid>
                  :
                  null
              }


              {userO.id !== props.user ?

                <Grid item>

                  <IconButton
                    color="primary"
                    aria-label="add"
                    style={{ backgroundColor: "orange" }}
                    size="large">
                    <FlagIcon />
                  </IconButton>
                  Warn User
                </Grid>
                :
                null
              }

              {userO.id !== props.user ?

                <Grid item>

                  <IconButton size="large">
                    <EditIcon />
                  </IconButton>

                  Message User
                </Grid>
                :
                null
              }

              {userO.id !== props.user ?
                null
                :
                "No actions for you..."
              }

              {
                changes ?
                  <Dialog
                    modal={true}
                    open={open}
                  >
                    <p>Updating.....</p>
                  </Dialog> :
                  <Dialog
                    className='center'
                    title={res ? "Your update has been successful" : "There was an error making the updates"}
                    actions={actions}
                    modal={false}
                    open={open}
                    onRequestClose={handleClose}
                  >
                  </Dialog>
              }



            </Grid>
          }
        </TabPanel>
      {/* </SwipeableViews> */}
    </div>
  );
}