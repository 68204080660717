import React, { useState, useEffect } from 'react';
import GridLandingPage from './gridlandingpage';
import { Button } from '@mui/material';
import { alreadyVoted } from '../../utils';
import { useNavigate } from 'react-router-dom';

const HomePage = ({ socket }) => {
    const [completeProfile, setCompleteProfile] = useState(false);
    const [user, setUser] = useState(null); // State to store user information
    const navigate = useNavigate();

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser); // Update the user state
        console.log(storedUser);

        if (
            !storedUser?.company ||
            !storedUser?.industry ||
            !storedUser?.position ||
            !storedUser?.suburb ||
            !storedUser?.province ||
            !storedUser?.five_words ||
            storedUser.five_words.length < 4
        ) {
            setCompleteProfile(true);
        }

        // Emit user-click-event when component mounts
        socket.emit("user-click-event", { event: "home page", user: storedUser });

        // Handle the voting logic
        const vote = JSON.parse(localStorage.getItem("vote"));
        if (vote) {
            alreadyVoted({ user: storedUser, vote: vote })
                .then(data => {
                    if (data.stat === true) {
                        console.log("already voted");
                    } else {
                        // Navigate to user that must be voted for.
                        navigate('/user/' + vote.invitee);
                    }
                })
                .catch(error => {
                    console.error('Error checking vote status:', error);
                });
        }
    }, [socket, navigate]);

    return (
        <div>
            {completeProfile && user ? (
                <div className='center'>
                    <h2>Complete Profile</h2>
                    <p>Your profile is incomplete. Incomplete items include:</p>
                    {!user.company && <p> + Place of Work?</p>}
                    {!user.industry && <p> + What industry are you in?</p>}
                    {!user.suburb && <p> + The suburb you live in?</p>}
                    {!user.position && <p> + Your position at work?</p>}
                    {!user.province && <p> + The province you live in?</p>}
                    {!user.five_words && <p> + The 5 words that describe you?</p>}
                    {user.five_words && user.five_words.length < 4 && <p> + You need to enter 5 words that describe you.</p>}
                    <p>Would you like to complete your profile now?</p>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate('/mydashboard')}
                    >
                        Yes
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => setCompleteProfile(false)}
                    >
                        No
                    </Button>
                </div>
            ) : (
                <GridLandingPage push={navigate} />
            )}
        </div>
    );
};

export default HomePage;
