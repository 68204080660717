import React, { Component } from 'react';
import Tiler from './Tiler';
import { userlist } from '../../../utils';
import CircularProgress from '@mui/material/CircularProgress';
import {Button} from '@mui/material';

export default class ImageGrid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users : [],
            loading : false,
            tile_width: 200
        }
    }

    componentDidMount() {
        this.setState({ loading : true});
        userlist()
        .then( results => {
            // console.log('USERS', results)
            this.setState({
                loading : false,
                users : results
            })

            window.addEventListener('resize', () => {
                if (window.innerWidth < 500) {
                    this.setState({ tile_width: 100 })
                } else if (window.innerWidth > 499) {
                    this.setState({ tile_width: 200})
                }
            })
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => {})
    }

    render () {
        const {users} = this.state;
        
        // get all the profile pictures
    //     let usersWithProfiles = users.map(( user, index) => {
    //         return user.profile
    //     })
        
    //     // Make a list of users with pictures
    //     let filteredList = usersWithProfiles.filter(function (item) {
    //         return item !== null;
    //     })
    //    console.log( 'filtered list', filteredList)

        
        let userProfile = users.filter(u => u.profile).map(( user, i) => {
        
            return (
                    {
                        image: user.profile,
                        user_id: user.id   
                    }
            )
        })
        // console.log(userProfile)
        
        return (
            <div>
                {
                    this.state.loading ?
                        <div className= 'center' style={{ marginTop: '100px'}}>
                            <CircularProgress size={90} thickness={6} />
                        </div> :

                        <div>
                            <div className='container center'>
                                <Button 
                                    style={{ margin: '15px'}}
                                    label="Quick Rate" 
                                    primary={true} 
                                    type='submit'
                                    onClick={() => { this.props.push('/quick-rate')}}
                                />
                                <Button 
                                    style={{ margin: '15px'}}
                                    label="Logout of quick mode" 
                                    primary={true} 
                                    onClick={() => { 
                                        localStorage.removeItem('user')
                                        localStorage.removeItem('msgs');
                                        window.location = '/'
                                    }}
                                />
                                
                            </div>
                        {/* brings back the users that are there */}
                        <Tiler 
                            push={this.props.push}
                            images = { userProfile } 
                            minWidth={this.state.tile_width}
                        />
                        </div>
                        
                 }
            </div>
        );
    }

}