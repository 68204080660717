import React, { Component } from 'react';
import { Button, TextField, Dialog } from '@mui/material';
import { resetPassword } from '../utils';
import './../simple-grid.css';

export default class Forgotpassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            open: false,
            err: null,
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    navigate = (path) => {
        this.props.history.push(path)
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    refresh = () => {
        window.location.reload();
    }

    onSubmit(e) {
        e.preventDefault();

        // const user = JSON.parse(localStorage.getItem('user'))
        // console.log("USER ", user.company);

        const data = {
            user: this.state.email
        }

        this.setState({ loading: true })

        resetPassword(data)
            .then(res => {
                console.log("RESPONSE", res)
                if (res.status === 400) {
                    this.setState({ open: false, err: res.message })
                } else {
                    this.setState({ loading: false, open: true })
                }
            })

    }

    render() {

        const actions = [
            <Button
                label="Cool"
                primary={true}
                // onClick={this.handleClose}
                onClick={this.refresh}
            />
        ];

        return (
            <div className='container center'>
                <div>
                    <img className='image-container' src='/img/logo.png' alt='' />
                </div>
                <h2>Forgot password?</h2>
                <p>Enter your email and we'll send you a link to get back into your account</p>
                <form onSubmit={this.onSubmit}>
                    <TextField
                        name='email'
                        placeholder='Email'
                        type='text'
                        value={this.state.email}
                        onChange={this.handleChange}
                    /><br />
                    {
                        this.state.err ?
                            <span style={{ color: 'red' }}>{this.state.err}<br /></span> :
                            null
                    }
                    <br />
                    <Button
                        style={{ marginRight: '15px' }}
                        label="Back"
                        primary={true}
                        onClick={() => { this.navigate('/login') }}
                    />
                    <Button
                        type='submit'
                        style={{ marginBottom: '10px' }}
                        primary={true}
                        label='Submit'
                    />
                    {/* pop up */}
                    <Dialog
                        className='center'
                        title="Thank you! "
                        actions={actions}
                        modal={false}
                        open={this.state.open}
                        onRequestClose={this.handleClose}
                    >
                        Your email has been submitted.  Please have a look in your inbox.
                    </Dialog>
                </form>

            </div>
        )
    }
}