export default {
    marketing: [
        "Marketing Specialist",
        "Marketing Manager",
        "Marketing Director",
        "Graphic Designer",
        "Marketing Research Analyst",
        "Marketing Communications Manager",
        "Marketing Consultant",
        "Product Manager",
        "Public Relations",
        "Social Media Assistant",
        "Brand Manager",
        "SEO Manager",
        "Content Marketing Manager",
        "Copywriter",
        "Media Buyer",
        "Digital Marketing Manager",
        "eCommerce Marketing Specialist",
        "Brand Strategist",
        "Vice President of Marketing",
        "Media Relations Coordinator"
    ],
    business: [
        "Administrative Assistant",
        "Account Executive",
        "Branch Manager",
        "Business Manager",
        "Office Manager",
        "Quality Control Coordinator",
        "Administrative Manager",
        "Chief Executive Officer",
        "Business Analyst",
        "Risk Manager",
        "Human Resources",
        "Office Assistant",
        "Receptionist",
        "Secretary",
        "Office Clerk",
        "File Clerk",
        "Account Collector",
        "Administrative Specialist",
        "Auditing Clerk",
        "Bookkeeper",
        "Executive Assistant",
        "Program Administrator",
        "Program Manager",
        "Administrative Analyst",
        "Data Entry",
        "CEO—Chief Executive Officer",
        "COO—Chief Operating Officer",
        "CFO—Chief Financial Officer",
        "CIO—Chief Information Officer",
        "CTO—Chief Technology Officer",
        "CMO—Chief Marketing Officer",
        "CHRO—Chief Human Resources Officer",
        "CDO—Chief Data Officer",
        "CPO—Chief Product Officer",
        "CCO—Chief Customer Officer",
        "Team Leader",
        "Manager",
        "Assistant Manager",
        "Executive",
        "Director",
        "Coordinator",
        "Administrator",
        "Controller",
        "Officer",
        "Organizer",
        "Supervisor",
        "Superintendent",
        "Head",
        "Overseer",
        "Chief",
        "Foreman",
        "Controller",
        "Principal",
        "President",
        "Lead",
        "CEO",
        "Executive Assistant",
        "Proprietor",
        "Principal",
        "Owner",
        "President",
        "Founder",
        "Administrator",
        "Director",
        "Managing Partner",
        "Managing Member",
        "Shareholder",
        "Manager",
        "Supervisors",
        "Front-Line Employee",
        "Quality Control",
        "Human Resources",
        "Accounting Staff",
        "Marketing Staff",
        "Purchasing Staff",
        "Shipping and Receiving Staff",
        "Office Manager",
        "Receptionist",
        "Operations Manager",
        "Operations Assistant",
        "Operations Coordinator",
        "Operations Analyst",
        "Operations Director",
        "Vice President of Operations",
        "Operations Professional",
        "Scrum Master",
        "Continuous Improvement Lead",
        "Continuous Improvement Consultant",
        "Chief People Officer",
        "VP of Miscellaneous Stuff",
        "Chief Robot Whisperer",
        "Director of First Impressions",
        "Culture Operations Manager",
        "Director of Ethical Hacking",
        "Director of Bean Counting",
        "Digital Overlord",
        "Director of Storytelling",
        "Personal Assistant",
        "Entrepreneur",
        "Management Consultant",
        "Personal Assistant"
    ],
    it: [
        "Computer Scientist",
        "IT Professional",
        "UX Designer & UI Developer",
        "SQL Developer",
        "Web Designer",
        "Web Developer",
        "Help Desk Worker/Desktop Support",
        "Software Engineer",
        "Data Entry",
        "DevOps Engineer",
        "Computer Programmer",
        "Network Administrator",
        "Information Security Analyst",
        "Artificial Intelligence Engineer",
        "Cloud Architect",
        "IT Manager",
        "Technical Specialist",
        "Application Developer",
        "Chief Technology Officer (CTO)",
        "Chief Information Officer (CIO)",
        "Software Ninjaneer"
    ],
    sales: [
        "Sales Associate",
        "Sales Representative",
        "Sales Manager",
        "Retail Worker",
        "Store Manager",
        "Sales Representative",
        "Sales Manager",
        "Real Estate Broker",
        "Sales Associate",
        "Cashier",
        "Store Manager",
        "Account Executive",
        "Account Manager",
        "Area Sales Manager",
        "Direct Salesperson",
        "Director of Inside Sales",
        "Outside Sales Manager",
        "Sales Analyst",
        "Market Development Manager",
        "B2B Sales Specialist",
        "Sales Engineer"
    ],
    construction: [
        "Construction Worker",
        "Taper",
        "Plumber",
        "Heavy Equipment Operator",
        "Vehicle or Equipment Cleaner",
        "Carpenter",
        "Electrician",
        "Painter",
        "Welder",
        "Handyman",
        "Boilermaker",
        "Crane Operator",
        "Building Inspector",
        "Pipefitter",
        "Sheet Metal Worker",
        "Iron Worker",
        "Mason",
        "Roofer",
        "Solar Photovoltaic Installer",
        "Well Driller",
    ],
    customer_service: [
        "Virtual Assistant",
        "Customer Service",
        "Customer Support",
        "Concierge",
        "Help Desk",
        "Customer Service Manager",
        "Technical Support Specialist",
        "Account Representative",
        "Client Service Specialist",
        "Customer Care Associate",
    ],
    finance_accounting: [
        "Credit Authorizer",
        "Credit Counselor",
        "Accountant",
        "Bookkeeper",
        "Accounting Analyst",
        "Accounting Director",
        "Accounts Payable/Receivable Clerk",
        "Auditor",
        "Budget Analyst",
        "Controller",
        "Financial Analyst",
        "Finance Manager",
        "Economist",
        "Payroll Manager",
        "Payroll Clerk",
        "Financial Planner",
        "Financial Services Representative",
        "Finance Director",
        "Commercial Loan Officer",
        "Actuary",
    ],
    engineering: [
        "Engineer",
        "Mechanical Engineer",
        "Civil Engineer",
        "Electrical Engineer",
        "Assistant Engineer",
        "Chemical Engineer",
        "Chief Engineer",
        "Drafter",
        "Engineering Technician",
        "Geological Engineer",
        "Biological Engineer",
        "Maintenance Engineer",
        "Mining Engineer",
        "Nuclear Engineer",
        "Petroleum Engineer",
        "Plant Engineer",
        "Production Engineer",
        "Quality Engineer",
        "Safety Engineer",
        "Sales Engineer"
    ],
    researcher_analyst: [
        "Researcher",
        "Research Assistant",
        "Data Analyst",
        "Business Analyst",
        "Financial Analyst",
        "Biostatistician",
        "Title Researcher",
        "Market Researcher",
        "Title Analyst",
        "Medical Researcher",
        "Epidemiologist",
    ],
    education: [
        "Mentor",
        "Tutor/Online Tutor",
        "Mentor",
        "Tutor/Online Tutor",
        "Teacher",
        "Teaching Assistant",
        "Substitute Teacher",
        "Preschool Teacher",
        "Test Scorer",
        "Online ESL Instructor",
        "Professor",
        "Assistant Professor",
        "Professor",
        "Principal",
        "Headmaster",
        "Lecturer",
        "Assistant Lecturer",
        "Senior Lecturer",
        "Mathematician"
    ],
    arts: [
        "Artist",
        "Architect",
        "Actor",
        "Graphic Designer",
        "Interior Designer",
        "Video Editor",
        "Video or Film Producer",
        "Playwright",
        "Musician",
        "Novelist/Writer",
        "Computer Animator",
        "Photographer",
        "Camera Operator",
        "Sound Engineer",
        "Motion Picture Director",
        "Music Producer",
        "Director of Photography"
    ],
    healthcare: [
        "Nurse",
        "Travel Nurse",
        "Nurse Practitioner",
        "Doctor",
        "Caregiver",
        "CNA",
        "Physical Therapist",
        "Pharmacist",
        "Pharmacy Assistant",
        "Medical Administrator",
        "Medical Laboratory Tech",
        "Physical Therapy Assistant",
        "Massage Therapy",
        "Dental Hygienist",
        "Orderly",
        "Personal Trainer",
        "Massage Therapy",
        "Medical Laboratory Tech",
        "Phlebotomist",
        "Medical Transcriptionist",
        "Telework Nurse/Doctor",
        "Reiki Practitioner",
        "Physical Therapy Assistant",
        "Archivist"
    ],
    hospitality: [
        "Housekeeper",
        "Flight Attendant",
        "Travel Agent",
        "Hotel Front Door Greeter",
        "Bellhop",
        "Cruise Director",
        "Entertainment Specialist",
        "Hotel Manager",
        "Front Desk Associate",
        "Front Desk Manager",
        "Concierge",
        "Group Sales",
        "Event Planner",
        "Porter",
        "Spa Manager",
        "Wedding Coordinator",
        "Cruise Ship Attendant",
        "Casino Host",
        "Hotel Receptionist",
        "Reservationist",
        "Events Manager",
        "Meeting Planner",
        "Lodging Manager",
        "Director of Maintenance",
        "Valet"
    ],
    food_service:[
        "Waiter/Waitress",
        "Server",
        "Chef",
        "Fast Food Worker",
        "Barista",
        "Line Cook",
        "Cafeteria Worker",
        "Restaurant Manager",
        "Wait Staff Manager",
        "Bus Person",
        "Restaurant Chain Executive",
        "Bartender",
        "Host / Hostess"
    ],
    scientist: [
        "Political Scientist",
        "Chemist",
        "Conservation Scientist",
        "Sociologist",
        "Biologist",
        "Geologist",
        "Physicist",
        "Astronomer",
        "Atmospheric Scientist",
        "Molecular Scientist",
        "HVAC Technician"
    ],
    call_center: [
        "Call Center Representative",
        "Customer Service",
        "Telemarketer",
        "Telephone Operator",
        "Phone Survey Conductor",
        "Dispatcher for Trucks or Taxis",
        "Customer Support Representative",
        "Over the Phone Interpreter",
        "Phone Sales Specialist",
        "Mortgage Loan Processor",
    ],
    counselling: [
        "Counselor",
        "Mental Health Counselor",
        "Addiction Counselor",
        "School Counselor",
        "Speech Pathologist",
        "Guidance Counselor",
        "Social Worker",
        "Therapist",
        "Life Coach",
        "Couples Counselor",
        "Career Coach",
    ],
    cosmetology: [
        "Beautician",
        "Hair Stylist",
        "Nail Technician",
        "Cosmetologist",
        "Salon Manager",
        "Makeup Artist",
        "Esthetician",
        "Skin Care Specialist",
        "Manicurist",
        "Barber"
    ],
    journalism_writing: [
        "Journalist",
        "Copy Editor",
        "Editor/Proofreader",
        "Content Creator",
        "Speechwriter",
        "Communications Director",
        "Screenwriter",
        "Technical Writer",
        "Columnist",
        "Public Relations Specialist",
        "Proposal Writer",
        "Content Strategist",
        "Grant Writer",
        "Video Game Writer",
        "Translator",
        "Film Critic",
        "Copywriter",
        "Travel Writer",
        "Social Media Specialist",
        "Ghostwriter"
    ],
    worker: [
        "General Worker",
        "Warehouse Worker",
        "Painter",
        "Heavy Equipment Operator",
        "Welding",
        "Housekeeper",
        "Landscaping Worker",
        "Mover",
        "Security Guard",
        "Mechanic",
        "Locksmith",
        "Caretaker or House Sitter",
        "Parking Attendant",
        "Machinery Operator",
        "Manufacturing Assembler",
        "Funeral Attendant"
    ],
    animal_services: [
        "Animal Breeder",
        "Veterinary Assistant",
        "Farm Worker",
        "Animal Shelter Worker",
        "Dog Walker / Pet Sitter",
        "Zoologist",
        "Animal Trainer",
        "Service Dog Trainer",
        "Animal Shelter Manager",
        "Animal Control Officer"
    ],
    driver: [
        "Delivery Driver",
        "School Bus Driver",
        "Truck Driver",
        "Tow Truck Operator",
        "UPS Driver",
        "Mail Carrier",
        "Recyclables Collector",
        "Courier",
        "Bus Driver",
        "Uber eats driver",
        "Uber driver",
        "Cab Driver"
    ],
    volunteer: [
        "Animal Shelter Board Member",
        "Office Volunteer",
        "Animal Shelter Volunteer",
        "Hospital Volunteer",
        "Youth Volunteer",
        "Food Kitchen Worker",
        "Homeless Shelter Worker",
        "Conservation Volunteer",
        "Meals on Wheels Driver",
        "Habitat for Humanity Builder",
        "Emergency Relief Worker",
        "Red Cross Volunteer",
        "Community Food Project Worker",
        "Women’s Shelter Jobs",
        "Suicide Hotline Volunteer",
        "School Volunteer",
        "Community Volunteer Jobs",
        "Sports Volunteer",
        "Church Volunteer"
    ],
    recruitment: [
        "Recruiter"
    ],
    food_retail: [
        "Shelf Stocker"
    ],
    library: [
        "Library Assistant"
    ],
    venicular: [
        "Translator"
    ],
    law: [
        "Attorney",
        "Paralegal"
    ],
    banking_commrce: [
        "Bank Teller"
    ],
    profecional_sports: [
        "Assistant Golf Professional"
    ],
    sme_entrepeneur: [
        "Yoga Instructor"
    ]
}