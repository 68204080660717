import React, { Component } from 'react'
import LineGraphs from './chart1';
import PieGraph from './charts2';
import CssBaseline from '@mui/material/CssBaseline';
import TabPane from './tabbed-pane';
import CheckboxListSecondary from './comments-preview';
import SimpleCard from './card';
import Typography from '@mui/material/Typography';
import AlignItemsList from './activity-tab';
import SimpleContainer from './temp';
import ManageUsers from './manage-user';
import {userlist , webTraffic , invitationSent} from '../../utils';
import Backdrop from '@mui/material/Backdrop';
import makeStyles from '@mui/styles/makeStyles';

export default class AdminDash extends Component{


    constructor(props){
        super(props);
        this.state = {
            users: null,
            wt:null,
            open: false,
            open2: false,
            open3: false,
        }

        var x = localStorage.getItem("user");
        this.props.state.socket.emit("user-click-event" , {event:"Admin-dashboard page", user:x} );

    }

    componentDidMount(){

        userlist().
        then((usersx)=>{

            this.setState({users: usersx});

        });

        webTraffic().
        then(data=>{

            this.setState({wt: data});
            

        })

        invitationSent().
        then(data=>{
  
          this.setState({sd: data});
  
  
        })
  


    }

    render(){

        return(
            <div>
            <CssBaseline/>

<Typography variant="h2" component="h2" gutterBottom style={{textAlign:'center' , marginTop:'50px' , marginBottom:'50px'}}>
        Admin Dashboard
</Typography>


<div className={"top-items-admin"}>
    <SimpleCard kind= {0} src ={this.state.wt} open ={this.state.open} setOpen={(p)=> this.setState({open:p})}/>
    <SimpleCard kind= {1} src ={this.state.users} open ={this.state.open2} setOpen={(p)=> this.setState({open2:p})}/>
    <SimpleCard kind= {3} src ={this.props.state.usersOnline} open ={this.state.open3} setOpen={(p)=> this.setState({open3:p})}/>
    <SimpleCard kind= {2} src = {this.state.sd}/>
</div>

<br/>
<br/>
<br/>

<div className={"graphs-admin"}>
    <LineGraphs title="User Statistics"/>
    <PieGraph/>
</div>

<div className={"row-two"}>

<div className={"Comments"}>

<CheckboxListSecondary/>


</div>

<div className={"activityList"}>
    <AlignItemsList />
</div>

</div>

            </div>
        );
    }


}