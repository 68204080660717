import React, { Component } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import jobs from '../data/jobs';
import UpdatePictureComponent from './dashboard/uploadimage';
import { updateWork, updateUsername } from '../utils';

const industries = Object.keys(jobs);

export default class UpdateProfile extends Component {
    constructor(props) {
        const user = JSON.parse(localStorage.getItem('user')); 
        super(props);
        this.state = {
            selected: 'workinfo',
            surname: user.last_name,
            mobile: user.mobile,
            position: user.position,
            company: user.user_company,
            industry: user.industry,
            suburb: user.suburb,
            province: user.province,
            other_position: '',
            other_industry: '',
            open: false,
            loading_update: false,
            value: 0,
        };
        this.onSubmitWork = this.onSubmitWork.bind(this);
        this.onSubmitPersonal = this.onSubmitPersonal.bind(this);
        
        var x = localStorage.getItem("user");
        this.props.socket.emit("user-click-event", { event: "update-profile page", user: x });
    }

    refresh = () => {
        window.location.reload();
    }

    handleOpen = () => {
        this.setState({ open: true });
    };
    
    handleClose = () => {
        this.setState({ open: false });
    };

    onSubmitWork(e) {
        e.preventDefault();
        console.log(this.state);
        const user = JSON.parse(localStorage.getItem('user'));
        const id = user.id;

        const data = {
            position: this.state.position === 'other' ? this.state.other_position : this.state.position,
            company: this.state.company,
            industry: this.state.industry === 'other' ? this.state.other_industry : this.state.industry,
            suburb: this.state.suburb,
            province: this.state.province,
        };

        this.setState({ loading_update: true });
        updateWork(data, id)
            .then(results => {
                if (results) {
                    this.setState({ loading_update: false });
                    var user = JSON.parse(localStorage.getItem('user'));
                    user.position = results.position;
                    user.user_company = results.user_company;
                    user.industry = results.industry;
                    user.suburb = results.suburb;
                    user.province = results.province;
                    
                    localStorage.setItem('user', JSON.stringify(user));
                }
                if (results.status === 400) {
                    this.setState({ err: results.message, loading_update: false });
                }
            });
    }

    onSubmitPersonal(e) {
        e.preventDefault();

        let formData = new FormData();
        formData.append('last_name', this.state.surname);

        this.setState({ loading_update: true });
        updateUsername(formData)
            .then(results => {
                if (results && results.status === 'success') {
                    var user = JSON.parse(localStorage.getItem('user'));
                    user.last_name = results.data.last_name;

                    updateWork({ mobile: this.state.mobile }, user.id)
                        .then(results => {
                            if (results) {
                                this.setState({ loading_update: false });
                                var user = JSON.parse(localStorage.getItem('user'));
                                user.mobile = results.mobile;
                                
                                localStorage.setItem('user', JSON.stringify(user));
                            }
                            if (results.status === 400) {
                                this.setState({ err: results.message, loading_update: false });
                            }
                        });

                    localStorage.setItem('user', JSON.stringify(user));
                }
                
                if (results.status === 400) {
                    this.setState({ loading_update: false, err: results.message });
                }
            });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    navigate = (path) => {
        this.props.history.push(path);
    }

    render() {
        const actions = [
            <Button onClick={this.refresh} color="primary">
              Cool
            </Button>
        ];

        return ( 
            <div className='container'>
                <div className='row'>
                    <div className='col-4'>
                        <List style={{ backgroundColor: '#fff', paddingTop: 0 }}>
                            <ListItem 
                                id='personal' 
                                style={{
                                    color: this.state.selected === 'personal' ? '#fff' : '#343434', 
                                    backgroundColor: this.state.selected === 'personal' ? 'rgb(46, 149, 198)' : null
                                }} 
                                onClick={() => this.setState({ selected: 'personal'})} 
                                primaryText="Update your personal details"  
                                icon={<PersonIcon />} 
                            />
                            
                            <Divider />
                            <ListItem   
                                id='workinfo' 
                                style={{
                                    color: this.state.selected === 'workinfo' ? '#fff' : '#343434', 
                                    backgroundColor: this.state.selected === 'workinfo' ? 'rgb(46, 149, 198)' : null
                                }} 
                                onClick={() => this.setState({ selected: 'workinfo'})} 
                                
                                primaryText="Update what you do"  
                                icon={<BusinessIcon />} 
                            />
                            <Divider />
                            <ListItem 
                                id='updatepicture'
                                style={{
                                    color: this.state.selected === 'updatepicture' ? '#fff' : '#343434', 
                                    backgroundColor: this.state.selected === 'updatepicture' ? 'rgb(46, 149, 198)' : null
                                }}
                                primaryText="Update your picture" 
                                onClick={() => this.setState({ selected: 'updatepicture'})} 
                                icon={<AddAPhotoIcon />} 
                            />
                            <Divider />
                            <ListItem 
                                style={{color: '#343434'}} 
                                primaryText="Back to your dashboard" 
                                onClick={() => { this.navigate('/mydashboard') }} 
                                icon={<ArrowBackIcon />} 
                            />
                        </List>
                    </div>
                   {
                    this.state.selected === 'workinfo' ?
                        <div className='col-8'>
                            <Card style={{ backgroundColor: '#fff', color: '#343434' }} className='center'>
                            <br/>
                            <h3 style={{ color: '#343434' }}>Update what you do</h3>
                            <form onSubmit={this.onSubmitWork}>
                                <TextField 
                                    name='company'
                                    placeholder='Company'
                                    type='text'
                                    style={{ color: '#343434' }}
                                    value={this.state.company}
                                    onChange={this.handleChange}
                                />
                                <br />
                                <Select
                                    name="industry"
                                    value={this.state.industry}
                                    onChange={(e) => this.setState({ industry: e.target.value })}
                                    style={{ color: '#343434', marginTop: '16px' }}
                                >
                                    {
                                        industries.sort().map((i, index) => (
                                            <MenuItem key={index} value={i} primaryText={i}>{i}</MenuItem>
                                        ))
                                    }
                                    <MenuItem value={'other'} primaryText={"Other"}>Other</MenuItem>
                                </Select>

                                {this.state.industry === 'other' && (
                                    <div>
                                        <br />
                                        <TextField
                                            name='other_industry'
                                            placeholder='Other Industry'
                                            type='text'
                                            value={this.state.other_industry}
                                            onChange={this.handleChange}
                                        /> 
                                    </div>
                                )}
                                <br />
                                {this.state.industry && (
                                    <Select
                                        name='position'
                                        value={this.state.position}
                                        onChange={(e) => this.setState({ position: e.target.value })}
                                        style={{ color: '#343434', marginTop: '16px' }}
                                    >
                                        {jobs[this.state.industry] && jobs[this.state.industry].sort().map((i, index) => (
                                            <MenuItem key={index} value={i} primaryText={i}>{i}</MenuItem>
                                        ))}
                                        <MenuItem value={'other'} primaryText={"Other"}>Other</MenuItem>
                                    </Select>
                                )}
                                {this.state.position === 'other' && (
                                    <div>
                                        <br />
                                        <TextField
                                            name='other_position'
                                            placeholder='Other Position'
                                            type='text'
                                            value={this.state.other_position}
                                            onChange={this.handleChange}
                                        /> 
                                    </div>
                                )}
                                <br />
                                <TextField 
                                    name='suburb'
                                    placeholder='Suburb'
                                    type='text'
                                    value={this.state.suburb}
                                    onChange={this.handleChange}
                                />
                                <br />
                                <TextField
                                    name='province'
                                    placeholder='Province'
                                    type='text'
                                    value={this.state.province}
                                    onChange={this.handleChange}
                                />
                                <br />
                                <div className='container'>
                                    <Button
                                        type='submit'
                                        style={{ marginBottom: '10px' }}
                                        variant="contained"
                                        color="secondary"
                                        fullWidth
                                        disabled={
                                            !this.state.position ||
                                            !this.state.company ||
                                            !this.state.industry ||
                                            !this.state.suburb ||
                                            !this.state.province
                                        }
                                        onClick={this.handleOpen}
                                    >
                                        Upload details
                                    </Button>
                                    {this.state.loading_update ? (
                                        <Dialog
                                            open={this.state.open}
                                            onClose={this.handleClose}
                                        >
                                            <DialogContent>
                                                <DialogContentText>Updating.....</DialogContentText>
                                            </DialogContent>
                                        </Dialog>
                                    ) : (
                                        <Dialog
                                            open={this.state.open}
                                            onClose={this.handleClose}
                                        >
                                            <DialogTitle>Your update has been successful</DialogTitle>
                                            <DialogActions>
                                                {actions}
                                            </DialogActions>
                                        </Dialog>
                                    )}
                                </div>
                            </form>
                            </Card>
                        </div> :
                        this.state.selected === 'personal' ? 
                            <div className='col-8'>
                                <Card style={{ backgroundColor: '#fff' }} className='center'>
                                <h3 style={{ color: '#343434' }}>Update your surname</h3>
                                    <form onSubmit={this.onSubmitPersonal}> 
                                        <TextField 
                                            name='surname'
                                            placeholder='Surname'
                                            type='text'
                                            value={this.state.surname}
                                            onChange={this.handleChange}
                                        /><br />
                                        <TextField 
                                            name='mobile'
                                            placeholder='Mobile'
                                            type='text'
                                            value={this.state.mobile}
                                            onChange={this.handleChange}
                                        />
                                        <br/>
                                        <i><small>Add your phone number for us to share your details with potential recruiters</small></i>
                                        <div className='container'>
                                            <Button
                                                type='submit'
                                                style={{ marginBottom: '10px' }}
                                                variant="contained"
                                                color="secondary"
                                                fullWidth
                                                disabled={!this.state.surname}
                                                onClick={this.handleOpen}
                                            >
                                                Upload details
                                            </Button>
                                            {this.state.loading_update ? (
                                                <Dialog
                                                    open={this.state.open}
                                                    onClose={this.handleClose}
                                                >
                                                    <DialogContent>
                                                        <DialogContentText>Updating.....</DialogContentText>
                                                    </DialogContent>
                                                </Dialog>
                                            ) : (
                                                <Dialog
                                                    open={this.state.open}
                                                    onClose={this.handleClose}
                                                >
                                                    <DialogTitle>Your update has been successful</DialogTitle>
                                                    <DialogActions>
                                                        {actions}
                                                    </DialogActions>
                                                </Dialog>
                                            )}
                                        </div>
                                    </form>
                                </Card>
                            </div> :
                        null
                    }
                </div> 
            </div>
        );
    }
}
