import React, { Component } from 'react';
import { CircularProgress } from '@mui/material';
import { verifyEmail } from '../utils'
import Button from '@mui/material/Button';


export default class EmailVerification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            message : 'Verifing your email',
            loading: true
        }

        this.props.socket.emit("user-click-event" , {event:"email-verification page" } );

    }

    componentDidMount () {
        var urlParams = new URLSearchParams(this.props.location.search)
        const key = urlParams.get('key')
        console.log("KEY", key);
        verifyEmail(key)
        .then(res => {
            console.log("ERS", res);
            if (res && res.status === 'error') {
                this.setState({message: res.message, loading: false })
            } else {
                this.setState({message: 'Success: Email verified!', loading: false })
            }
        })
        .catch(err => {
            this.setState({message: 'Verification failed please try again', loading: false })
        })
        

        setTimeout(function(){}, 4000);
    }

    navigate = (path) => {
        this.props.history.push(path)
    }

    render() {
        
        return (
            
            <div>

               {
                   this.state.loading ?
                    <div className='center'><CircularProgress size={80} thickness={5} /></div> :
                    <div>
                        <div className='center'>
                            <img alt='' style={{
                                width: '280px',
                                height: 'auto'
                            }} src='/img/logo.png'/>
                            <p style={{fontSize: '30px'}}>{this.state.message}</p>
                        </div>
                        <div className='center'>

                            <p>Hi, </p>
                            <p>Welcome to UpSTAR! Thank you for verifying your email address.</p>
                            <p>Click here to continue creating your profile.</p>
                            <Button
                                    style={{ marginRight: '30px'}}
                                    label='Continue'
                                    primary={true}
                                    type='submit'
                                    onClick={() => { 
                                        var urlParams = new URLSearchParams(this.props.location.search)

                                        const next = urlParams.get('next');
                                        if (next) {
                                            window.location = '/#/manifesto?next=' + next;
                                        } else {
                                            window.location = '/#/manifesto' 
                                        }
                                    }}
                            />
                            <p>Have a bright day! <br/> Team UpSTAR </p>
                        </div>
                    </div>
               }
               
            </div>
            
        );
    }
}