import React, { Component } from 'react'

export default class extends Component {

    state = {
        mobile: false
    }

    componentDidMount() {

       if (this.props.push) {
            if (window.innerWidth < 445) {
                this.setState({ mobile: true })
            } else {
                this.setState({ mobile: false })
            }
            window.addEventListener('resize', () => {

                if (window.innerWidth < 445) {
                    this.setState({ mobile: true })
                } else {
                    this.setState({ mobile: false })
                }
            })
       }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => {
            
        });
    }

    render() {
        const { user, push } = this.props;

        const logged_in_user = JSON.parse(localStorage.getItem('user'));

        const variable_window_height = this.state.mobile ? window.innerWidth/2 : window.innerWidth / 4;

        return (
            <div onClick={() => {
                if (logged_in_user.id === user.rehive_user_id) {
                    push && push('/mydashboard')
                } else {
                    push && push('/user/' + user.rehive_user_id)
                }
            }} style={{display: 'flex', justifyContent: 'center', alignContent: 'center', position: 'relative'}}>
                <div style={{
                    position: 'relative',
                    width: '100%',
                    maxWidth: push ? variable_window_height : '200px'
                }}>
                    <div style={{
                        position: 'absolute',
                        paddingTop: '5%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        flexDirection: 'column',
                        top: !push ? '80px' : this.state.mobile ? window.innerWidth/5 : window.innerWidth / 9,
                        maxWidth: push ? variable_window_height : '200px',
                        width: '100%',
                        textAlign: 'center'
                    }}>
                        {
                            user && user.profile && user.five_words && user.five_words.map((word, index) => (
                                word && <span style={{
                                    fontSize: !push ? '14px' : this.state.mobile ? '12px' : window.innerWidth / 70 + 'px',
                                    fontFamily: "'Concert One', cursive"
                                }} key={index}>{word.toUpperCase()}<br/></span>
                            ))
                        }
                    </div>
                    <img 
                        style={{
                            marginTop : '10px',
                            height: push ? variable_window_height : '200px',
                            maxWidth: push ? variable_window_height : '200px',
                            width: push ? variable_window_height : '200px',
                            borderRadius: !push ? '5%' : null,
                            objectFit: 'cover'
                        }} 
                        src={user.profile || 'img/avatar.png'} 
                        className='container' 
                        alt=''
                    />
                </div>
            </div>
        )
    }
}