import * as React from 'react';
import Paper from '@mui/material/Paper';
import {
  Chart,
  PieSeries,
  Title,
  Legend
} from '@devexpress/dx-react-chart-material-ui';
import { Animation } from '@devexpress/dx-react-chart';
import CircularProgress from '@mui/material/CircularProgress'

import {popularProfessions} from '../../utils';

const dataz = [
  { profession: 'Russia', area: 12 },
  { profession: 'Canada', area: 7 },
  { profession: 'USA', area: 7 },
  { profession: 'China', area: 7 },
  { profession: 'Brazil', area: 6 },
  { profession: 'Australia', area: 5 },
  { profession: 'India', area: 2 },
  { profession: 'Others', area: 55 },
];
export default class PieGraph extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data:null
    };
  }

  componentDidMount(){

    popularProfessions().
    then(data=>{
      console.log(data)
      this.setState({data:data});
    }).
    catch(err=>{
      console.log(err)
    })    

  }

  render() {
//    const { data: chartData } = this.state;

    return (
      <Paper style={{display: 'inline-block', width:500 , height:450}} className={"graph1-paper"}>
        {this.state.data?
        <Chart
          data={this.state.data}

          width={500} height={450}

        >
          <PieSeries
            valueField="number"
            argumentField="_id"
          />
          <Legend/>
          <Title
            text="Most popular professions"
          />
          <Animation />
        </Chart>
        :
        <div className='center'><CircularProgress size={40} thickness={5} /></div>
        }
      </Paper>
    );
  }
}
