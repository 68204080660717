
//const API_URL = 'http://localhost:1233';
//const API_URL = 'http://localhost:3001';
//const API_URL = 'http://164.92.182.132:1233'
const API_URL = 'https://api.upstar.co.za'


//Methods for Admin Dash


export const mostSearched = (data) => (
    fetch(API_URL + '/calc/most-searched', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(data) {  
        // console.log('data', data)
        return data
    }).catch(err => {
        // console.log('ERROR', err)
        return err;
    })
)

export const averageHoursPP = (data) => (
    fetch(API_URL + '/calc/average-hours-pp', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(data) {  
        // console.log('data', data)
        return data
    }).catch(err => {
        // console.log('ERROR', err)
        return err;
    })
)


export const averageHours = (data) => (
    fetch(API_URL + '/calc/average-hours', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(data) {  
        // console.log('data', data)
        return data
    }).catch(err => {
        // console.log('ERROR', err)
        return err;
    })
)

export const newestUser = (data) => (
    fetch(API_URL + '/calc/newest-user', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(data) {  
        // console.log('data', data)
        return data
    }).catch(err => {
        // console.log('ERROR', err)
        return err;
    })
)


export const mostPopular = (data) => (
    fetch(API_URL + '/calc/mostpuser', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(data) {  
        // console.log('data', data)
        return data
    }).catch(err => {
        // console.log('ERROR', err)
        return err;
    })
)

export const mostPopularThisWeek = (data) => (
    fetch(API_URL + '/calc/mostpusertweek', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(data) {  
        // console.log('data', data)
        return data
    }).catch(err => {
        // console.log('ERROR', err)
        return err;
    })
)


export const changeAccountType = (data) => (
    fetch(API_URL + '/accounts/type/set', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(data) {  
        // console.log('data', data)
        return data
    }).catch(err => {
        // console.log('ERROR', err)
        return err;
    })
)

export const changeAccountStatus = (data) => (
    fetch(API_URL + '/accounts/status/set', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(data) {  
        // console.log('data', data)
        return data
    }).catch(err => {
        // console.log('ERROR', err)
        return err;
    })
)



export const onlineDaily = (data) => (
    fetch(API_URL + '/calc/daily-online-users', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(data) {  
        // console.log('data', data)
        return data
    }).catch(err => {
        // console.log('ERROR', err)
        return err;
    })
)


export const invitationSent = (data) => (
    fetch(API_URL + '/recent-activities/invitations', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(data) {  
        // console.log('data', data)
        return data
    }).catch(err => {
        // console.log('ERROR', err)
        return err;
    })
)



export const webTraffic = (data) => (
    fetch(API_URL + '/calc/web-traffic', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(data) {  
        // console.log('data', data)
        return data
    }).catch(err => {
        // console.log('ERROR', err)
        return err;
    })
)

export const navigationLogs = (data) => (
    fetch(API_URL + '/recent-activities/nl', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(data) {  
        // console.log('data', data)
        return data
    }).catch(err => {
        // console.log('ERROR', err)
        return err;
    })
)

export const userActions = (data) => (
    fetch(API_URL + '/recent-activities/ua', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(data) {  
        // console.log('data', data)
        return data
    }).catch(err => {
        // console.log('ERROR', err)
        return err;
    })
)
export const popularProfessions = (data) => (
    fetch(API_URL + '/calc/mostpprofessions', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(data) {  
        // console.log('data', data)
        return data
    }).catch(err => {
        // console.log('ERROR', err)
        return err;
    })
)

export const popularInd = (data) => (
    fetch(API_URL + '/calc/mostpindustry', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(data) {  
        // console.log('data', data)
        return data
    }).catch(err => {
        // console.log('ERROR', err)
        return err;
    })
)



//Methods for Admin Dash

export const updateProfile = (data) => (
    fetch(API_URL + '/uprofile', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) {
        return response.json();
    })
    .then(response => response)
    .catch(err => {
        console.log('ERROR', err)
        return err
    })
)



export const deleteChats = (from, to) => {
    
    fetch(API_URL + '/messages/delete/' + from + '/' + to, {
        method: 'GET',
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) {
        return response.json();
    })
    .catch(err => {
        console.log('ERROR', err)
        return err
    })
}


export const register = (data) => (
    fetch(API_URL + '/register/', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) {
        return response.json();
    })
    .then(response => response)
    .catch(err => {
        console.log('ERROR', err)
        return err
    })
)

export const login = (data) => (
    fetch(API_URL + '/login/', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) {
        // console.log("Login response",response)
        return response.json();
    })
    .then(response => response)
)


export const getEmails = (id) => {
    const token = localStorage.getItem('token')
    return fetch(API_URL + '/emails/' + id + '?token=' + token, {
        method: 'GET',
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) {
        return response.json();
    })
    .catch(err => {
        console.log('ERROR', err)
        return err
    })
}

export const addEmail = (email) => {
    const token = localStorage.getItem('token')
    return fetch(API_URL + '/emails?token=' + token, {
        method: 'POST',
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        },
        body: JSON.stringify({ email })
    })
    .then(function(response) {
        return response.json();
    })
    .catch(err => {
        console.log('ERROR', err)
        return err
    })
}

export const deleteEmail = (data) => {
    const token = localStorage.getItem('token')
    return fetch(API_URL + '/emails/delete?token=' + token, {
        method: 'POST',
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        },
        body: JSON.stringify(data)
    })
    .then(function(response) {
        return response.json();
    })
    .catch(err => {
        console.log('ERROR', err)
        return err
    })
}

export const updateEmail = (data) => {
    const token = localStorage.getItem('token')
    return fetch(API_URL + '/emails/update?token=' + token, {
        method: 'POST',
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        },
        body: JSON.stringify(data)
    })
    .then(function(response) {
        return response.json();
    })
    .catch(err => {
        console.log('ERROR', err)
        return err
    })
}

export const userlistlanding = () => (
    fetch(API_URL + '/land/users/', {
        method: 'GET',
        headers: {
            'Content-Type' : "application/json",
            'Accept' : "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(users) {  
        // console.log('utils users', users);
        return users
    })
)

export const usermsglanding = (user) => (
    fetch(API_URL + '/messages/'+user, {
        method: 'GET',
        headers: {
            'Content-Type' : "application/json",
            'Accept' : "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(msglist) {  
        // console.log('utils users', users);
        return msglist
    })
)



export const userlist = () => (
    fetch(API_URL + '/users/', {
        method: 'GET',
        headers: {
            'Content-Type' : "application/json",
            'Accept' : "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(users) {  
        // console.log('utils users', users);
        return users
    })
)

export const unclaimedUserlist = () => (
    fetch(API_URL + '/users/unclaimed', {
        method: 'GET',
        headers: {
            'Content-Type' : "application/json",
            'Accept' : "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(users) {  
        // console.log('utils users', users);
        return users
    })
)

export const unclaimedVotes = (id) => (
    fetch(API_URL + '/votes/unclaimed/' + id, {
        method: 'GET',
        headers: {
            'Content-Type' : "application/json",
            'Accept' : "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(data) {  
         console.log('utils data', data);
        return data
    })
)

export const getUser = (id) => (
    fetch(API_URL + '/user/' + id, {
        method: 'GET',
        headers: {
            'Content-Type' : "application/json",
            'Accept' : "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(user) {  
         console.log('USER!', user)
        return user
    })
)


export const getVotes = (id) => (
    fetch(id ? API_URL + '/votes/' + id : API_URL + '/votes/', {
        method : 'GET',
        headers: {
            'Content-Type' : "application/json",
            'Accept' : "application/json"
        }
    })
    .then(function(response) {
        return response.json();
    }).then(function(data) {
        return data
    })
)


export const getAllVotes = () => (
    fetch(API_URL + '/votes/all/', {
        method : 'GET',
        headers: {
            'Content-Type' : "application/json",
            'Accept' : "application/json"
        }
    })
    .then(function(response) {
        return response.json();
    }).then(function(data) {
        return data
    })
)



export const votes = (data) => (
    fetch(API_URL + '/votes/', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(votes) {  
        // console.log('VOTES', votes)
        return votes
    }).catch(err => {
        // console.log('ERROR', err)
        return err;
    })
)

export const voteUnclaimed = (data) => (
    fetch(API_URL + '/votes/unclaimed', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(votes) {  
        // console.log('VOTES', votes)
        return votes
    }).catch(err => {
        // console.log('ERROR', err)
        return err;
    })
)

export const voteUnclaimedExisting = (id, data) => (
    fetch(API_URL + '/votes/unclaimed/existing/' + id, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(votes) {  
        // console.log('VOTES', votes)
        return votes
    }).catch(err => {
        // console.log('ERROR', err)
        return err;
    })
)

export const upstar_votes = (data) => (
    fetch(API_URL + '/upstar_votes/', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    }).then(function(response) {
        return response.json();
    }).then(function(votes) {
        console.log('Upstar_votes', votes);
        return votes
    }).catch(err => {
        console.log('ERROR', err);
        return err
    })
)

export const get_upstar_votes = (data) => (
    fetch(API_URL + '/upstar_votes/', {
        method: 'GET',
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    }).then(function(response) {
        return response.json();
    }).then(function(votes) {
        return votes
    }).catch(err => {
        console.log('ERROR', err);
        return err
    })
)

export const editVote = (data, voteID) => (
    fetch(API_URL + '/votes/' + voteID, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) {
        return response.json();
    }) 
    .then(function(vote) {
        // console.log('vote', vote)
        return vote
    })
    .catch(err => {
        // console.log('Error', err);
        return err;
    })
)

export const updateWords = (data, id) => (
    fetch(API_URL + '/users/' + id, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        // console.log('WORDS', response)
        return response.json(); 
    }).then(function(words) {  
        // console.log('WORDS', words)
        return words
    }).catch(err => {
        // console.log('ERROR!!!!', err)
        return err;
    })
)

export const updateWork = (data, id) => (
    fetch(API_URL + '/users/' + id, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        // console.log('WORK RESPONSE', response)
        return response.json(); 
    }).then(function(work) {  
        // console.log('WORK', work)
        return work
    }).catch(err => {
        // console.log('ERROR!!!!', err)
        return err;
    })
)

let _apiCallFileUpload = async (url, method, data) => {
	try {
	  const token = localStorage.getItem('token')
	  let headers = {
		// 'Content-Type': 'multipart/form-data',
		'Authorization': 'Token ' + token,
	  }
	  let response = await fetch(url, {
		method,
		headers,
		body: data,
	  })
	  let responseJson = await response.json()
	  return responseJson
	} catch (error) {
		console.log(JSON.stringify(error))
	}
  }

  export const fileUpload = (data) => {
    let rehive_api_url = 'https://api.rehive.com/3';
    
    return _apiCallFileUpload(rehive_api_url + '/user/', "PATCH", data)
  }

  let _apiCallUpdateUsername = async (url, method, data) => {
	try {
	  const token = localStorage.getItem('token')
	  let headers = {
		// 'Content-Type': 'multipart/form-data',
		'Authorization': 'Token ' + token,
	  }
	  let response = await fetch(url, {
		method,
		headers,
		body: data,
	  })
	  let responseJson = await response.json()
	  return responseJson
	} catch (error) {
		console.log(JSON.stringify(error))
	}
  }  

  export const updateUsername = (data) => {
      let rehive_api_url = 'https://api.rehive.com/3';

      return _apiCallUpdateUsername(rehive_api_url + '/user/', "PATCH", data)
  }

  export const verifyEmail = (key) => (
    fetch(API_URL + '/email/verify', {
        method: 'POST',
        body: JSON.stringify({key}),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(data) {  
        return data
    }).catch(err => {
        return err;
    })
)

export const resetPassword = (data) => (
    fetch(API_URL + '/password/reset', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        console.log(response);
        return response.json(); 
    }).then(function(data) {  
        return data;
    }).catch(err => {
        return err;
    })
)

export const resetPasswordConfirm = (data) => (
    fetch(API_URL + '/password/reset/confirm', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        // console.log('Response', response);
        return response.json(); 
    }).then(function(data) {  
        // console.log('Data', data)
        return data
    }).catch(err => {
        return err;
    })
)

export const sendMail = (data) => (
    fetch(API_URL + '/email/send/', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(data) {  
        // console.log('data', data)
        return data
    }).catch(err => {
        // console.log('ERROR', err)
        return err;
    })
)

export const adminSendMail = (data) => (
    fetch(API_URL + '/emails/send', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(data) {  
        // console.log('data', data)
        return data
    }).catch(err => {
        // console.log('ERROR', err)
        return err;
    })
)


//messages
//fetching messages between users
export const getMessages = (mf, to) => (
    
    fetch(API_URL + '/messages/'+mf+"/"+to, {
        method: 'GET',
        headers: {
            'Content-Type' : "application/json",
            'Accept' : "application/json"
        }
    })
    .then(function(response) { 
        
        return response.json(); 
    }).then(function(messages) {  
        console.log('msgs ', messages);
        return messages
    })

)



export const sendMessage = (data) => (
    fetch(API_URL + '/messages/send/', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(data) {  
        // console.log('data', data)
        return data
    }).catch(err => {
        // console.log('ERROR', err)
        return err;
    })
)


export const inviteFriends = (data) => (
    fetch(API_URL + '/invite/', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(data) {  
        // console.log('data', data)
        return data
    }).catch(err => {
        // console.log('ERROR', err)
        return err;
    })
)

export const alreadyVoted = (data) => (
    fetch(API_URL + '/invite/voted', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(data) {  
        // console.log('data', data)
        return data
    }).catch(err => {
        // console.log('ERROR', err)
        return err;
    })
)

export const rateNow = (data) => (
    fetch(API_URL + '/invite/rate', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
    .then(function(response) { 
        return response.json(); 
    }).then(function(data) {  
        // console.log('data', data)
        return data
    }).catch(err => {
        // console.log('ERROR', err)
        return err;
    })
)