import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { login } from '../utils';
import { useNavigate, useLocation } from 'react-router-dom';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [formErrors, setFormErrors] = useState({ email: '', password: '' });

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate("/");
        }
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'email') setEmail(value);
        else if (name === 'password') setPassword(value);
    };

    const validateForm = () => {
        let isValid = true;
        const errors = { email: '', password: '' };

        if (!email) {
            errors.email = 'Email is required.';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email address is invalid.';
            isValid = false;
        }

        if (!password) {
            errors.password = 'Password is required.';
            isValid = false;
        } else if (password.length < 6) {
            errors.password = 'Password must be at least 6 characters long.';
            isValid = false;
        }

        setFormErrors(errors);
        return isValid;
    };

    const onSubmit = (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return; // Stop form submission if validation fails
        }

        const urlParams = new URLSearchParams(location.search);
        const next = urlParams.get('next');

        setLoading(true);

        login({ email, password })
            .then(results => {
                if (results.err) {
                    setErr(results.err);
                } else {
     
                    localStorage.setItem('user', JSON.stringify(results.user));
                    localStorage.setItem('token', results.token);

                    if (next) {
                        navigate('/quick-rate');
                    } else {
                        navigate('/');
                    }
                }
            })
            .catch(error => {
                console.error('Login error:', error);
                setErr('An error occurred during login.');
            })
            .finally(() => setLoading(false));
    };

    return (
        <div>
            <form onSubmit={onSubmit}>
                <div>
                    <h1>Login</h1>
                    <TextField
                        name='email'
                        placeholder='Email'
                        type='text'
                        value={email}
                        onChange={handleChange}
                        fullWidth
                        error={!!formErrors.email}
                        helperText={formErrors.email}
                    /><br />
                    <TextField
                        name='password'
                        placeholder='Password'
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={handleChange}
                        fullWidth
                        error={!!formErrors.password}
                        helperText={formErrors.password}
                    />
                    <span
                        style={{
                            cursor: 'pointer',
                            userSelect: 'none'
                        }}
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? "Hide" : "Show"}
                    </span>
                    <br />
                    {err && <span style={{ color: 'red' }}>{err}<br/></span>}
                    <br/>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        type='submit'
                    >
                        {loading ? <CircularProgress size={24} /> : "Login"}
                    </Button>
                    <p style={{ cursor: "pointer" }} onClick={() => navigate('/forgotpassword')}>
                        <a style={{ color: 'white', textDecoration: 'none' }}> Forgot password ?</a>
                    </p>
                </div>
            </form>
        </div>
    );
};

export default Login;
