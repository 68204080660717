import React from 'react';
import makeStyles from '@mui/styles/makeStyles';



import {userlist} from '../../utils';

import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import {getObjects} from '../../jp';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CircularProgress from '@mui/material/CircularProgress';


const useStyles3 = makeStyles(theme => ({
    demo: {
      backgroundColor: theme.palette.background.paper,
    },
    title: {
      margin: theme.spacing(4, 0, 2),
    },
  }));


export default class ListDisp extends React.Component{
 
constructor(props){
    super(props);
    this.state = {
        list1 : this.props.data,
        list2 : null,
        classes3: useStyles3,
        working:false,
    }

    this.getUser = this.getUser.bind(this);
}

componentDidMount(){
    userlist().
    then(data2=>{
      this.setState({list2:data2 , working:true});
    })
    
}

 getUser = function(id){
    var o = getObjects(this.state.list2 , "id" , id);
    return o[0];
  }


render(){

    return (
      <Grid container spacing={2} alignItems={"center"} justifyContent={"center"}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" className={this.state.classes3.title}>
                  {this.props.title}
                </Typography>
                <div className={this.state.classes3.demo}>
                  <List>
                    {
                      
                      this.state.working && this.props.data ? 
                      typeof(this.props.data)==="String"? 
                      this.props.data
                      :
                      this.props.data.map((val)=>(
                      <ListItem>
                        <ListItemAvatar>
                          {this.props.kind===1 ? val.profile? 
                          
                          <Avatar src={val.profile}/> :
                          
                          <Avatar>
                            <AccountBoxIcon/>
                          </Avatar>

                          :

                          this.props.kind===3 ?
                          this.getUser(val).profile? 
                          
                          <Avatar src={this.getUser(val).profile}/> :
                          
                          <Avatar>
                            <AccountBoxIcon/>
                          </Avatar>
                          
                          :

                          this.props.kind===7 ?
                          this.getUser(val._id).profile? 
                          
                          <Avatar src={this.getUser(val._id).profile}/> :
                          
                          <Avatar>
                            <AccountBoxIcon/>
                          </Avatar>
                          
                          :
                          null

                          }

                        </ListItemAvatar>
                        <ListItemText
                          primary={ 

                          this.props.kind===1 && val?
                              val.first_name + " "+ val.last_name
                          :

                          this.props.kind===3 && val && this.getUser(val)?
                              this.getUser(val).first_name + " " + this.getUser(val).last_name
                          :
                          this.props.kind===7 && val && val._id && this.getUser(val._id)?
                              this.getUser(val._id).first_name + " " + this.getUser(val._id).last_name + "     "+ 
                              Math.ceil(val.hAvg)+" H : "+ Math.ceil(val.mAvg) + " M : "+ Math.ceil(val.sAvg)+" S"
                          :

                          null                    
                          
                          }
                        />
                      </ListItem>
                    ))
                    :
                    
                    <div className='center'><CircularProgress size={40} thickness={5} /></div>

                    }
                  </List>
                </div>
              </Grid>
              </Grid>
    );
}

}