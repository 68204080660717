import React, { Component } from 'react';
import {Button} from '@mui/material/';

export default class ContactSuccess extends Component {
    
    render () {
        return (
            <div className='center'>
                <h4>Your message has been sent successfully</h4>
                <p>At UpStar we really value our users opinions.</p>
                <Button
                    label="Back" 
                    primary={true} 
                    type='submit'
                    onClick={()=> this.props.history.push('/contactus')}
                />
            </div>
        )
    }
}