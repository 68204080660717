import React, {useState, useEffect} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import { getUser, getMessages, usermsglanding, deleteChats } from '../utils';
import Dashboard from './components/dashboard'
import { CircularProgress } from '@mui/material';
import {getObjects} from '../jp';
import FastForwardIcon from  '@mui/icons-material/FastForward' //, FastRewind
import FastRewindIcon from  '@mui/icons-material/FastRewind' //, 
import IconButton from '@mui/material/IconButton'
import _ from 'underscore';

import Badge from '@mui/material/Badge';
import withStyles from '@mui/styles/withStyles';

const StyledButton = withStyles(theme => ({
  iconbutton: {
    right: -40,
    top: 20,
    display:'absolute'
  },
}))(IconButton);


const StyledBadge = withStyles(theme => ({
  badge: {
    right: -40,
    top: 20,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  bold:{
    fontWeight: 'bold',
},
normal:{
  fontWeight: 'normal',
},

  paper: {
    paddingBottom: 50,
    width:"40%",
    height:"90vh",
    overflowY:"scroll",
    float : "left",
  },
  paper2: {
    paddingBottom: 50,
    width:"60%",
    height:"90vh",
    overflow:"none",
    float : "left"
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
    fontWeight:'bold',
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  }, menuButton: {
      marginRight: theme.spacing(2),
    },
    typography: {
      padding: theme.spacing(2),
    },
}));

const StyledBadge2 = withStyles(theme => ({
  badge: {
    backgroundColor: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid #44b700',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

export default function Messages(props) {

  const classes = useStyles();

  if (props.match.params.id){
    console.log("URL FOUND");
    localStorage.setItem("url_to", props.match.params.id);
    props.history.push("/messages")

  }

  const getNumMessages = (x)=>{
    if (user) {
      var y = getObjects(newMessages , "_id" , x);
      return y.length>0 ?  y[0].number : 0;
    }
    else return 0;
  };

  
  var x = localStorage.getItem("user");
  props.state.socket.emit("user-click-event" , {event:"messages", user:x} );



  const [urlUser, setURLUser] = useState(localStorage.getItem("url_to"));
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [loading2, setLoading2] = useState(false);
  const [messages2, setMsgs] = useState(null); //all chats in convo
  const [reload, setReload] = useState(false)

  const [loading, setLoading] = useState(true);//loadinf screen

  const [selected, setSelected] = useState(false);//is msg tab clicked on
  const [seletUser, setser] = useState(null);//is userId
  const [disp, setDisp] = useState(null);//display name
  
  const [res, setRes] = useState(null);//results = all msg tab data
  const msgs = localStorage.getItem("msgs"); //all msg tab data

  const [dchange, seeChange] = useState(null); //new message sent
  const [to, setTo]= useState(null);

  const [shrunk , shrink] = useState(false);
  const [newMessages, setNMessages] = useState(null);

  const [selectedIndex, setSelectedIndex] = useState(-1);//msg tab selected index

  /*
  props.state.socket.on("num new message value" , (data)=>{

    if (data.data && data.data.id == user.id){

      setNMessages(data.val);


    }

  })
*/

  props.state.socket.on('new message' , (data)=>{


    if (data.to === user.id){

      function custom_sort(a, b) {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
        }

      var x = JSON.parse(localStorage.getItem("msgs"))
      console.log(x);
      var i = x.findIndex(y=>y.other_user === data.from);
      if (i>-1){

          x[i].date = data.date;
          x[i].text = data.msg;
          x[i].last_sent = data.from;
    
          x.sort(custom_sort);
          
          localStorage.setItem("msgs", JSON.stringify( 
            x
          ));
    
          setRes(x);

      }
      else{

      localStorage.removeItem('msgs');
      setReload(true);

     usermsglanding(user.id)
     .then( results => {
       localStorage.setItem("msgs", JSON.stringify(results));
       setRes(results);
       setSelectedIndex(setSelectedIndex);
   })

  setReload(false);

  }
    }


  });
  
  const handleShrink= (event, x) =>{

    if (x==1){
      shrink(false);
    }
    if (x==2){
      shrink(true);
    }

  };


  const navigate = (path) => {
  props.history.push(path)
  }
 
  useEffect(()=>{
    if (dchange){console.log("checking conditions after dchange is true");
    if (urlUser && res) {
      console.log("both conditions");
      var dt = getObjects(res, "id", urlUser);

      if (_.size(dt)===0){     
        localStorage.removeItem("msgs");
        localStorage.setItem("url_to", urlUser);
        window.location.reload(); 
        console.log("talk");
      }
  
    }
    else{
      console.log("this user already exists");
    }

    var x = JSON.parse(localStorage.getItem("msgs"));
    //if statement
    if (x && dchange){
      for (var i = 0; i< x.length; i++){
        if (x[i].other_user=== dchange.other_user){
          x[i].date = dchange.date;
          x[i].text = dchange.text;
          x[i].last_sent = user.id;
  
          break;
        }
      }
  
      function custom_sort(a, b) {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
        }

      x.sort(custom_sort);
      
      localStorage.setItem("msgs", JSON.stringify( 
        x
      ));

      setRes(x);
      setSelectedIndex(0);
      
      }

  }
  else
  {
    console.log(
      "no dchange value"
    );
  }

  } , [dchange]);


  function show_this(index, id, disp_name){
    if (index===selectedIndex){
      console.log("same item");
      setSelected(false);
      setDisp(null);
      setTo(null);
      setSelectedIndex(-1);
      setURLUser(null);
      setMsgs([]);
    }
    else
    {
      console.log("different item");
      setMsgs(null);
      setSelectedIndex(index);
      setTo(id);
      setDisp(disp_name);
    }
  }

  const preventDefault = (e) => {
    e.preventDefault();
    console.log(e.target.id);
    if (e.target.id===1){
      navigate("/");
    }
    else
    if (e.target.id===2){
      navigate("/search");
    }
  };

  const handleListItemClick = (event, index , id , disp_name) => {
    console.log("handle list");
    show_this(index, id, disp_name)
  };

  useEffect(()=>{
    console.log("reloading ", reload);
    if (reload)
    if (_.size(res)>0 && !res.error)
    res.map(({ other_user,last_sent, num_new, text, profile, date , disp_name}, id) => {
      if (other_user===urlUser) handleListItemClick(null, id , other_user, disp_name);
          
    });

  }, [reload]);

  useEffect(()=>{
    
    if (msgs){
      console.log("url user run if");
      setRes(JSON.parse(msgs));
      setLoading(false);
      console.log("res set done");
    }
    else{
      console.log("url user run else");
      usermsglanding(user.id)
      .then( results => {
        localStorage.setItem("msgs", JSON.stringify(results));
        setLoading(false);
        setRes(results);
        console.log("res set done");
   })
  
    }
    setReload(true);
  }, [])

  useEffect(()=>{

    if (selectedIndex!==-1){
      if (localStorage.getItem("url_to"))
        {localStorage.removeItem("url_to");
          setURLUser(null);}  
    
    getMessages(user.id, to)
      .then( results => {
        
        if (_.size(results)>0){
        setMsgs(results)     
        setser(to);
        setSelected(true);
        console.log("messages are ready");
        console.log("if statement");
        }
       

      })
    }
    else if (selectedIndex===-1 && urlUser){
      getUser(urlUser).
        then(data=>{
          getMessages(user.id, urlUser)
            .then( results => {
              if (_.size(results)===0 && JSON.stringify(data).length>2){
                setSelected(true);
                setDisp(data.first_name+" "+data.last_name);
                setser(urlUser);
                setMsgs([]);
                console.log("else statement");
        }
            });
        })      

    }
  
    
  }, [selectedIndex])

  console.log("last line - eof");

  var d = new Date();
  d.setDate(d.getDate() - 1);
    
  function timeSince(timeStamp) {
    var now = new Date(),
      secondsPast = (now.getTime() - timeStamp) / 1000;
    if (secondsPast < 60) {
      return parseInt(secondsPast) + 's ago';
    }
    if (secondsPast < 3600) {
      return parseInt(secondsPast / 60) + 'm ago';
    }
    if (secondsPast <= 86400) {
      return parseInt(secondsPast / 3600) + 'h ago';
    }
    if (secondsPast > 86400) {
      if (timeStamp.toDateString()=== d.toDateString()){

        return "yesterday"

      }
      else{
        var day = timeStamp.getDate();
        var month = timeStamp.toDateString().match(/ [a-zA-Z]*/)[0].replace(" ", "");
        var year = timeStamp.getFullYear() == now.getFullYear() ? "" : " " + timeStamp.getFullYear();
         return day + " " + month + year;
   
      }
    }
  }


  const newMes = (other_user)=>{
    return props.state.newMessages.findIndex(x=>x._id ===other_user);
  }
  
  return (
    <React.Fragment>
      <CssBaseline />
    {
      //(!shrunk) && 
    }     
    
    <Paper square className={classes.paper} elevation={2}>
    {/*  <Grid container justify="space-between">
        <Grid item>
          <span></span>
        </Grid>

        <Grid item>
        {shrunk ?
          <StyledButton onClick={(event) => handleShrink(event , 1)}>
            <FastForwardIcon/>
          </StyledButton>
          :
          <StyledButton onClick={(event) => handleShrink(event , 2)}>
            <FastRewindIcon/>
          </StyledButton>
        }
        </Grid>
      </Grid>
      */}
      
        <List className={classes.list}>
        
        {
            

            loading ?
            <div className='center'><CircularProgress size={40} thickness={5} /></div> :

            null
          }
            
        {
            res && !res.error &&   
            
            res.filter((a)=>{
             return ((new Date(a.date)).toDateString() === (new Date()).toDateString())
            }).length>0 ?

            <React.Fragment>
            
            <ListSubheader className={classes.subheader}>Today</ListSubheader>
        {   
              res.filter((a)=>{
                return ((new Date(a.date)).toDateString() === (new Date()).toDateString())
              }).map(({ other_user,last_sent, num_new, text, profile, date , disp_name}, id) => (

              <React.Fragment key={ res.findIndex(x=> x.other_user===other_user) }>
              
              <ListItem button 
              selected={selectedIndex === res.findIndex(x=> x.other_user===other_user)}
              onClick={event => handleListItemClick(event, res.findIndex(x=> x.other_user===other_user) , other_user, disp_name)}> 
        
              <StyledBadge badgeContent=
{(props.state.newMessages && newMes(other_user)>-1)? props.state.newMessages[newMes(other_user)].number : 0} color="primary">
        
                <ListItemAvatar>
                <StyledBadge2
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  variant={(props.state.usersOnline && props.state.usersOnline.indexOf(other_user)>-1) ?"dot" : ""}
                >
                  <Avatar alt="Profile Picture" src={profile} />
                </StyledBadge2>
                
                </ListItemAvatar>
              
             
                <ListItemText primary={disp_name}
              
                 secondary={
                  <Typography className={(props.state.newMessages!==null && newMes(other_user)>-1 && props.state.newMessages[newMes(other_user)].number>0)? classes.bold:classes.normal }>
                {  text.substr(0 , 100) + " ... " + timeSince( (new Date(date)) )}
                {
                  //console.log('value new messages' , props.state.newMessages[newMes(other_user)].number)
                  }
                  </Typography>
                  } 
                />
                </StyledBadge>
              </ListItem>
            </React.Fragment>

              ))
        }
            </React.Fragment>
         :null   

        }
        
        
        {     
          
          res && !res.error &&
          
          res.filter((a)=>{
                return ((new Date(a.date)).toDateString() === d.toDateString())
            }).length>0 ? 

            <React.Fragment>

      
            <ListSubheader className={classes.subheader}>Yesterday</ListSubheader>
         {      
              res.filter((a)=>{
                return ((new Date(a.date)).toDateString() === d.toDateString())
              }).map(({ other_user,last_sent, num_new, text, profile, date , disp_name}, id) => (

                <React.Fragment key={res.findIndex(x=> x.other_user===other_user)}>
              
              <ListItem button 
              selected={selectedIndex === res.findIndex(x=> x.other_user===other_user)}
              onClick={event => handleListItemClick(event, res.findIndex(x=> x.other_user===other_user) , other_user, disp_name)}> 
        
                <ListItemAvatar>
                <StyledBadge2
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  variant={(props.state.usersOnline && props.state.usersOnline.indexOf(other_user)>-1) ?"dot" : ""}
                >
                  <Avatar alt="Profile Picture" src={profile} />
                </StyledBadge2>
                
                </ListItemAvatar>
              
             
                <ListItemText primary={disp_name} secondary={
                  text.substr(0 , 100) + " ... " + timeSince( (new Date(date)) )
                  } 
                />
              </ListItem>
            </React.Fragment>



              )
              )
        }
            </React.Fragment>
         :null 
        }


        {
          
          res && !res.error &&
            res.filter((b)=>{

              return ((new Date(b.date)).toDateString() !== (new Date()).toDateString() ) && ((new Date(b.date)).toDateString() !== d.toDateString())                              
            
            }).length>0?
            
            <React.Fragment>
            <ListSubheader className={classes.subheader}>Older</ListSubheader>
   {
            res.filter((b)=>{
              return ((new Date(b.date)).toDateString() !== (new Date()).toDateString() ) && ((new Date(b.date)).toDateString() !== d.toDateString())
            }).map(({ other_user,last_sent, num_new, text, profile, date , disp_name}, id) => (

            <React.Fragment key={res.findIndex(x=> x.other_user===other_user)}>              
              <ListItem button 
              //onContextMenu={event=> handleClick2(event, id)}
              selected={selectedIndex === res.findIndex(x=> x.other_user===other_user)}
              onClick={event => handleListItemClick(event, res.findIndex(x=> x.other_user===other_user) , other_user, disp_name)}

              > 
        
                <ListItemAvatar>
                <StyledBadge2
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  variant={(props.state.usersOnline && props.state.usersOnline.indexOf(other_user)>-1 ) ?"dot" : ""}
                >
                  <Avatar alt="Profile Picture" src={profile} />
                </StyledBadge2>
                
                </ListItemAvatar>
              
             
                <ListItemText primary={disp_name} secondary={
                    text.substr(0 , 100) + " ... " + timeSince( (new Date(date)) )
                  } 
                />
              </ListItem>
            </React.Fragment>
          ))
   }
          </React.Fragment>
          :null
        }

          
          {
            
            !loading && (!res || res && res.error)? 
          
          
          <Typography className={classes.text} variant="body1" gutterBottom>
           You have not yet sent out or received any messages. Go to 
            <Link href="/" /*onClick={preventDefault}*/ id={1}>
              {" Dashboard "}
            </Link>
            /
            <Link href="#/search" /*onClick={preventDefault}*/ id={2}>
              {" Search "}
            </Link>
            and find a user to connect with.
           </Typography>

          :null
           
}
        </List>
      </Paper>


      <Paper square className={classes.paper2} elevation={2}>
        {
        
        selected && messages2 ?           
        
        <Dashboard from = {user.id} to = {seletUser} mess = {messages2} dis = {disp}
          newMessage = {seeChange} socket={props.state.socket}
          selected = {selected}
        />
        :null
        }
      </Paper>
    </React.Fragment>
  );
}