import * as React from 'react';
import Paper from '@mui/material/Paper';
import {
  Chart,
  BarSeries,
  Title,
  ArgumentAxis,
  ValueAxis,
  ZoomAndPan,
} from '@devexpress/dx-react-chart-material-ui';
import { Animation } from '@devexpress/dx-react-chart';
import CircularProgress from '@mui/material/CircularProgress';

export default class TrafficData extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data:null,
    };
  }

  render() {

    return (
      <Paper style={{width:"70%"}}>
      {this.props.data ? 
        <Chart
          data={this.props.data}
        >
          <ArgumentAxis/>
          <ValueAxis max={this.props.data? this.props.data[0].number : 7}/>

          <BarSeries
            valueField={this.props.vf}
            argumentField={this.props.af}
          />
          <ZoomAndPan />
          <Title text= {this.props.text}/>
          <Animation />
        </Chart>
        :
        <div className='center'><CircularProgress size={40} thickness={5} /></div>
      }
      </Paper>
    );
  }
}
