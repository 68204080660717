import React, { useState, useEffect, useCallback } from 'react';
import './db.css';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { sendMessage } from '../../utils';
import PropTypes from 'prop-types';
import { useRef } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    width: "100%",
    height: "90vh"
  },
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  chatWindow: {
    width: '100%',
    height: '60vh',
    padding: '20px',
    overflowY: 'auto',
  },
  userSent: {
    float: 'right',
    clear: 'both',
    padding: '20px',
    wordWrap: 'break-word',
    marginTop: '10px',
    backgroundColor: '#707BC4',
    color: 'white',
    width: '300px',
    borderRadius: '10px',
    display: 'block'
  },
  friendSent: {
    float: 'left',
    clear: 'both',
    padding: '20px',
    wordWrap: 'break-word',
    marginTop: '10px',
    backgroundColor: 'lightgreen',
    color: 'white',
    width: '300px',
    borderRadius: '10px',
    display: 'block'
  },
  chatBox: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}));

const timeSince = (timeStamp) => {
  const now = new Date();
  const secondsPast = (now - new Date(timeStamp)) / 1000;

  if (secondsPast < 60) return `${parseInt(secondsPast)}s ago`;
  if (secondsPast < 3600) return `${parseInt(secondsPast / 60)}m ago`;
  if (secondsPast < 86400) return `${parseInt(secondsPast / 3600)}h ago`;

  const d = new Date();
  d.setDate(d.getDate() - 1);

  if (secondsPast > 86400) {
    if (new Date(timeStamp).toDateString() === d.toDateString()) {
      return "yesterday";
    } else {
      const day = timeStamp.getDate();
      const month = timeStamp.toLocaleString('default', { month: 'short' });
      const year = timeStamp.getFullYear() === now.getFullYear() ? "" : ` ${timeStamp.getFullYear()}`;
      return `${day} ${month}${year}`;
    }
  }
};

const Dashboard = ({ from, to, mess, dis, newMessage, socket, selected }) => {
  const classes = useStyles();
  const [txt, setMes] = useState('');
  const [typing, setTyping] = useState(false);
  const chatEndRef = useRef(null);

  const scrollToBottom = useCallback(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const container = document.getElementById('style-3');
    if (container) container.scrollTo(0, container.scrollHeight);

    if (mess.length > 0 && selected) {
      socket.emit("read all messages", { id: from, to: to });
    }

    socket.on("typing", d => {
      if (d.from === to && d.to === from) {
        setTyping(true);
      }
    });

    socket.on("stop typing", d => {
      if (d.from === to && d.to === from) {
        setTyping(false);
      }
    });

    socket.on("new message", x => {
      if (x.to === from && x.from === to) {
        if (!mess.some(m => m.from === x.from && m.to === x.to && m.msg === x.msg && m.date === x.date)) {
          newMessage([...mess, x]);
          setMes("");
          scrollToBottom();
        }
      }
    });

    return () => {
      socket.off("typing");
      socket.off("stop typing");
      socket.off("new message");
    };
  }, [socket, from, to, mess, newMessage, scrollToBottom, selected]);

  const onChange = (e) => {
    setMes(e.target.value);

    if (e.target.value !== "") {
      socket.emit("typing", { from, to });
    } else {
      socket.emit("stop typing", { from, to });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      from,
      to,
      msg: txt,
      date: new Date(),
    };

    socket.emit("new message", data);

    const dt = {
      other_user: to,
      date: new Date(),
      text: txt
    };

    newMessage(dt);

    sendMessage(data)
      .then(() => {
        newMessage([...mess, data]);
        setMes("");
        setTyping(false);
        scrollToBottom();
      });
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h4" component="h4">
        {to && dis ? dis : "Chat"}
      </Typography>
      <div className={classes.flex}>
        <div className={classes.chatWindow} id="style-3">
          {mess.map((chat, index) => (
            <div
              key={index}
              className={chat.from === from ? classes.userSent : classes.friendSent}
            >
              <Typography variant='body1' gutterBottom>{chat.msg}</Typography>
              <span style={{ float: "right" }}>{timeSince(chat.date)}</span>
            </div>
          ))}
          <div ref={chatEndRef} />
          {dis && typing && (
            <div>
              <br />
              <Typography variant="caption" style={{ color: "blue" }}>
                {dis.split(" ")[0]} is typing a message ...
              </Typography>
            </div>
          )}
        </div>
      </div>
      <div className={classes.flex}>
        <TextField
          label="Type your message..."
          className={classes.chatBox}
          value={txt}
          onChange={onChange}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onSubmit}
          disabled={!txt}
        >
          Send
        </Button>
      </div>
    </Paper>
  );
};

Dashboard.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  mess: PropTypes.array.isRequired,
  dis: PropTypes.string,
  newMessage: PropTypes.func.isRequired,
  socket: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default Dashboard;
