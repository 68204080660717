
import * as React from 'react';
import Paper from '@mui/material/Paper';
import { ValueScale, Animation } from '@devexpress/dx-react-chart';

import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  LineSeries,
  ZoomAndPan,
  Legend,
  Title,
} from '@devexpress/dx-react-chart-material-ui';

import CircularProgress from '@mui/material/CircularProgress';

import {onlineDaily} from '../../utils';

export default class LineGraphs extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data:null,
      title:props.title,
    };
  }

  componentDidMount(){
    onlineDaily().
    then(data=>{
      if (data.length>0){
        this.setState({data: data});
        console.log(data);
      }
    })
  }

  render() {
    
    return (
      <Paper style={{display: 'inline-block' , width:650 , height:450 }} className={"graph1-paper"}>
        {this.state.data ?
        <Chart data={this.state.data} width={650} height={450} className={"graph1-paper-1"}>
          
        <ValueScale name="y"/>
          
          <ArgumentAxis />
          <ValueAxis/>

          <LineSeries valueField="count" argumentField="_id" name="Daily Active Users"/>

          <Legend/>

          <ZoomAndPan />
          <Animation />
          <Title text={this.state.title} />
        </Chart>
        : 
        <div className='center'><CircularProgress size={40} thickness={5} /></div>
        
        }
      </Paper>
    );
  }
}
