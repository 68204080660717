import React, {useState , useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import { green, orange, red, blue } from '@mui/material/colors';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import StarsIcon from '@mui/icons-material/Stars';
import Paper from '@mui/material/Paper'
import FiberNewIcon from '@mui/icons-material/FiberNew';
import PicNWords from './pic-n-words-p2';


const useStyles = makeStyles({
  
    green: {
      backgroundColor: green[500],
      float:'right'
    },
  
    orange: {
      backgroundColor: orange[500],
      float:'right',
      width:90,
      height:90,

    },
  
    red: {
      backgroundColor: red[500],
      float:'right',
      width:90,
      height:90,
    },
  
    blue: {
      backgroundColor: blue[500],
      float:'right',
      width:90,
      height:90,
    },
  
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  
  });

  

export default function MostP(props){

    const classes = useStyles();


    useEffect(()=>{


        console.log("user object passed ",props.log);



    } , [props.user])


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            >
        <Grid container
            direction="col"
            justifyContent="space-between"
            alignItems="center"
            style={{borderBottom:"10px solid black", width:"auto"}}
        >
        
        <Grid item>
        <Typography variant="h4" component="h4" gutterBottom style={{marginRight:'20px'}}>
            {props.title}
        </Typography>
        </Grid>
        
        <Grid item>
                {props.icon===4?
                
            <Avatar className={classes.orange}>
                <WhatshotIcon style={{width:"80%", height:"80%"}}/>
            </Avatar>

                :
                props.icon===5?

            <Avatar className={classes.blue}>
                <StarsIcon style={{width:"80%", height:"80%"}}/>
            </Avatar>
            :
            props.icon===6?

            <Avatar className={classes.red}>
                <FiberNewIcon style={{width:"80%", height:"80%"}}/>
            </Avatar>
            :
            null
    }    
        </Grid>
        
        </Grid>

        {props.user && props.extra ?

        <Grid             
            container
            direction="col"
            justifyContent="center"
            alignItems="center"
        >

    <Paper style={{width:"50%", height:"80%"}}>
    <Grid item>
        <br/>
        <br/>
    </Grid>

    <Grid             
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
    >
    <Grid item>
            
            {
            props.user.profile?
            <PicNWords user={props.user}/>
            :
            <Avatar style={{width:150, height:150}}>

                <AccountBoxIcon style={{width:"90%", height:"90%"}}/>

            </Avatar>
            }

        </Grid>
        
</Grid>

<Grid             
    item
>
<br/>
<br/>
</Grid>
        

    <Typography variant="p" component="p" gutterBottom style={{}}>
        User Name : <a onClick={(e)=>{window.location.href = "#/user/"+props.user.id} }>{props.user.first_name+" "+ props.user.last_name}</a>
    </Typography>

<br/>       

    <Typography variant="p" component="p" gutterBottom style={{}}>
        Industry : {props.user && props.extra && !props.user.industry ? "Not Set" : props.user.industry}
    </Typography>

<br/>


    <Typography variant="p" component="p" gutterBottom style={{}}>
        Job Title : {props.user && props.extra && !props.user.position ? "Not Set" : props.user.position}
    </Typography>

<br/>
    
    
    <Typography variant="p" component="p" gutterBottom style={{}}>
        Company : {props.user && props.extra && !props.user.user_company ? "Not Set" : props.user.user_company}
    </Typography>

<br/>

    <Typography variant="p" component="p" gutterBottom style={{}}>
        {props.icon===6?

        "Date Created : "+  (new Date(props.extra.dateCreated)).toDateString()

        :
        "Number of Profile Views : "+ (props.icon===5? props.extra.totalThisWeek :props.extra.number)

        }
    </Typography>
    </Paper>


         </Grid>           

         :



         <div className='center'><CircularProgress size={40} thickness={5} /></div>

        }
            
        </Grid>
    );


}

