import React, { useState, useEffect } from 'react';
import { TextField, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, CircularProgress, Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import StarComponent from '../components/star_component';
import { voteUnclaimed } from '../utils';

const RateUnclaimedProfile = ({ socket }) => {
  const [formData, setFormData] = useState({
    rating: '0',
    review: '',
    name: '',
    company: '',
    position: '',
    relationship: ''
  });
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(null);
  const [status, setStatus] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    socket.emit("user-click-event", { event: "rate-unclaimed-user page", user });
  }, [socket]);

  const handleRatingChange = (nextValue) => {
    setFormData(prevState => ({ ...prevState, rating: nextValue }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const user = JSON.parse(localStorage.getItem('user'));
    const data = {
      ...formData,
      created_by: user.id,
      profile: user.profile,
      created_by_name: `${user.first_name} ${user.last_name}`
    };

    voteUnclaimed(data)
      .then(res => {
        setLoading(false);
        setMsg(res.msg);
        setStatus(res.status);
      });
  };

  const handleDialogClose = () => {
    setMsg(null);
    if (status === 'success') {
      navigate('/');
    }
  };

  return (
    <div className='container center'>
      <h2>Rate a new person</h2>
      <p>Create a profile for someone to claim later!</p>

      <br />

      <div className='row'>
        <div className='col-6'>
          <TextField
            name='name'
            label='*Name'
            value={formData.name}
            onChange={handleChange}
            multiline
            rows={2}
            maxRows={4}
            fullWidth
            margin="normal"
          />
          <TextField
            name='company'
            label='*Company'
            value={formData.company}
            onChange={handleChange}
            multiline
            rows={2}
            maxRows={4}
            fullWidth
            margin="normal"
          />
          <TextField
            name='position'
            label='*Position'
            value={formData.position}
            onChange={handleChange}
            multiline
            rows={2}
            maxRows={4}
            fullWidth
            margin="normal"
          />
          <br />
          <h3>Rating</h3>
          <p>Not selecting a star will result in a zero rating</p>
          <StarComponent rating={formData.rating} onRatingChange={handleRatingChange} />
          <TextField
            name='review'
            label='Review'
            value={formData.review}
            onChange={handleChange}
            multiline
            rows={2}
            maxRows={4}
            fullWidth
            margin="normal"
          />
          <br />
        </div>
        <div className='col-6'>
          <FormControl component="fieldset">
            <FormLabel component="legend">How do you know this person?</FormLabel>
            <RadioGroup
              name="relationship"
              value={formData.relationship}
              onChange={handleChange}
            >
              <FormControlLabel value="friend" control={<Radio />} label="Friend" />
              <FormControlLabel value="customer" control={<Radio />} label="Customer" />
              <FormControlLabel value="boss" control={<Radio />} label="I am his/her manager or boss" />
              <FormControlLabel value="colleague" control={<Radio />} label="I work with this person" />
              <FormControlLabel value="subordinate" control={<Radio />} label="He/She is my manager or boss" />
              <FormControlLabel value="family" control={<Radio />} label="Family" />
              <FormControlLabel value="other" control={<Radio />} label="Other" />
            </RadioGroup>
          </FormControl>
          <br /><br />
          <Button variant="contained" color="primary" disabled={loading} onClick={handleSubmit}>
            {loading ? <CircularProgress size={24} /> : 'Submit'}
          </Button>
          <br /><br />
          <Button variant="outlined" onClick={() => navigate('/search')}>
            Back
          </Button>
        </div>
      </div>

      <Dialog
        open={Boolean(msg)}
        onClose={handleDialogClose}
      >
        <DialogTitle>{msg}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {status === 'success' ? 'Your rating has been submitted successfully.' : 'There was an error submitting your rating.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RateUnclaimedProfile;
