import React from 'react';
import Paper from '@mui/material/Paper';
import FullWidthTabs from './tabbed-view-user';
import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles(theme => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        
      },
      maxWidth:'700px',
        height:'400px',
        display:'block',
        marginLeft:'550px',
        overflowY:"scroll",
      },
    //new item here
  }));


export default function ViewUser (props){

    const classes = useStyles();


    return(
        <Paper className={classes.root}>

        <FullWidthTabs user={props.user} enabled={props.enabled} setEnabled={props.setEnabled}/>

        </Paper>
    );
} ;
