// this is to complete your profile so that you don't upload a picture with no words
import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import SelectInput from '@mui/material/Select/SelectInput';

import { MenuItem } from '@mui/material';

import {Button} from '@mui/material';
import UpdatePictureComponent from './dashboard/uploadimage';
import { updateWords, updateWork, inviteFriends } from '../utils';
import Dialog from '@mui/material/Dialog';
import jobs from '../data/jobs';


export default class CompleteProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {

            word_one: '',
            word_two: '',
            word_three: '',
            word_four: '',
            word_five: '',
            

            open: false,
            loading_update: false,

            first_name: '',
            last_name: '',
            mobile: '',
            position: '',
            other_position: '',
            company: '',
            industry: '',
            other_industry: '',
            suburb: '',
            province: '',

        }
        this.onSubmitWords = this.onSubmitWords.bind(this);
        this.onSubmitWork = this.onSubmitWork.bind(this);
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }


    handleOpen = () => {
        this.setState({
            open: true
        });
    };
    
    handleClose = () => {
        this.setState({open: false});
    };


    refresh = () => {
        window.location.reload();
    }

    onSubmitWords(e) {
        e.preventDefault();
        const user = JSON.parse(localStorage.getItem('user'));
        const id = user.id;

        var data = {
            'word_one': this.state.word_one,
            'word_two': this.state.word_two,
            'word_three': this.state.word_three,
            'word_four': this.state.word_four,
            'word_five': this.state.word_five
        }
        this.setState({ loading_update : true })
        updateWords(data, id)
        .then(results => {
            // console.log('UPDATE RESULTS!!!!', results.five_words)
            if (results) {
                this.setState({ loading_update: false})
                var user = JSON.parse(localStorage.getItem('user'));
                user.five_words = results.five_words
                localStorage.setItem('user', JSON.stringify(user))

            }
            if (results.status === 400) {
                this.setState({ err: results.message, loading_update: false})
            }
        })
    }

    onSubmitWork(e) {
        e.preventDefault();

        const user = JSON.parse(localStorage.getItem('user'));
        const id = user.id;

        var data = {
            'position': this.state.position === 'other' ? this.state.other_position : this.state.position,
            'company': this.state.company,
            'industry': this.state.industry === 'other' ? this.state.other_industry : this.state.industry,
            'suburb': this.state.suburb,
            'province': this.state.province,
        }

        this.setState({ loading_update : true })
        updateWork(data, id)
        .then(results => {
            if (results) {
                this.setState({ loading_update: false})
                var user = JSON.parse(localStorage.getItem('user'));
                user.position = results.position
                user.user_company = results.user_company
                user.industry = results.industry
                user.suburb = results.suburb
                user.province = results.province
                localStorage.setItem('user', JSON.stringify(user))
            }
            if (results.status === 400) {
                this.setState({ err: results.message, loading_update: false})
            }
        })
    }

    onSubmitPersonal = (e) => {
        e.preventDefault();

        const user = JSON.parse(localStorage.getItem('user'));
        const id = user.id;

        var data = {
            'first_name': this.state.first_name,
            'last_name': this.state.last_name,
            'mobile': this.state.mobile
        }

        this.setState({ loading_update : true })
        updateWork(data, id + '/?token=' + localStorage.getItem('token'))
        .then(results => {
            if (results) {
                this.setState({ loading_update: false})
                var user = JSON.parse(localStorage.getItem('user'));
                user.first_name = results.first_name
                user.last_name = results.last_name
                user.mobile = results.mobile
                localStorage.setItem('user', JSON.stringify(user))
            }
            if (results.status === 400) {
                this.setState({ err: results.message, loading_update: false})
            }
        })
    }

    inviteFriends = (e) =>{
        this.props.history.push('/rateme');
    }


    render () {
        const user = JSON.parse(localStorage.getItem('user'));

        const industries = Object.keys(jobs);

        const actions = [
            <Button
              label="Cool"
              primary={true}
              onClick={ this.refresh }

            />
        ];

        return (
            <div className='center'>
                {
                    !user.first_name || !user.last_name ?
                    <form onSubmit={this.onSubmitPersonal}>
                        <h3 >Update you name and surname</h3>
                        <TextField
                            name='first_name'
                            placeholder='First name'
                            type='text'
                            value={this.state.first_name}
                            onChange={this.handleChange}
                        />
                        <br />
                        <TextField 
                            name='last_name'
                            placeholder='Last name'
                            type='text'
                            value={this.state.last_name}
                            onChange={this.handleChange}
                        />
                        <br />
                        <TextField 
                            name='mobile'
                            placeholder='Mobile'
                            type='text'
                            value={this.state.mobile}
                            onChange={this.handleChange}
                        />
                        <br/>
                        <i><small>Add your phone number for us to share your details with potential recruiters</small></i>
                        <div className='container'>
                            <Button
                                type='submit'
                                style={{marginBottom: '10px'}}
                                label=  'Upload details' 
                                secondary={true} 
                                disabled={!this.state.first_name || !this.state.last_name}
                                onClick={this.handleOpen}
                            />
                        </div>
                        {
                            this.state.loading_update ?
                            <Dialog
                                modal={true}
                                open={this.state.open}
                            >
                                <p>Updating.....</p>
                            </Dialog> :
                            <Dialog
                                className='center'
                                title="Your update has been successful"
                                actions={actions}
                                modal={false}
                                open={this.state.open}
                                onRequestClose={this.handleClose}
                            >
                            </Dialog>
                        }
                    </form> :
                    !user.five_words || user.five_words.length === 0 ?
                    <form onSubmit={this.onSubmitWords}>
                            <h4>Please choose five words that describe your character</h4>
                                <TextField
                                    name='word_one'
                                    floatingLabelStyle={{ color: '#fff' }}
                                    floatingLabelText="Word one"
                                    value={ this.state.word_one }
                                    onChange={ this.handleChange }
                                /><br />
                                <TextField
                                    name='word_two'
                                    floatingLabelStyle={{ color: '#fff' }}
                                    floatingLabelText="Word two"
                                    value={ this.state.word_two }
                                    onChange={ this.handleChange }
                                /><br />
                                <TextField
                                    name='word_three'
                                    floatingLabelStyle={{ color: '#fff' }}
                                    floatingLabelText="Word three"
                                    value={ this.state.word_three }
                                    onChange={ this.handleChange } 
                                /><br />
                                <TextField
                                    name='word_four'
                                    floatingLabelStyle={{ color: '#fff' }}
                                    floatingLabelText="Word four"
                                    value={ this.state.word_four }
                                    onChange={ this.handleChange }
                                /><br />
                                <TextField
                                    name='word_five'
                                    floatingLabelStyle={{ color: '#fff' }}
                                    floatingLabelText="Word five"
                                    value={ this.state.word_five }
                                    onChange={ this.handleChange }
                                />
                                <br /><br />
                                <Button
                                    type='submit'
                                    style={{marginBottom: '10px'}}
                                    label=  'Submit words' 
                                    secondary={true}
                                    disabled={
                                        !this.state.word_one ||
                                        !this.state.word_two ||
                                        !this.state.word_three ||
                                        !this.state.word_four ||
                                        !this.state.word_five
                                    }
                                    onClick={this.handleOpen}
                                />
                                {
                                    this.state.loading_update ?
                                    <Dialog
                                        modal={true}
                                        open={this.state.open}
                                    >
                                        <p>Updating.....</p>
                                    </Dialog> :
                                    <Dialog
                                        className='center'
                                        title="Your update has been successful"
                                        actions={actions}
                                        modal={false}
                                        open={this.state.open}
                                        onRequestClose={this.handleClose}
                                    >
                                    </Dialog>
                                }
                    </form> :
                        !user.profile ?
                    <div>
                        <UpdatePictureComponent socket={this.props.socket}/>
                    </div>
                    
                    
                     :
                     
                    !user.position ?
                    <form onSubmit={this.onSubmitWork}>
                        <h3 >Update what you do</h3>
                        <TextField 
                            name='company'
                            placeholder='Company'
                            type='text'
                            value={this.state.company}
                            onChange={this.handleChange}
                        />
                        <br />
                        <SelectInput
                                name="industry"
                                floatingLabelText="Industry"
                                inputStyle={{
                                    color: '#fff !important',
                                    textAlign: 'left'
                                }}
                                floatingLabelStyle={{
                                    left: '0',
                                    color: '#fafafa !important'
                                }}
                                labelStyle={{
                                    color: '#fafafa !important'
                                }}
                                value={this.state.industry}
                                onChange={(e, index, val) => this.setState({ industry: val })}
                                >
                                {
                                    industries.sort().map((i, index) => (
                                        <MenuItem key={index} value={i} primaryText={i} />
                                    ))
                                }
                                <MenuItem value={'other'} primaryText={"Other"} />
                            </SelectInput>

                            {
                                this.state.industry === 'other' ?
                                <div>
                                    <br/>
                                    <TextField
                                        name='other_industry'
                                        placeholder='Other Industry'
                                        type='text'
                                        value={this.state.other_industry}
                                        onChange={this.handleChange}
                                    /> 
                                </div>: null
                            }
                            <br/>
                            {
                                this.state.industry ?
                                <SelectInput
                                    name='position'
                                    floatingLabelText="Position"
                                    inputStyle={{
                                        color: '#fff !important',
                                        textAlign: 'left'
                                    }}
                                    floatingLabelStyle={{
                                        left: '0',
                                        color: '#fafafa !important'
                                    }}
                                    labelStyle={{
                                        color: '#fafafa !important'
                                    }}
                                    value={this.state.position}
                                    onChange={(e, index, val) => this.setState({ position: val })}
                                    >
                                    {
                                        jobs[this.state.industry] && jobs[this.state.industry].length > 0 && jobs[this.state.industry].sort().map((i, index) => (
                                            <MenuItem key={index} value={i} primaryText={i} />
                                        ))
                                    }
                                    <MenuItem value={'other'} primaryText={"Other"} />
                                </SelectInput> : null
                            }
                            {
                                this.state.position === 'other' ?
                                <div>
                                    <br/>
                                    <TextField
                                        name='other_position'
                                        placeholder='Other Position'
                                        type='text'
                                        value={this.state.other_position}
                                        onChange={this.handleChange}
                                    /> 
                                </div>: null
                            }
                        <br />
                        <TextField 
                            name='suburb'
                            placeholder='Suburb'
                            type='text'
                            value={this.state.suburb}
                            onChange={this.handleChange}
                        />
                        <br />
                        <TextField
                            name='province'
                            placeholder='Province'
                            type='text'
                            value={this.state.province}
                            onChange={this.handleChange}
                        />
                        <br />
                        <div className='container'>
                            <Button
                                type='submit'
                                style={{marginBottom: '10px'}}
                                label=  'Upload details' 
                                secondary={true} 
                                disabled={
                                    !this.state.company ||
                                    !this.state.industry ||
                                    !this.state.position ||
                                    !this.state.suburb ||
                                    !this.state.province
                                }
                                onClick={this.handleOpen}
                            />
                        </div>
                        {
                            this.state.loading_update ?
                            <Dialog
                                modal={true}
                                open={this.state.open}
                            >
                                <p>Updating.....</p>
                            </Dialog> :
                            <Dialog
                                className='center'
                                title="Your update has been successful"
                                actions={actions}
                                modal={false}
                                open={this.state.open}
                                onRequestClose={this.handleClose}
                            >
                            </Dialog>
                        }
                    </form> :
                                        
                    <div>
                        <p>Thank you for completing your profile</p>
{
    // sharre to social media goes here...
}
                        <p>One last thing, we'd like to ask if you want to ask some of your contacts via 
                            mail to rate your profile. If yes, click on the "Invite Friends" Button else just click on
                            "My Dashboard".
                        </p>


                        <Button
                            secondary={true}
                            label= 'My Dashboard'
                            onClick={() => { this.props.history.push('/mydashboard')}}  
                        />
                        <Button
                            secondary={true}
                            style={{marginLeft:"50px"}}
                            label= 'Invite Friends'
                            onClick={this.inviteFriends}  
                        />
                

                    </div>
                }
                       
            </div>
        )
    }
}