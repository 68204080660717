import React , {useEffect , useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import { userlist, unclaimedUserlist } from './../../utils';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    float:'left',
    marginLeft:'100px',
    display:'block',
    height:'400px',
    overflowY:"scroll"
  },
}));

export default function CheckboxListSecondary(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([-1]);

  const [checked2, setChecked2] = React.useState(-1);

  const [loaded, setL] = useState(false);
  const [users , setUsers]  = useState(null);
  const [results , setRes]  = useState(null);
  const [unclaimed_users , setUUsers]  = useState(null);


  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];


    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleToggle2 = (e , i , id) => {
    const currentIndex = checked2;
    const newChecked = i;

    console.log(currentIndex , newChecked , id);

    if (currentIndex !== newChecked) {
      setChecked2(newChecked);
      props.setUser(id);
    } else {
        setChecked2(-1);
        props.setUser(null);
    }

  };

  useEffect(()=>{

    //

    userlist()
        .then((results) => {
            // console.log('USERS', results)
            unclaimedUserlist()
            .then(unclRes => {
                    setUsers(results);
                    setUUsers(unclRes);
                    setL(true);
            })
        })
        
    .catch();


  }, []);

  useEffect(()=>{

    if (users || unclaimed_users) {
    const result = (users.concat(unclaimed_users)) ;
    setRes(result);
    }

  } , [unclaimedUserlist , users]);


  useEffect(()=>{

    if (users || unclaimed_users) {
  
      setL(false);
      let result = (users.concat(unclaimed_users));
      result = result.filter(user=>{

        let search = props.search && props.search.toLowerCase().trim();

        let email = props.otherFeature==="Email" && search;
        let name = props.otherFeature==="Name" && search;
        let words = props.otherFeature==="5 Words" && search;
        let company = props.otherFeature==="Company" && search;
        let location = props.otherFeature==="Location" && search;
        let industry = props.industry && props.industry.toLowerCase();
        let position = props.job && props.job.toLowerCase();
        console.log("position" , position? "true":"false");
        console.log("industry" , industry? "true":"false");

        var numMatchWords = 0;

        if (words){
          words = words.split(";");

          let words_db = user.five_words && user.five_words;
          if (words_db){

            words_db.map(i=>{

              words.map(comp=>{
                if (i.toLowerCase().includes(comp.toLowerCase()))
                  numMatchWords+=1;
              })

            })

          }

        }

      let email2 = user.email && user.email.toLowerCase().trim()
      let name2 = user.first_name && user.first_name.toLowerCase().trim();
      let last_name2 = user.last_name && user.last_name.toLowerCase().trim();
      let company2 = user.user_company && user.user_company.toLowerCase().trim();
      let industry2 = user.industry && user.industry.toLowerCase().trim();
      let position2 = user.position && user.position.toLowerCase().trim();
      let suburb = user.suburb && user.suburb.toLowerCase().trim();
      let province = user.province && user.province.toLowerCase().trim();
      //as for other industry/position ??

      if (props.otherFeature && !props.industry && !props.search) return true
      else
      return (
        
        (user && position && position2 && industry && industry2 && position2.toLowerCase().includes(position) && industry2.toLowerCase().includes(industry) ) ||
        (user && industry && !position && industry2 && industry2.toLowerCase().includes(industry)) ||
        
        (user && name2 && name && name2.toLowerCase().includes(search)) || 
        (user && last_name2 && name && last_name2.toLowerCase().includes(search)) ||
        (user && email2 && email && email2.toLowerCase().includes(search)) || 
        (
          (user && name2 && name && search && search.split(" ")[0] && name2.toLowerCase().includes(search.split(" ")[0])) &&
          (user && last_name2 && name && search && search.split(" ")[1] && last_name2.toLowerCase().includes(search.split(" ")[1])) 
        ) ||
        (user && company && company2 && company2.toLowerCase().includes(search)) ||
        (user && location && suburb && suburb.toLowerCase().includes(search)) ||
        (user && location && province && province.toLowerCase().includes(search)) ||
        (user && words && numMatchWords>0)     
      );
      



          


      });

      setRes(result);
      setL(true);
    
  }

  } , [props.search , props.industry , props.job , props.otherFeature]);



  return (
    <Paper className={classes.root}>
    <List dense>
      {loaded && users && unclaimed_users && results && console.log(results)}
      { loaded && users && unclaimed_users && results && results.map((value, i) => {
        const labelId = `checkbox-list-secondary-label-${i}`;
        if (value) return (
          <ListItem key={i} button
          selected = {i === checked2}
          onClick={event => handleToggle2(event, i , value.id)}
          >
            <ListItemAvatar>
              <Avatar
                alt={`Avatar n°${i + 1}`}
                src={value.profile}
              />
            </ListItemAvatar>
            <ListItemText id={labelId} primary={value.first_name+ " " + value.last_name} />
            <ListItemSecondaryAction>
              <Checkbox
                edge="end"
                onChange={handleToggle(i)}
                checked={checked.indexOf(i) !== -1}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
    </Paper>
  );
}