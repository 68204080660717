import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const LandingPage = ({ socket }) => {
    const navigate = useNavigate();

    useEffect(() => {
        // Emit socket event after component has mounted
        socket.emit("user-click-event", { event: "landing page", user: null });

        // Clean-up function (equivalent to componentWillUnmount in class components)
        return () => {
            // Perform any cleanup here if necessary
        };
    }, [socket]);

    const flexStyle = {
        minHeight: '80vh',
        alignContent: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const handleSignUpClick = () => {
        navigate('/signup');
    };

    const handleLoginClick = () => {
        navigate('/login');
    };

    return (
        <div style={{ width: "100%" }}>
            <div style={flexStyle}>
                <div className='row container center'>
                    <div>
                        <img
                            style={{ width: '300px', height: 'auto' }}
                            className='container'
                            src='/img/logo.png'
                            alt=''
                        />
                    </div>
                    <div className=''>
                        <div className="container">
                            <h2>Welcome to UpSTAR!</h2>
                            <div>
                                <div>
                                    <span style={{ fontSize: '13px' }}>Show the world how good you are.</span>
                                    <span style={{ fontSize: '13px' }}>Rate the performance of anyone, anywhere, anytime.</span>
                                    <span style={{ fontSize: '13px' }}>Go on, try it. You may have been rated already!</span>
                                </div>
                            </div>
                            <br /><br /><br />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSignUpClick}
                                type='button'
                            >
                                Sign Up
                            </Button>
                            <Button
                                style={{ marginLeft: 15 }}
                                variant="contained"
                                color="primary"
                                type='button'
                                onClick={handleLoginClick}
                            >
                                Log In
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;
