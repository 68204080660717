import React, { Component } from 'react';
import { userlist } from '../../utils';
import {TextField} from '@mui/material';
import { ListItem } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import {CircularProgress} from '@mui/material';
import {Button} from '@mui/material'
import { register } from '../../utils';



class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            loading: false,
            selectedPerson: null,
            logged_in_user : JSON.parse(localStorage.getItem('user')),
            search_value: ''
        }
        this.handleChange = this.handleChange.bind(this);
        var x = localStorage.getItem("user");
        this.props.socket.emit("user-click-event" , {event:"quick-search page", user:x} );
    }

    componentDidMount() {
        this.setState({ loading: true })
        userlist()
        .then((results) => {
            // console.log('USERS', results)
            this.setState({ 
                loading: false,   
                users: results
            })
        })
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value})
    }

    handleClose = () => {
        this.setState({ selectedPerson: null })
    } 

    render() {
        
        const result = this.state.users.filter(user => {
            const search = this.state.search_value && this.state.search_value.toLowerCase()
            const email = user.email && user.email.toLowerCase()
            const name = user.first_name && user.first_name.toLowerCase();
            const last_name = user.last_name && user.last_name.toLowerCase();
            return (user && name && name.includes(search)) || (user && email && email.includes(search)) || (user && last_name && last_name.includes(search));
        });

        const top_users = this.state.users.sort((a, b) => parseFloat(b.votes) - parseFloat(a.votes)).filter((i, index) => index < 5)

        return (
            <div className='container'>
                <a style={{
                    position: 'absolute',
                    top: '70px',
                    color: '#fff'
                }} href='/#/login'>Quick rate mode: Login to complete your profile for full access</a>
                 <div className='center'>
                    <h1>Search</h1>
                    <TextField 
                            placeholder='Search for a person'
                            name='search_value'
                            type='text'
                            value={this.state.search_value}
                            onChange={this.handleChange}
                        />
                </div>
                {
                    this.state.loading ?
                    <div className='center'>
                        <br/><br/>
                        Please be patient while we search for {this.state.search_value}...
                        <br/><br/>
                        <CircularProgress size={80} thickness={5} />
                    </div> :
                    <div>
                        {
                            this.state.search_value.length > 2 ? result.map((user, index) => {
                                if(user.id === this.state.logged_in_user.id) {
                                    return null
                                }
                                return (
                                    <ListItem 
                                        onClick={() => {
                                            this.props.history.push('/quick-rate/' + user.id)
                                        }}
                                        primaryText={(user.first_name + ' ' + user.last_name)} 
                                        leftAvatar={<Avatar src={user.profile || '/img/avatar.png'} />} 
                                        rightAvatar={<Avatar backgroundColor='#2E95C6' color='#FFF'> {user.votes && user.votes.toFixed(1)} </Avatar>}
                                        secondaryText={<span>{user.email}<br/>Works at {user.user_company}</span>}
                                        secondaryTextLines={2}
                                        key={index}
                                    />
                                )
                            }) : 
                            top_users.map((user, index) => {
                                if(user.id === this.state.logged_in_user.id) {
                                    return null
                                }
                                return (
                                    <ListItem 
                                        onClick={() => {
                                            this.props.history.push('/quick-rate/' + user.id)
                                        }}
                                        primaryText={(user.first_name + ' ' + user.last_name)} 
                                        leftAvatar={<Avatar src={user.profile || '/img/avatar.png'} />} 
                                        rightAvatar={<Avatar backgroundColor='#2E95C6' color='#FFF'>{user.votes && user.votes.toFixed(1)}</Avatar>}
                                        secondaryText={<span>{user.email}<br/>Works at {user.user_company}</span>}
                                        secondaryTextLines={2}
                                        key={index}
                                    />
                                )
                            })
                        }
                    </div>
                }
            </div>
        )
    }
}

export default class extends Component {
    state = {
        email: '',
        password: ''
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value})
    }

    onSubmit =(e) => {
        e.preventDefault();
        var data = {
            'email': this.state.email,
            'password1': this.state.password,
            'password2': this.state.confirmpassword,
        }
        this.setState({ loading: true })
        // console.log ('THIS IS THE DATA', data)
        register(data)
        .then(results => {
            console.log('REGISTER RESULTS', results);
            if (results.status === 400) {
                this.setState({ err: results.message, loading: false, stepIndex: 0 });
            }

            if (results.err) {
                this.setState({ err: results.err, stepIndex: 0, loading: false})
            }
            localStorage.setItem('user', JSON.stringify(results.user))
            // if(results.result === 'Registered') {
                window.location.reload()
            // }
        })
    
    }

    render() {
        const user = localStorage.getItem('user')
        const parsed_user = JSON.parse(user)
        
        return parsed_user ?
        <Dashboard history={this.props.history}/> :
        <div>
            <h2>Quick signup</h2>
                <TextField
                    name='email'
                    placeholder='* Email'
                    type='text'
                    value={this.state.email}
                    onChange={this.handleChange}
                />
                <br />
                <TextField 
                    name='password'
                    placeholder='* Password'
                    type='password'
                    value={this.state.password}
                    onChange={this.handleChange}
                />
                <br />
                <TextField 
                    name='confirmpassword'
                    placeholder='* Confirm password'
                    type='password'
                    value={this.state.confirmpassword}
                    onChange={this.handleChange}
                />
                <br/>
                <Button
                    style={{ marginRight: '30px'}}
                    label='Submit'
                    primary={true}
                    type='submit'
                    onClick={this.onSubmit}
                /> 
        </div> 
    }
}