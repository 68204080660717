import React from 'react';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';

const StarComponent = ({ rating, onRatingChange }) => {
  const getRatingLabel = (rating) => {
    if (rating < 1) return 'Bad';
    if (rating < 2) return 'Not Bad';
    if (rating < 3) return 'Good';
    if (rating < 4) return 'Very good';
    if (rating < 5) return 'Excellent';
    if (rating === 5) return 'Blew my socks off!';
    return '';
  };

  return (
    <div>
      <Rating
        name="rate1"
        value={rating}
        onChange={(event, newValue) => onRatingChange(newValue)}
        precision={0.5} // Allows half-star ratings
        size="large" // Adjust size as needed
        sx={{
          color: '#f7f32c',
          '& .MuiRating-iconEmpty': {
            color: '#e1e7ea',
          },
        }}
      />
      <Typography variant="subtitle2" component="div">
        {getRatingLabel(rating)}
      </Typography>
    </div>
  );
};

export default StarComponent;
