import React, { Component } from 'react';
import { Card, CardContent } from '@mui/material'
import {Chip} from '@mui/material';

import Dialog from '@mui/material/Dialog';
import Star from '@mui/icons-material/Star';
import StarBorder from '@mui/icons-material/StarBorder';
import HalfStar from '@mui/icons-material/StarHalf';
import StarComponent from '../components/star_component';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import Moment from 'react-moment';
import { rateNow, getUser, votes, editVote } from '../utils';
// import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import PicNWords from '../components/pic-n-words';
import PicNWordsSmall from '../components/pic-n-words-small';



const styles = {
  chip: {
    margin: 4,
    display: 'inline-block'
  },
  wrapper: {
    display: 'inline-block'
  },
  block: {
    maxWidth: 250,
  },
  radioButton: {
    marginBottom: 16,
  },
};


export default class RatingDash extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      open_thanks: false,
      edit_review_thanks: false,

      review: '',
      edit_review: '',
      votee: null,
      user: null,
      rating: 0,
      full_list_open: false,

      loading: false,
      loading_submit: false,
      loading_edit_submit: false,

      editReviewOpen: false,
      vote_id: '',

      relationship: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.onRatingChange = this.onRatingChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.editReviewSubmit = this.editReviewSubmit.bind(this);
    
    
  }

  componentDidMount() {
    this.setState({ loading: true })
    getUser(this.props.match.params.id)
      .then(result => {
        // console.log("RESULT RATING DASH", result);
        const voteeData = result
        voteeData.vote_list = voteeData && voteeData.vote_list && voteeData.vote_list;
        // console.log('vote_list', voteeData.vote_list);
        this.setState({
          votee: voteeData,
          user: JSON.parse(localStorage.getItem('user')),
          loading: false,
          err: null
        })

        var x = localStorage.getItem("user");
        this.props.socket.emit("user-click-event" , {event:"another-user page", user:x , other_user:this.props.match.params.id} );

      })
  }

  handleOpen = () => {
    window.scrollTo(0, 0)
    this.setState({ open: true });
  };

  handleEditReviewOpen = () => {
    this.setState({ editReviewOpen: true });
  };

  handleClose = () => {
    this.setState({ open: false, review: '', rating: 0, err: false, open_thanks: false });
  };

  handleEditReviewClose = () => {
    this.setState({ editReviewData: false, err: false });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  editReviewHandleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  onRatingChange = (nextValue, prevValue, name) => {
    this.setState({
      rating: nextValue
    });
  }

  editRatingChange = (nextValue, prevValue, name) => {
    const voteeData = this.state.editReviewData
    voteeData.score = nextValue
    this.setState({ editReviewData: voteeData });
  }

  refresh = () => {
    window.location.reload();
  }

  onSubmit = (e) => {
    e.preventDefault();
    const user = JSON.parse(localStorage.getItem('user'));
    // console.log('relationship', this.state.relationship)
    var data = {
      'created_for': this.state.votee.id,
      'created_by': user.id,
      'score': this.state.rating,
      'review': this.state.review,
      'created_by_name': user.first_name + ' ' + user.last_name,
      'profile': user.profile,
      'relationship': this.state.relationship
    }
    this.setState({ loading_submit: true, open: false });
    votes(data)
      .then(results => {

        if (results.status === 'error') {
          this.setState({ err: results.msg, loading_submit: false })
        } else {
          this.setState({ open_thanks: true, loading_submit: false });
          
          var x = localStorage.getItem("vote");
          if (x){

            rateNow(x).
            then((d)=>{
              if (d){
                console.log("updated");
                localStorage.removeItem("vote");
              }
            }).
            catch((err)=>{
              //error
              console.log("error");   
            });

          }
          
        }
      })
      .catch(err => {
        this.setState({ loading_submit: false, err })
      })
  }

  editReviewSubmit = (e) => {
    e.preventDefault();

    // console.log(this.state.edit_review);
    let voteID = this.state.vote_id;
    let data = {
      'score': this.state.editReviewData.score,
      'review': this.state.edit_review,
      'created_for': this.state.votee.id,
    }
    this.setState({ loading_edit_submit: true });
    editVote(data, voteID)
      .then(results => {
        if (results.err) {
          this.setState({ err: results.err, loading_edit_submit: false })
        }
        else {
          this.setState({ edit_review_thanks: true, loading_edit_submit: false });
        }
      })
  }

  toggle = () => {
    this.setState({
      full_list_open: !this.state.full_list_open
    });
  }


  navigate = (path) => {
    this.props.history.push(path)
}

  getVotes() {

    let vote_list = this.state.votee && this.state.votee.vote_list;
    let sort_list = vote_list.sort((a, b) => Date.parse(a.created) - Date.parse(b.created));
    let list = sort_list.reverse();

    if (this.state.full_list_open) {
      return list;
    }
    return list.slice(0, 6);
  }

  // }

  // graph data
  // thisMonthRating() {
  //     if ((this.state.votee && this.state.votee.avg_last_month !== null) && (this.state.votee && this.state.votee.avg_last_month !== undefined) ) {
  //         let avg = (this.state.votee && this.state.votee.votes) - (this.state.votee && this.state.votee.avg_last_month);
  //         return avg.toFixed(1)
  //     }
  //     else {
  //         return this.state.votee && this.state.votee.votes.toFixed(1)
  //     }
  // }

  render() {

   


    const { votee } = this.state;
    // console.log('VOTEE',votee );

    const user = JSON.parse(localStorage.getItem('user'));
    let user_id = user.id;

    console.log("USERS");
    console.log("USER 1 ", votee);
    console.log("USER 2 ", user);
    

    let scoreArray = (votee && votee.vote_list && votee.vote_list.map((vote) => vote.score)) || ([]);
    //console.log('score array', scoreArray)

    let scoreCount = votee && scoreArray.reduce((obj, item) => {
      if (!obj[item]) {
        obj[item] = 0;
      }
      obj[item]++;
      return obj;
    }, {});

    const data = [
      { name: '5', votes: votee && scoreCount['5'] },
      { name: '4', votes: votee && scoreCount['4'] },
      { name: '3', votes: votee && scoreCount['3'] },
      { name: '2', votes: votee && scoreCount['2'] },
      { name: '1', votes: votee && scoreCount['1'] },
      { name: '0', votes: votee && scoreCount['0'] }
    ]


    const rating = <div className='row'>
      <br />
      <div className='col-6'>
        <h1 style={{ marginTop: '-1px', marginBottom: '-15px' }}>{votee && votee.first_name + ' ' + votee.last_name}</h1>
        <p style={{ color: '#2E95C6' }}>Not clicking a star will result in a zero rating</p>
        <StarComponent rating={this.state.rating} onRatingChange={this.onRatingChange} />
        <TextField
          name='review'
          placeholder='Review'
          value={this.state.review}
          onChange={this.handleChange}
          multiLine={true}
          rows={2}
          maxRows={4}
        />
        <br />
        {/* {
                                    this.state.err ?
                                    <span style={{ color: 'red'}}>{this.state.err}<br /></span> :
                                    null
                                } */}
      </div>
      <div className='col-6'>
        <p style={{ textAlign: 'left', color: '#2E95C6' }}>How do you know {votee && votee.first_name + ' ' + votee.last_name}?</p>
        {/* <RadioButtonGroup name="shipSpeed" className='left' >
          <RadioButton
            value='friend'
            label="Friend"
            style={styles.radioButton}
            onClick={() => this.setState({ relationship: 'friend' })}
          />
          <RadioButton
            value='customer'
            label="Customer"
            style={styles.radioButton}
            onClick={() => this.setState({ relationship: 'customer' })}
          />
          <RadioButton
            value='boss'
            label="I am his/her manager or boss"
            style={styles.radioButton}
            onClick={() => this.setState({ relationship: 'boss' })}
          />
          <RadioButton
            value='colleague'
            label="I work with this person"
            style={styles.radioButton}
            onClick={() => this.setState({ relationship: 'colleague' })}
          />
          <RadioButton
            value='subordinate'
            label="He/She is my manager or boss"
            style={styles.radioButton}
            onClick={() => this.setState({ relationship: 'subordinate' })}
          />
          <RadioButton
            value='family'
            label="Family"
            style={styles.radioButton}
            onClick={() => this.setState({ relationship: 'family' })}
          />
          <RadioButton
            value='other'
            label="Other"
            style={styles.radioButton}
            onClick={() => this.setState({ relationship: 'other' })}
          />
        </RadioButtonGroup> */}
        <br />
        <Button
          label="Close"
          primary={true}
          onClick={this.handleClose}
        />
        <Button
          label="Submit"
          primary={true}
          onClick={(e => this.onSubmit(e))}
        />
      </div>
    </div>

    const upstar_actions = [
      <Button
        label='Rate someone else'
        secondary={true}
        onClick={() => this.props.history.push('/search')}
      />,
      <Button
        label='Check your latest reviews'
        secondary={true}
        onClick={() => this.props.history.push('/mydashboard')}
      />

    ];

    //   edit review action buttons
    const editReviewActions = [
      <Button
        label="Close"
        primary={true}
        onClick={this.handleEditReviewClose}
      />,
      <Button
        label="Submit"
        primary={true}
        keyboardFocused={true}
        onClick={(e => this.editReviewSubmit(e))}
      />,
    ];

    const editReview_actions = [
      <Button
        label='cool'
        primary={true}
        onClick={this.refresh}
      />
    ];

    return (
      <div className='container'>
        {
          this.state.loading ?
            <div className='center'><CircularProgress size={80} thickness={5} /></div> :
            this.state.open ?
              rating :
              <div>
                <div className='center'>
                  <div className='row'>
                    <div className='col-12'>
                      <Card style={{ backgroundColor: white, color: '#343434' }}>
                        {votee && votee.profile ? <PicNWords user={votee} /> : <img alt='' style={{
                          maxWidth: '280px',
                          width: '100%',
                          height: 'auto'
                        }} src='/img/avatar.png' />}

                        <h1 style={{ marginTop: '-1px', marginBottom: '-15px' }}>{votee && votee.first_name + ' ' + votee.last_name}</h1>
                        {
                          votee && votee.user_company === '' ?
                            <div>
                              <p></p><br />
                            </div> :
                            <p>{votee && votee.user_company}</p>
                        }


                        <div className='center'>
                          {
                            votee && votee.five_words === undefined ?
                              null :
                              votee && votee.five_words.map((word, index) => (
                                word && <div key={index} style={styles.wrapper}>
                                  {
                                    word === "" ?
                                      null :
                                      <Chip
                                        style={styles.chip}
                                      >
                                        {word}
                                      </Chip>
                                  }
                                </div>
                              ))
                          }
                        </div>

                        <div className='container'>
                          {
                            votee && votee.show_profile?
                          <div className='row'>
                            {
                              votee && votee.vote_list.length === 0 ?
                                <div className='col-12'>
                                  <p style={{ marginBottom: '-15px', marginTop: '-15px' }}>Not yet rated</p>
                                </div> :
                                <div className='col-6'>
                                  {
                                    (votee && votee.position === '') || (votee && votee.position === undefined) ?
                                      <p style={{ paddingTop: '55px' }}>Position not provided for ranking</p> :
                                      <div>
                                        <h5 style={{ marginBottom: '-15px' }} >{votee && votee.first_name} is </h5>
                                        <h1 style={{ marginBottom: '-15px' }} > #1</h1>
                                        <h5>of all {votee && votee.position}'s</h5>
                                      </div>
                                  }
                                </div>
                            }

                            <div className='col-6'>
                              {
                                votee && votee.vote_list.length === 0 ?
                                  null :
                                  <div>
                                    <h5 style={{ marginBottom: '-15px' }}>{votee && votee.first_name}'s rating</h5>
                                    <h1 style={{ marginBottom: '-3px' }}>{votee && votee.votes && votee.votes.toFixed(1)}</h1>
                                  </div>
                              }

                              {
                                votee && votee.vote_list.length === 0 ?
                                  null :
                                  votee && votee.votes === 0 ?
                                    <div>
                                      <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                      <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                      <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                      <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                      <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                    </div> :
                                    votee && votee.votes > 0 && votee && votee.votes < 1 ?
                                      <div>
                                        <HalfStar style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                        <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                        <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                        <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                        <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                      </div> :
                                      votee && votee.votes === 1 ?
                                        <div>
                                          <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                          <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                          <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                          <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                          <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                        </div> :
                                        votee && votee.votes > 1 && votee && votee.votes < 2 ?
                                          <div>
                                            <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                            <HalfStar style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                            <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                            <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                            <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                          </div> :
                                          votee && votee.votes === 2 ?
                                            <div>
                                              <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                              <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                              <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                              <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                              <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                            </div> :
                                            votee && votee.votes > 2 && votee && votee.votes < 3 ?
                                              <div>
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                <HalfStar style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                              </div> :
                                              votee && votee.votes === 3 ?
                                                <div>
                                                  <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                  <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                  <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                  <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                  <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                </div> :
                                                votee && votee.votes > 3.0 && votee && votee.votes < 4 ?
                                                  <div>
                                                    <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                    <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                    <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                    <HalfStar style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                    <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                  </div> :
                                                  votee && votee.votes === 4 ?
                                                    <div>
                                                      <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                      <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                      <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                      <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                      <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                    </div> :
                                                    votee && votee.votes > 4 && votee && votee.votes < 5 ?
                                                      <div>
                                                        <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                        <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                        <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                        <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                        <HalfStar style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                      </div> :
                                                      votee && votee.votes === 5 ?
                                                        <div>
                                                          <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                          <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                          <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                          <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                          <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                        </div> : null
                              }
                            </div>
                          </div>
                              : null
                          }
                        </div>

                        <br />
                        {/* Rate now button */}
                        <div className='container'>
                          <Button
                            onClick={this.handleOpen}
                            label="Rate now"
                            secondary={true}
                            type='submit'

                          />

                          <Button
                            onClick={() => {this.navigate('/messages/' + votee.id)}}
                            label="MESSAGE"
                            style={{marginLeft:'50px'}}
                            secondary={true}
                            type='submit'

                          />


                          
                          <div>
                          </div>
                        </div>
                        <br />
                      </Card>
                    </div>


                    {/* Graphs */}
                    <div className='col-12'>
                      {
                        votee && votee.show_profile?
                        votee && votee.vote_list && votee.vote_list.length === 0 ?
                          null :
                          <Card style={{ backgroundColor: '#FAFAFA' }}>
                            <div className='row'>
                              <div className='col-12'>
                                <div className='row'>
                                  <div className='col-6 center' style={{ marginBottom: '-30px', marginLeft: '-40px' }}>
                                    <h4 style={{ color: '#343434', marginBottom: '-15px' }}>Review summary</h4>
                                    <ComposedChart layout="vertical" width={300} height={240} data={data}
                                      margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                                      <CartesianGrid stroke='#f5f5f5' />
                                      <XAxis display='none' type="number" />
                                      <YAxis dataKey="name" type="category" />
                                      <Tooltip />
                                      <Bar dataKey='votes' barSize={20} fill='#2E95C6' />
                                    </ComposedChart>
                                  </div>
                                  <div className='col-6 center'>
                                    <div style={{ color: '#343434' }}>
                                      <h2 style={{ marginBottom: '-5px' }}>{votee && votee.votes && votee.votes.toFixed(1)}</h2>

                                      <div style={{ marginBottom: '-20px' }}>
                                        {
                                          votee && votee.votes === 0 ?
                                            <div>
                                              <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                              <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                              <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                              <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                              <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                            </div> :
                                            votee && votee.votes > 0 && votee && votee.votes < 1 ?
                                              <div>
                                                <HalfStar style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                              </div> :
                                              votee && votee.votes === 1 ?
                                                <div>
                                                  <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                  <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                  <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                  <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                  <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                </div> :
                                                votee && votee.votes > 1 && votee && votee.votes < 2 ?
                                                  <div>
                                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                    <HalfStar style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                  </div> :
                                                  votee && votee.votes === 2 ?
                                                    <div>
                                                      <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                      <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                      <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                      <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                      <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                    </div> :
                                                    votee && votee.votes > 2 && votee && votee.votes < 3 ?
                                                      <div>
                                                        <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                        <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                        <HalfStar style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                        <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                        <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                      </div> :
                                                      votee && votee.votes === 3 ?
                                                        <div>
                                                          <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                          <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                          <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                          <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                          <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                        </div> :
                                                        votee && votee.votes > 3 && votee && votee.votes < 4 ?
                                                          <div>
                                                            <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                            <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                            <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                            <HalfStar style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                            <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                          </div> :
                                                          votee && votee.votes === 4 ?
                                                            <div>
                                                              <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                              <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                              <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                              <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                              <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                            </div> :
                                                            votee && votee.votes > 4 && votee && votee.votes < 5 ?
                                                              <div>
                                                                <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                <HalfStar style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                              </div> :
                                                              votee && votee.votes === 5 ?
                                                                <div>
                                                                  <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                  <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                  <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                  <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                  <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                </div> : null
                                        }
                                      </div>

                                      <br />

                                      {
                                        votee && votee.vote_list.length === 1 ?
                                          <small>{votee && votee.vote_list.length + ' '}review </small> :
                                          <small>{votee && votee.vote_list.length + ' '}reviews </small>
                                      }
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </Card>

                          :null
                      }
                    </div>

                  </div>
                </div>


                {/* Reviews */}
                <div>
                  <div style={{ marginBottom: '30px' }} className='center'>
                    <h3>Reviews</h3>
                    {

                      votee && votee.show_profile?

                      votee && votee.vote_list.length === 0 ?
                        <div>
                          <Card style={{ backgroundColor: white }}>
                            <div className='row'>
                              <div className='col-8-sm'>
                                <CardContent style={{ color: black }}>
                                  <h4>No reviews have been made yet</h4>
                                </CardContent>
                              </div>
                            </div>
                          </Card>
                        </div> :

                        votee && this.getVotes().map((vote, i) => (
                          <div key={i}>
                            <Card
                              style={{ backgroundColor: white }}
                            >
                              <div className='row'>
                                <div className='col-4-sm'>
                                  <PicNWordsSmall  push={this.props.history.push} user_id={vote.created_by} />
                                </div>
                                <div className='col-8-sm left' style={{ position: 'relative' }}>
                                  <CardContent style={{ color: black }}>
                                    <span
                                      style={{ color: black }}
                                      onClick={() => {
                                        if (user_id === vote.created_by) {
                                          this.props.history.push('/mydashboard')
                                        } else {
                                          this.props.history.push('/user/' + vote.created_by)
                                          window.location.reload();
                                        }
                                      }}
                                    >
                                      {vote.created_by_name}
                                      <br />
                                      <i>{vote.relationship}</i>
                                    </span>
                                    <br />
                                    <span style={{ color: black }}><Moment fromNow>{vote.created}</Moment></span>
                                    <br />
                                    {vote.review}
                                    <br />
                                    <p style={{
                                          marginTop: '-5px',
                                          fontSize: '20px',
                                          display: 'inline-block'
                                      }}>
                                          {vote.score}
                                      </p>
                                      <div style={{
                                          display: 'inline-block',
                                          marginBottom: '-10px',
                                          paddingLeft: '5px'
                                      }}>
                                          {
                                              vote.score === 0 ? 
                                              <div>
                                                  <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                                  <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                                  <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                                  <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                                  <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                              </div> :
                                              vote.score === 1 ? 
                                              <div>
                                                  <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                                  <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                                  <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                                  <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                                  <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                              </div> :
                                              vote.score === 2 ? 
                                              <div>
                                                  <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                                  <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                                  <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                                  <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                                  <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                              </div> :
                                              vote.score === 3 ? 
                                              <div>
                                                  <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                                  <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                                  <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                                  <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                                  <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                              </div> :
                                              vote.score === 4 ? 
                                              <div>
                                                  <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                                  <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                                  <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                                  <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                                  <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                              </div> :
                                              vote.score === 5 ? 
                                              <div>
                                                  <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                                  <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                                  <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                                  <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                                  <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                              </div> : null
                                          }
                                      </div>
                                  </CardContent>
                                  {
                                    user.id === vote.created_by ?
                                      <div>
                                        <div>
                                          {/* Edit review button */}
                                          <Button style={{ position: 'absolute', right: '0', bottom: '10px' }}
                                            label="Edit" primary={true} onClick={() => {
                                              // console.log("VOTE!!!", vote);
                                              this.setState({
                                                editReviewData: vote,
                                                edit_review: vote.review,
                                                vote_id: vote._id
                                              })
                                            }}
                                          />
                                        </div>
                                      </div>
                                      :
                                      null
                                  }

                                </div>
                              </div>
                            </Card>
                          </div>
                        ))
                        
                        :
                        <div>
                          <Card style={{ backgroundColor: white }}>
                            <div className='row'>
                              <div className='col-8-sm'>
                                <CardContent style={{ color: black }}>
                                  <h4>You can't view {votee ? votee.first_name+"'s ": "this user's "} Reviews.</h4>
                                </CardContent>
                              </div>
                            </div>
                          </Card>
                        </div>
                        
                        }
                    {
                      // More or less button
                      votee && votee.vote_list.length >= 7 ?
                        <Button
                          onClick={this.toggle}
                          label={this.state.full_list_open ? 'less reviews' : 'more reviews'}
                          secondary={true}
                          fullWidth={true}
                        >
                        </Button> : null
                    }
                  </div>
                </div>
                <Dialog
                  style={{ textAlign: 'center' }}
                  actions={editReviewActions}
                  modal={false}
                  open={this.state.editReviewData ? true : false}
                  onRequestClose={() => this.setState({ editReviewData: null })}
                >
                  <p> Edit rating for {votee && votee.first_name + ' ' + votee.last_name} </p>
                  Not clicking a star will result in a zero rating
                            <br />
                  <br />
                  {
                    this.state.editReviewData &&
                    <StarComponent rating={this.state.editReviewData.score} onRatingChange={this.editRatingChange} />
                  }

                  <TextField
                    name='edit_review'
                    value={this.state.edit_review}
                    onChange={this.editReviewHandleChange}
                    multiLine={true}
                    rows={2}
                    maxRows={4}
                  />
                  <br />
                  {/* {
                                this.state.err ?
                                <span style={{ color: 'red'}}>{this.state.err}<br /></span> :
                                null
                            } */}
                </Dialog>
                <div>
                  {
                    this.state.loading_edit_submit ?
                      <Dialog
                        modal={true}
                        open={this.state.loading_edit_submit}
                      >
                        <p>Updating your review</p>
                      </Dialog> :
                      <Dialog
                        style={{ textAlign: 'center' }}
                        title={"Your review has been changed. Thank You!"}
                        actions={editReview_actions}
                        modal={true}
                        open={this.state.edit_review_thanks}
                      >
                      </Dialog>
                  }
                </div>
                {
                  this.state.loading_submit ?
                    <Dialog
                      modal={true}
                      open={this.state.loading_submit}
                    >
                      <p>Submitting....</p>
                    </Dialog> :
                    <Dialog
                      style={{ textAlign: 'center' }}
                      title={
                        this.state.err ?
                          this.state.err :
                          "Thank you for rating"
                      }
                      actions={upstar_actions}
                      modal={true}
                      open={this.state.open_thanks || this.state.err ? true : false}
                    >
                    </Dialog>
                }
              </div>
        }
      </div>
    );
  }
}