import React, { Component } from 'react';
// import UploadImage from './uploadimage';
import GridLandingPage from './gridlandingpage';


export default class extends Component {

    constructor(props){
        super(props)
        var x = localStorage.getItem("user");
        this.props.socket.emit("user-click-event" , {event:"quick-land page", user:x} );
    }

    render() {
        // const user = JSON.parse(localStorage.getItem('user'));
        return (
            <div>
                {/* {
                    user && user.profile ?
                    <GridLandingPage push={this.props.history.push}/> :
                    <UploadImage {...this.props}/>
                } */}
                <GridLandingPage push={this.props.history.push}/> 
            </div>
        )
    }
}