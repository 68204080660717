import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress'

import Star from '@mui/icons-material/Star';
import StarBorder from '@mui/icons-material/StarBorder';
import Moment from 'react-moment';
import { getAllVotes } from '../../utils';
import PicNWordsSmall from '../../components/pic-n-words-small';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export default class CheckboxListSecondary extends Component{
 
    constructor(props){
        super(props);
        this.state = {
            user: null,
            votes_list : [],
            full_list_open : false,
            loading: false,
            avg_todays_vote: 0,
            avg_lastweek_vote: 0,
            avg_twoweeks_vote: 0,
            avg_threeweeks_vote: 0,
            profile_open: false,
            profile_loading_update:false,
            profile_message:null,
            sprompt:false,
        }
    }


    componentDidMount() {
        this.setState({ loading: true })
            getAllVotes()
            .then(results => {
                // console.log('RESULTS MY DASHBOARD', results)
                this.setState({
                    loading: false,
                    votes_list : results.items,
                })
            });
    }


    getVotes() {
        let vote_list = this.state.votes_list;
        let sort_list = vote_list.sort((a,b) => Date.parse(a.created) - Date.parse(b.created));
        let list = sort_list.reverse();

        if (this.state.full_list_open) {
            return list;
        }
        return list.slice(0, 6)
    }


 
    render (){
    

    
    return(


        <div style={{display:'inline-block' , 'width':'400px'}}>
    
        <Typography variant="h4" component="h4" gutterBottom style={{textAlign:'center'}}>
                Recent Reviews
        </Typography>

        <Paper style={{overflowY:'scroll', height:'500px' }}>

{
    this.state.loading ?
    
    <div className='center'><CircularProgress size={80} thickness={5} /></div> :

    this.state.votes_list.length === 0 ?

    <div>
        <Card style={{ backgroundColor: white}}>
            <div className='row'>
                <div className='col-8-sm'>
                    <CardContent style={{ color: black}}>
                        <h4>No reviews have been made yet</h4>
                    </CardContent>
                </div>
            </div>
        </Card>
    </div> :

    this.getVotes().map((vote, i) => (
    <div key={i}>
        <Card  
            style={{ backgroundColor: white }}
        >
        <div className='row'>
                <div className='col-4-sm'>
                    <PicNWordsSmall push={null} user_id={vote.created_by}/>
                    
                </div>
                <div className='col-8-sm left'>
                    <CardContent style={{ color: black}}>
                        <span 
                            style={{ color: black }}
                            onClick={() => {
                                this.props.history.push('/user/' + vote.created_by)
                            }}
                        >
                            { vote.created_by_name }
                        </span>
                        <br/>
                        <span style={{ color: black }}><Moment fromNow>{vote.created}</Moment></span>
                        <br />
                        {vote.review}
                        <br /><br/>
                        {/* <Star style={{color: '#2e95c6', marginTop: '3px'}}/> */}
                        <p style={{
                            marginTop: '-5px',
                            fontSize: '20px',
                            display: 'inline-block'
                        }}>
                            {vote.score}
                        </p>
                        <div style={{
                            display: 'inline-block',
                            marginBottom: '-10px',
                            paddingLeft: '5px'
                        }}>
                            {
                                vote.score === 0 ? 
                                <div>
                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                </div> :
                                vote.score === 1 ? 
                                <div>
                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                </div> :
                                vote.score === 2 ? 
                                <div>
                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                </div> :
                                vote.score === 3 ? 
                                <div>
                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                </div> :
                                vote.score === 4 ? 
                                <div>
                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                </div> :
                                vote.score === 5 ? 
                                <div>
                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto'}} />
                                </div> : null
                            }
                        </div>
                    </CardContent>
                </div>
            </div>
        </Card>
    </div> 
))

}

        </Paper>



</div>
    );
}
}