import React, { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';

export default class AboutUs extends Component {

    constructor(props) {
        super(props);
        this.state = {open: false}

        var x = localStorage.getItem("user");
        this.props.socket.emit("user-click-event" , {event:"manifesto page", user:x } );

    }

    handleOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };    

    navigate = (path) => {
        this.props.history.push(path)
    }

    render() {
        var urlParams = new URLSearchParams(this.props.location.search)

        const next = urlParams.get('next');



        const actions = [
            <a href='https://www.dalailama.com/videos/dealing-with-anger' target="_blank" rel='noopener noreferrer'>
                <Button
                    label="Still Declining"
                    primary={true}
                />
            </a>,
            <Button
              label="Back to Manifesto"
              primary={true}
              onClick={this.handleClose}
            />,
          ];
        return (
            <div className='center'>
                <img style={{
                    height: 'auto',
                    width: '300px'
                }} src='/img/logo.png' alt='upstar_logo' />
                <div className='container'>
                    <h1>Manifesto</h1>
                    <p>
                    In order to keep UpSTAR positive and help spread excellence around the globe your positive reviews are always welcome. Please keep negative (0 star) reviews to the absolute minimum, free from emotion and stating only the facts. Happy reviewing!
                    </p>
                    <p> 
                        I undertake to be honest, while treating everyone in the UpStar community fairly and respectfully.
                    </p>
                    <Button
                        label="DECLINE" 
                        primary={true} 
                        type='button' 
                        onClick={this.handleOpen}  
                    />
                    <Dialog
                        title="Not ready yet?"
                        actions={actions}
                        modal={false}
                        open={this.state.open}
                        onRequestClose={this.handleClose}
                        >
                        We look forward to welcome you to the UpStar community when you are ready.
                    </Dialog>
                    <Button
                        style={{ paddingLeft: 15}}
                        label="ACCEPT" 
                        primary={true} 
                        type='button' 
                        onClick={() => {
                            if (next) {
                                this.navigate('/login?next=' + next)
                            } else {
                                this.navigate('/login')
                            }
                        }} 
                    />
                </div>
            </div>
        )
    }
}