import React from 'react';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import ClearIcon from '@mui/icons-material/Clear';
import Tooltip from '@mui/material/Tooltip';

import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

import jobs from '../../data/jobs';

const industries = Object.keys(jobs);


const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    backgroundColor:'rgb(46, 149, 198)',
    width:"100%",
    marginTop:"30px",
  },

  formControl: {
    margin: theme.spacing(3),
    maxWidth: 180,
    minWidth:150,
  },


//for search input

search: {
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: '10px',
  width: '300px',
  display:'inline-block',
  marginTop:'24px'
},
searchIcon: {
  width: theme.spacing(7),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
},
inputRoot: {
  color: 'inherit',
},
inputInput: {
  padding: theme.spacing(1, 1, 1, 7),
  transition: theme.transitions.create('width'),
  width: '200px',
  fontSize:'30px',
},
'search-components':{

  display:'inline-block',

},


}));

export default function FloatingActionButtons(props) {
  const classes = useStyles();

  const inputLabel = React.useRef(null);

  const [open, setOpen] = React.useState(false);

  
  const handleChange= (e) => {
    props.setInd(e.target.value);
  };


  const handleChangeJob= (e) => {
    props.setJob(e.target.value);
  };

  const handleChangeOther= (e) => {
    props.setOF(e.target.value);
  };

  const handleChangeSearch = (e) =>{
    props.setSearch(e.target.value);
  }

  const clearAll = (e)=>{
    props.setOF("Name");
    props.setJob("");
    props.setInd("");
    props.setSearch("");
  };
  
  return (
    <div className={classes.root}>
      
      <div className={"search-components"}>
      <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              value={props.search}
              onChange={handleChangeSearch}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>


          <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
          Work Field
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
/*
          value={age}
          
          labelWidth={labelWidth}
          */
         value={props.industry}
         onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
            </MenuItem>

            
{            industries.sort().map((i, index) => (
            <MenuItem key={index} value={i} primaryText={i}>{i}</MenuItem>
                                ))
                                }

            

        </Select>
      </FormControl>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
          Work Position
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
/*
          value={age}
          onChange={handleChange}
          labelWidth={labelWidth}
          */
         value={props.job}
          onChange={handleChangeJob}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {    props.industry && jobs[props.industry] && jobs[props.industry].length > 0 && jobs[props.industry].sort().map((i, index) => (
            <MenuItem key={index} value={i} primaryText={i}>{i}</MenuItem>
                                ))
         }


        </Select>
      </FormControl>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
          Other Features
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
/*
          value={age}
          onChange={handleChange}
          labelWidth={labelWidth}
          */
         value={props.otherFeature}
         onChange={handleChangeOther}
        >
          <MenuItem value={"Name"}>Name</MenuItem>
          <MenuItem value={"Email"}>Email</MenuItem>
          <MenuItem value={"Company"}>Place Of Work</MenuItem>
          <MenuItem value={"Location"}>Residence</MenuItem>
          <MenuItem value={"5 Words"}>Words that describe User</MenuItem>
        </Select>
      </FormControl>
      <Tooltip disableFocusListener disableTouchListener title="Clear Filter" placement="right">

      <IconButton
        color="primary"
        aria-label="add"
        style={{backgroundColor:"yellow"}}
        onClick={clearAll}
        size="large">
        <ClearIcon />
      </IconButton>
      </Tooltip>

      </div>

      <div className={"quick-tools"} style={{paddingBottom:"10px"}}>
      
      </div>


      
    </div>
  );
}