import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import StarComponent from '../components/star_component';
import Button from '@mui/material/Button';
import { Dialog } from '@mui/material';
import {upstar_votes, sendMail} from '../utils';

export default class ContactUs extends Component {

    constructor(props) {
        super(props);
        const user = JSON.parse(localStorage.getItem('user'));
        this.state = {
            username : user.first_name,
            surname : user.last_name,
            email : user.email,
            message : '',
            review : '',
            rating: 0,
            open : false,
            openContact : false,
            err : null,
        }

        var x = localStorage.getItem("user");
        
        this.props.socket.emit("user-click-event" , {event:"contact-us page", user:x} );

    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onRatingChange = (nextValue, prevValue, name) => {
        this.setState({ 
            rating : nextValue 
        });
    }

    refresh = () => {
        window.location.reload();
    }

    navigate = (path) => {
        this.props.history.push(path)
    }

    onClick = () => {
        let { message } = this.state;
        if (message.length > 0) {
            return true
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        // console.log('This is logging' ,this.state.review);
        // console.log('rating', this.state.rating)
        this.setState({open: true});
        const user = JSON.parse(localStorage.getItem('user'));
        var data = {
            'created_by' : user.id,
            'created_by_name' : user.first_name + ' ' + user.last_name,
            'profile' : user.profile,
            'score' : this.state.rating,
            'review' : this.state.review 
        }
        upstar_votes(data)
        .then(results => {
            console.log('Upstar vote results', results)

        })
        
    }

    render() {

        const actions = [
            <Button
                label="Cool"
                primary={true}
                keyboardFocused={true}
                onClick={ () => { this.navigate('/') }}
            />,
          ];

        const actionContact = [
            <Button 
                label='All done'
                primary={true}
                onClick={ () => { this.navigate('/')} }
            />
        ]

        return (
            <div className='container center'> 
            {/* The form to contact upstar */}
                <div>
                    {/* <img className='container' src='/img/logo.png' alt='' /> */}
                </div>
                <h3>Thanks for helping us improve UpSTAR, tell us how to make UpSTAR better!</h3>
                <div className='row'>
                    {/* Contact upstar */}
                    <div style={{
                        minHeight: '380px',
                        position: 'relative'
                    }} className='col-6'>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            this.setState({ loading: true });

                            sendMail(this.state)
                            .then(results => {
                                console.log('Send mail results', results);
                                if(results.err && results.err.length > 0) {
                                    this.setState({ openContact : false, err: results.err })
                                } else {
                                    this.setState({ loading: false, openContact: true })
                                }  
                            })
                        }} method="POST" encType="multipart/form-data">
                            <h4>Have a question? Contact us here</h4>
                            <input type="hidden" name="_after" value="https://upstar.co.za/success"/>
                            <input type="hidden" name="_honeypot" value=""/>
                            <input type="hidden" name="_confirmation" value=""/>
                            <div>
                                <TextField
                                    name= 'username'
                                    placeholder= 'Your name'
                                    value = {this.state.username}
                                    onChange= {this.handleChange}
                                    type='text'
                                />
                                <br />
                                <TextField 
                                    name='surname'
                                    placeholder='Your surname'
                                    value = {this.state.surname}
                                    onChange={this.handleChange}
                                    type='text'
                                />
                                <br />
                                <TextField
                                    name='email'
                                    placeholder='Email address'
                                    value = {this.state.email}
                                    onChange={this.handleChange}
                                    type='text'
                                />
                                <br />
                                <TextField
                                    name='message'
                                    placeholder='Message'
                                    value = {this.state.message}
                                    onChange={this.handleChange}
                                    multiLine={true}
                                    rows={2}
                                    maxRows={4}
                                />
                                <br />
                                {
                                    this.state.err ?
                                    <span style={{ color: 'red' }}>{this.state.err}<br/></span> :
                                    null
                                }
                                <div className='container'>
                                    <Dialog
                                        className='center'
                                        title="Thank you for reaching out"
                                        actions={actionContact}
                                        modal={false}
                                        open={this.state.openContact}
                                        >
                                        We will get back to you soonest!
                                        Best, the UpSTAR team.
                                    </Dialog>
                                    <br />
                                    <div style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        right: 0, left: 0,
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}>
                                        <Button
                                            style={{
                                                display: 'block'
                                            }}
                                            label="Submit" 
                                            primary={true} 
                                            type='submit'
                                            onClick= { this.onClick}
                                        />
                                    </div>
                                    
                                    <br />
                                    <br />
                                </div>
                            </div>
                        </form>
                    </div>

                    {/* The Rating UpStar part */}
                    <div style={{
                        minHeight: '380px',
                        position: 'relative'
                    }} className='col-6'>
                        <form >
                            <h4>Rate Us!</h4>
                            <p>Not clicking a star will result in a zero rating</p>
                            <br />
                            <StarComponent rating={this.state.rating} onRatingChange={this.onRatingChange} />
                            <div>
                                <br />
                                <TextField
                                    name='review'
                                    placeholder='Review'
                                    value = {this.state.review}
                                    onChange={this.handleChange}
                                    multiLine={true}
                                    rows={2}
                                    maxRows={4}
                                />
                            </div>
                            <Dialog
                                className='center'
                                title="Thank you"
                                actions={actions}
                                modal={false}
                                open={this.state.open}
                                >
                                We value your input and will use it to improve your UpSTAR experience.
                            </Dialog>
                            <br />
                            
                            {/* <br /><br /> */}
                            <div style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0, left: 0,
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <Button
                                style={{
                                    display: 'block'
                                }}
                                label="Send" 
                                primary={true} 
                                type='submit'
                                onClick={(e => this.onSubmit(e))}
                            />
                            </div>
                        </form>
                    </div>

                    
                </div>
            </div>
        );
    }
}