import React, { Component } from 'react';

export default class PleaseVerifyEmail extends Component {
    render () {
        return (
            <div className='center'>
                <img alt='' style={{
                    width: '100%',
                    maxWidth: '300px'
                }} src='/img/logo.png'/>
                <p style={{ fontSize: '24px' }}>
                    <b>You've got mail!</b><br />
                    Check your inbox! Your UpSTAR verification email was email sent to the email provided.
                </p>
            </div>
        );
    }
}