import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Updated import
import { register, unclaimedUserlist } from '../utils';
import { Step, Stepper, StepLabel, Button, TextField, CircularProgress, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import jobs from '../data/jobs';
import Terms from './terms';

const industries = Object.keys(jobs);

const Signup = () => {
    const [state, setState] = useState({
        checkedA: false,
        businessWebsite: '',
        businessContactNumber: '',
        first_name: '',
        surname: '',
        email: '',
        password: '',
        mobile: '',
        confirmpassword: '',
        position: '',
        other_position: '',
        company: '',
        industry: '',
        other_industry: '',
        suburb: '',
        province: '',
        place: null,
        word_one: '',
        word_two: '',
        word_three: '',
        word_four: '',
        word_five: '',
        stepIndex: 0,
        indus: [],
        loading: false,
        checked: true,
        show_password: false,
        loading_users: false,
        users: [],
        err: '',
        show_terms: false,
        unclaimed_id: null,
    });

    const navigate = useNavigate(); // Updated

    useEffect(() => {
        setState(prevState => ({ ...prevState, loading_users: true }));
        unclaimedUserlist()
            .then(res => {
                console.log("USER RESULTS", res);
                setState(prevState => ({ ...prevState, loading_users: false, users: res }));
            });
    }, []);

    const handleChange = (e) => {
        setState(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    };

    const handleChange1 = (name) => (event) => {
        setState(prevState => ({ ...prevState, [name]: event.target.checked }));
    };

    const handleNext = () => {
        setState(prevState => ({ ...prevState, stepIndex: prevState.stepIndex + 1 }));
    };

    const handlePrev = () => {
        setState(prevState => ({ ...prevState, stepIndex: prevState.stepIndex > 0 ? prevState.stepIndex - 1 : 0 }));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const data = {
            first_name: state.first_name,
            last_name: state.surname,
            email: state.email,
            password1: state.password,
            password2: state.confirmpassword,
            mobile: state.mobile,
            business_website: state.businessWebsite,
            business_contact_number: state.businessContactNumber,
            position: state.position === 'other' ? state.other_position : state.position,
            company: state.company,
            suburb: state.suburb,
            province: state.province,
            industry: state.industry === 'other' ? state.other_industry : state.industry,
            word_one: state.word_one,
            word_two: state.word_two,
            word_three: state.word_three,
            word_four: state.word_four,
            word_five: state.word_five,
            terms_and_conditions: state.checked,
        };

        if (state.unclaimed_id) {
            data.unclaimed_id = state.unclaimed_id;
        }
        setState(prevState => ({ ...prevState, loading: true }));
        console.log('THIS IS THE DATA', data);
        register(data)
            .then(results => {
                if (results.status === 400) {
                    setState(prevState => ({ ...prevState, err: results.message, loading: false, stepIndex: 0 }));
                } else if (results.err) {
                    setState(prevState => ({ ...prevState, err: results.err, stepIndex: 0, loading: false }));
                } else if (results.result === 'Registered') {
                    navigate('/please_verify_email'); // Updated
                }
            })
            .catch(err => { console.log(err); });
    };

    const onQuickSubmit = (e) => {
        e.preventDefault();
        const data = {
            first_name: state.first_name,
            last_name: state.surname,
            email: state.email,
            password1: state.password,
            password2: state.confirmpassword,
        };

        if (state.unclaimed_id) {
            data.unclaimed_id = state.unclaimed_id;
        }

        if (state.company) {
            data.company = state.company;
        }

        if (state.checked) {
            setState(prevState => ({ ...prevState, loading: true }));
            register(data)
                .then(results => {
                    console.log('REGISTER RESULTS', results);
                    if (results.status === 400) {
                        setState(prevState => ({ ...prevState, err: results.message, loading: false, stepIndex: 0 }));
                    } else if (results.err) {
                        setState(prevState => ({ ...prevState, err: results.err, stepIndex: 0, loading: false }));
                    } else {
                        localStorage.setItem('user', JSON.stringify(results.user));
                        navigate('/please_verify_email?next=quick_rate'); // Updated
                    }
                });
        } else {
            alert('Please accept terms and conditions before signing up');
        }
    };

    const getStepContent = (stepIndex) => {
        const x = /^\d+$/;

        switch (stepIndex) {
            case 0:
                return (
                    <form>
                        <h4>Please tell us who you are</h4>
                        <TextField
                            name='first_name'
                            autoComplete="off"
                            placeholder='Name'
                            type='text'
                            value={state.first_name}
                            onChange={handleChange}
                            fullWidth
                        />
                        <br />
                        <TextField
                            name='surname'
                            placeholder='Surname'
                            type='text'
                            value={state.surname}
                            onChange={handleChange}
                            fullWidth
                        />
                        <br />
                        <TextField
                            name='email'
                            placeholder='Email'
                            type='text'
                            value={state.email}
                            onChange={handleChange}
                            fullWidth
                        />
                        <br />
                        <TextField
                            name='password'
                            placeholder='Password'
                            type={state.show_password ? 'text' : 'password'}
                            value={state.password}
                            onChange={handleChange}
                            fullWidth
                        />
                        <span
                            className={state.show_password ? 'fa fa-eye-slash' : 'fa fa-eye'}
                            style={{ cursor: 'pointer', userSelect: 'none' }}
                            onMouseDown={() => setState(prevState => ({ ...prevState, show_password: !prevState.show_password }))}
                            onMouseUp={() => setState(prevState => ({ ...prevState, show_password: !prevState.show_password }))}
                        />
                        <br />
                        <i className='password_length' style={state.password.length >= 8 ?
                            { fontSize: 10, color: 'green' } : { fontSize: 10, color: '#a3a3a3' }}>
                            At least 8 characters accepted.
                        </i>
                        <br />
                        <TextField
                            name='confirmpassword'
                            placeholder='Confirm password'
                            type={state.show_password ? 'text' : 'password'}
                            value={state.confirmpassword}
                            onChange={handleChange}
                            fullWidth
                        />
                        <br />
                        {state.err && <span style={{ color: 'red' }}>{state.err}<br /></span>}
                    </form>
                );
            case 1:
                return (
                    <form>
                        <h4>Please tell us what you do</h4>
                        <TextField
                            name='company'
                            placeholder='Company'
                            type='text'
                            value={state.company}
                            onChange={handleChange}
                            fullWidth
                        />
                        <br />
                        <FormControl fullWidth>
                            <InputLabel>Industry</InputLabel>
                            <Select
                                name="industry"
                                value={state.industry}
                                onChange={(e) => setState(prevState => ({ ...prevState, industry: e.target.value }))}
                            >
                                {industries.sort().map((i, index) => (
                                    <MenuItem key={index} value={i}>{i}</MenuItem>
                                ))}
                                <MenuItem value='other'>Other</MenuItem>
                            </Select>
                        </FormControl>
                        <br />
                        {state.industry === 'other' && (
                            <TextField
                                name='other_industry'
                                placeholder='Other Industry'
                                type='text'
                                value={state.other_industry}
                                onChange={handleChange}
                                fullWidth
                            />
                        )}
                        <br />
                        {state.industry && (
                            <FormControl fullWidth>
                                <InputLabel>Position</InputLabel>
                                <Select
                                    name='position'
                                    value={state.position}
                                    onChange={(e) => setState(prevState => ({ ...prevState, position: e.target.value }))}
                                >
                                    <MenuItem value='other'>Other</MenuItem>
                                    <MenuItem value='CEO'>CEO</MenuItem>
                                    <MenuItem value='Manager'>Manager</MenuItem>
                                    <MenuItem value='Developer'>Developer</MenuItem>
                                    <MenuItem value='Designer'>Designer</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                        <br />
                        {state.position === 'other' && (
                            <TextField
                                name='other_position'
                                placeholder='Other Position'
                                type='text'
                                value={state.other_position}
                                onChange={handleChange}
                                fullWidth
                            />
                        )}
                        <br />
                        <TextField
                            name='suburb'
                            placeholder='Suburb'
                            type='text'
                            value={state.suburb}
                            onChange={handleChange}
                            fullWidth
                        />
                        <br />
                        <TextField
                            name='province'
                            placeholder='Province'
                            type='text'
                            value={state.province}
                            onChange={handleChange}
                            fullWidth
                        />
                    </form>
                );
            case 2:
                return (
                    <form>
                        <h4>Optional information</h4>
                        <small style={{ color: 'grey' }}>For recruitment & promotional purposes</small>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <h4>Business</h4>
                                <TextField
                                    name='businessWebsite'
                                    placeholder='Business website'
                                    type='text'
                                    value={state.businessWebsite}
                                    onChange={handleChange}
                                    fullWidth
                                />
                                <br />
                                <TextField
                                    name='businessContactNumber'
                                    placeholder='Business contact number'
                                    type='text'
                                    value={state.businessContactNumber}
                                    onChange={handleChange}
                                    fullWidth
                                />
                                <br />
                                <i className='password_length' style={(state.businessContactNumber.length >= 9) && (state.businessContactNumber[0] === "+") &&
                                    (x.test(state.businessContactNumber.substr(1))) ?
                                    { fontSize: 10, color: 'green' } : { fontSize: 10, color: '#a3a3a3' }}>
                                    Enter a valid phone number(with country). ie. +27637453728.
                                </i>
                                <br />
                            </div>
                            <div>
                                <h4>Individual</h4>
                                <TextField
                                    name='mobile'
                                    placeholder='Mobile'
                                    type='text'
                                    value={state.mobile}
                                    onChange={handleChange}
                                    fullWidth
                                />
                                <br />
                                <i className='password_length' style={(state.mobile.length >= 9) && (state.mobile[0] === "+") &&
                                    (x.test(state.mobile.substr(1))) ?
                                    { fontSize: 10, color: 'green' } : { fontSize: 10, color: '#a3a3a3' }}>
                                    Enter a valid phone number(with country). ie. +27637453728.
                                </i>
                                <br />
                                <small style={{ color: 'grey' }}>
                                    Add your phone number for us to share your details with potential recruiters
                                </small>
                                <br />
                            </div>
                        </div>
                    </form>
                );
            case 3:
                return (
                    <form onSubmit={onSubmit}>
                        <h4>Please choose five words that describe your character</h4>
                        <TextField
                            name='word_one'
                            label="First word"
                            value={state.word_one}
                            onChange={handleChange}
                            fullWidth
                        />
                        <br />
                        <TextField
                            name='word_two'
                            label="Second word"
                            value={state.word_two}
                            onChange={handleChange}
                            fullWidth
                        />
                        <br />
                        <TextField
                            name='word_three'
                            label="Third word"
                            value={state.word_three}
                            onChange={handleChange}
                            fullWidth
                        />
                        <br />
                        <TextField
                            name='word_four'
                            label="Fourth word"
                            value={state.word_four}
                            onChange={handleChange}
                            fullWidth
                        />
                        <br />
                        <TextField
                            name='word_five'
                            label="Fifth word"
                            value={state.word_five}
                            onChange={handleChange}
                            fullWidth
                        />
                    </form>
                );
            default:
                return 'Oops something went wrong here';
        }
    };

    return (
        <div style={{ width: '100%', maxWidth: 700, margin: 'auto' }}>
            <div>
                <h2 style={{ textAlign: 'center' }}>Create My Profile</h2>
                {state.show_terms ?
                    <Terms close={() => setState(prevState => ({ ...prevState, show_terms: false }))} /> :
                    <div>
                        {state.loading_users ?
                            <p>Loading...</p> :
                            <div>
                                <Stepper activeStep={state.stepIndex}>
                                    <Step><StepLabel>Step 1</StepLabel></Step>
                                    <Step><StepLabel>Step 2</StepLabel></Step>
                                    <Step><StepLabel>Step 3</StepLabel></Step>
                                    <Step><StepLabel>Step 4</StepLabel></Step>
                                </Stepper>
                                <div style={{ margin: '0 16px' }}>
                                    {getStepContent(state.stepIndex)}
                                    <div style={{ marginTop: 12 }}>
                                        {state.stepIndex === 0 &&
                                            <p style={{ fontSize: '15px' }}>
                                                <input
                                                    checked={state.checkedA}
                                                    onChange={() => setState(prevState => ({ ...prevState, checkedA: !prevState.checkedA }))}
                                                    type="checkbox"
                                                />
                                                I agree to the
                                                <a
                                                    style={{ color: '#2E95C6' }}
                                                    onClick={() => setState(prevState => ({ ...prevState, show_terms: true }))}
                                                >
                                                    terms and conditions
                                                </a>
                                            </p>
                                        }
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handlePrev}
                                            disabled={state.stepIndex === 0}
                                        >
                                            Back
                                        </Button>
                                        {state.stepIndex === 3 ?
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                onClick={onSubmit}
                                            >
                                                Submit
                                            </Button> :
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                            >
                                                {state.stepIndex !== 0 ? 'Next' : 'Create Profile'}
                                            </Button>
                                        }
                                        {state.stepIndex === 0 &&
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={onQuickSubmit}
                                            >
                                                Quick Sign Up
                                            </Button>
                                        }
                                        {state.loading &&
                                            <CircularProgress />
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    );
};

export default Signup;
