import React, { Component } from 'react';
import Star from '@mui/icons-material/Star';
import StarBorder from '@mui/icons-material/StarBorder';
import HalfStar from '@mui/icons-material/StarHalf';
import {Dialog, Button} from '@mui/material';
import { get_upstar_votes } from '../utils'
import './../simple-grid.css';
import './../App.css';

export default class AboutUs extends Component {
    
    constructor(props){
        super(props)
        var x = localStorage.getItem("user");
        this.props.socket.emit("user-click-event" , {event:"about-us page", user:x} );
    }
    
    state = {
        loading: false,
        votes: [],
        score: '',
        selected: null
    }
    componentDidMount() {
        this.setState({ loading: true })
        get_upstar_votes()
        .then(votes => {
            this.setState({ loading: false, votes })
        })
    }

    handleOpen = (data) => {
        this.setState({selected: data });
    };

    handleClose = () => {
        this.setState({selected: null });
    };
    

    render() {
        const flexStyle = {
            minHeight: '90vh',
            alignContent: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }

        const actions = [
            <Button
              label="Okay"
              primary={true}
              onClick={this.handleClose}
            />
          ];
        
        return (
            <div className='center'>
            <Dialog
                title={this.state.selected && this.state.selected.name}
                actions={actions}
                modal={false}
                open={this.state.selected ? true : false}
                onRequestClose={this.handleClose}
            >
                <i><h4 style={{ marginTop: '2px'}}>{this.state.selected && this.state.selected.title}</h4></i>
                {this.state.selected && this.state.selected.desc}
            </Dialog>
            
                <div className='container center'>
                    <img className='image-container' src='/img/logo.png' alt='' />
                </div>
        
                <div id="about">
                <div>
                    <h3>About UpSTAR</h3>
                    <div className='container'>
                        <p>
                            UpSTAR is your platform to recognise great work, promote good people and the companies that employ them, 
                            spread excellence across the globe, and make the world a better place.
                        </p>
                        <p>
                            UpSTAR goes beyond the CV, making it possible for you to rate the performance of anyone, anywhere, any time. 
                            And promote good people - including yourself!
                        </p>
                    </div>
                    <br/><br/><br/><br/>
                    <h3>UpSTAR history</h3>
                    <div className='container'>
                        <p>
                            Most weekends Meg and Chenél can be found in the sea at Surfer’s Corner, Muizenberg. 
                            One Saturday morning, over their usual post-surf Scram C at Empire Café, 
                            they were discussing the amazing service they always receive from their regular waitress, Doreen.
                            <br/><br/>
                            Chenél mentioned that she had been thinking about a platform on which an individual’s performance could be rated, 
                            when Meg said she had been discussing the very same thing, with her sister, the night before! 
                            <br /><br/>
                            The brainstorm continued... why not create a platform where the public can share their gratitude for people who go beyond expectation? 
                            Acknowledging people on a public platform feels good and will inspire the service deliverer to continue their outstanding work, 
                            it could even help them get promoted or create authentic testimonials that solidify their CV...
                            <br /> <br/>
                            And the rest, as they say, is history.
                        </p>
                    </div>
                </div>
                <br/><br/><br/><br/>
                </div>
                <div id="team">
                    <div>
                        <h3>Meet the team</h3>
                        <div>
                            <div className='row'>
                                <div onClick={() => {
                                    this.handleOpen({
                                        name: 'Chenél Ferreira',
                                        title: 'Founding partner',
                                        desc: `Chenél is a Branding and Creative Consultant. She started her career in publishing 
                                        (she worked at a number of publishing companies, including Commonwealth Publishing UK, Associated Media, New Media on a variety of glossies including O The Oprah Magazine) 
                                        before moving to advertising. Since then she has worked as a Creative Consultant mentoring creative teams at a number of companies,
                                        most recently with Accra-based advertising agency EchoHouse. Chenél loves creative problem-solving and branding. 
                                        (We suspect part of the reason she wanted to start UpSTAR was in order to design branding for it!) She is also passionate about recognising great work and the people doing it. 
                                        And surfing.`
                                    })
                                }} style={{
                                    position: 'relative',
                                }} className='col-6'>
                                    <img 
                                        style={{
                                            marginTop : '10px',
                                            height: '170px',
                                            width: '170px',
                                            borderRadius: '100%',
                                            objectFit: 'cover'
                                        }} 
                                        src={'/img/chenel.jpg'} 
                                        className='container' 
                                        alt=''
                                    />
                                    <h3>Chenél Ferreira</h3>
                                    <h4>Founding partner</h4>
                                    {/* <h3 style={{ color: black, marginBottom : '-20px'}}>Chenél Ferreira</h3>
                                    <p style={{ color: '#343434'}}>Founding Partner</p> */}

                                </div>
                                <div onClick={() => {
                                    this.handleOpen({
                                        name: 'Meg Osler',
                                        title: 'Founding partner',
                                        desc: `Meg has spent the last number of years as a Monitoring and Evaluation Strategist with UCT 
                                            (University of Cape Town), where she has stewarded the development and implementation of HIV and TB health service monitoring software to help improve service delivery 
                                            and inform positive policy changes. As an expert in this field, she has been invited to speak at conferences across the world and is also currently working on her PhD 
                                            examining HIV antiretroviral policies via the analysis of big data. 
                                            Meg’s work in this field has given her extensive experience in software development, which she has brought to the development of UpSTAR. Meg is passionate about great service. 
                                            And surfing.`
                                    })
                                }} style={{
                                    position: 'relative'
                                }} className='col-6'>
                                        <img 
                                            style={{
                                                marginTop : '10px',
                                                height: '170px',
                                                width: '170px',
                                                borderRadius: '100%',
                                                objectFit: 'cover'
                                            }} 
                                            src={'/img/meg.jpg'} 
                                            className='container' 
                                            alt=''
                                        />
                                        <h3>Meg Osler</h3>
                                        <h4>Founding partner</h4>
                                </div>
                            </div>
                        </div>
                        <br/><br/><br/><br/>
                    </div>
                </div>
                <h3>Reviews</h3>
                <div style={{
                    width: '100vw',
                    overflow: 'none'
                }}>
                    {
                        this.state.loading ?
                        <p>Loading reviews...</p> :
                        <div style={{
                            display: 'inline-flex',
                            overflow: 'none'
                        }}>
                            {
                                this.state.votes && this.state.votes.length > 0 &&
                                this.state.votes.map((vote, index) => (
                                    <div key={index} style={{ width: '300px', display: 'block', textAlign: 'center', margin: '15px'}}>
                                        <h3>{vote.review}</h3>
                                        {
                                            vote && vote.score === 0 ?
                                            <div>
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                            </div> :
                                            vote && vote.score > 0 && vote && vote.score < 1 ? 
                                            <div>
                                                <HalfStar style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                            </div> :
                                            vote && vote.score === 1 ?
                                            <div>
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                            </div> :
                                            vote && vote.score > 1 && vote && vote.score < 2 ?
                                            <div>
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <HalfStar style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                            </div> :
                                            vote && vote.score === 2 ?
                                            <div>
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                            </div> :
                                            vote && vote.score > 2 && vote && vote.score < 3 ?
                                            <div>
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <HalfStar style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                            </div> :
                                            vote && vote.score === 3 ?
                                            <div>
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                            </div> :
                                            vote && vote.score > 3.0 && vote && vote.score < 4 ?
                                            <div>
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <HalfStar style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                            </div> :
                                            vote && vote.score === 4 ?
                                            <div>
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                            </div> :
                                            vote && vote.score > 4 && vote && vote.score < 5 ?
                                            <div>
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <HalfStar style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                            </div> :
                                            vote && vote.score === 5 ?
                                            <div>
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto'}} />
                                            </div> : null
                                        }
                                        <h4><i>-{vote.created_by_name}</i></h4>
                                        
                                    </div>
                                ))
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }
}