import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Drawer } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import MailIcon from '@mui/icons-material/Mail';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import '../css/Navbar.css'

const NavBar = ({ state2, setState }) => {
  const [open, setOpen] = useState(false);
  const [newMessage, setNewMessage] = useState(false);
  const [numMessages, setNumMessages] = useState(0);
  const [showStar, setShowStar] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    setNewMessage(true);
  };

  const handleCloseSB = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNewMessage(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.id) {
      state2.socket.emit('add user', user);

      let xyz = { ...user, site: 'navbar' };
      state2.socket.emit('who is online', xyz);

      state2.socket.on('user left', (data) => {
        setState({ usersOnline: data.users });
      });

      state2.socket.on('user joined', (data) => {
        setState({ usersOnline: data.users });
      });

      state2.socket.on('new message', (x) => {
        if (x.to === user.id) {
          state2.socket.emit('num new message', user);
          handleClick();
        }
      });

      state2.socket.on('disconnect', () => {
        setState({ usersOnline: null });
      });

      state2.socket.emit('num new message', user);

      state2.socket.on('message view noted', (data) => {
        state2.socket.emit('num new message', data);
      });

      state2.socket.on('num new message value', (data) => {
        if (data.data && data.data.id === user.id) {
          const numMessagesx = data.val;
          if (numMessagesx.length > 0) {
            let num = 0;
            numMessagesx.forEach((v) => {
              num += v.number;
            });
            setShowStar(true);
            setNumMessages(num);
          } else {
            setShowStar(false);
            setNumMessages(0);
          }
        }
      });

      state2.socket.on('fail num new message', () => {
        console.log('failed to get new messages');
      });
    }
  }, [state2.socket, setState]);

  const token = localStorage.getItem('token');
  const user = localStorage.getItem('user');
  const currentRoute = location.pathname;

  return (
    <div>
      <AppBar
        style={{
          position: 'fixed',
          top: 0,
        }}
        title={
          <img
            alt=''
            onClick={() => {
              navigate('/');
            }}
            style={{ height: '50px', float: 'left' }}
            src='/img/logo2sm.png'
          />
        }
        // showMenuIconButton={false}
      >
        <div>
          {token && (
            <IconButton
              aria-label='show 4 new mails'
              color='inherit'
              onClick={() => {
                if (currentRoute !== '/messages') navigate('/messages');
              }}
              size="large">
              <Badge badgeContent={numMessages} color='secondary'>
                <MailIcon />
              </Badge>
            </IconButton>
          )}

          <IconButton
            edge='end'
            aria-label='account of current user'
            aria-controls='primary-search-account-menu'
            aria-haspopup='true'
            onClick={handleToggle}
            color='inherit'
            size="large">
            <MenuIcon />
          </IconButton>
        </div>
      </AppBar>
      <Drawer
        anchor="right" // Adjust the anchor position as needed
        open={open}
        PaperProps={{
          className: 'drawerPaper'
        }}
        onClose={() => setOpen(false)}
      >
        {user && JSON.parse(user).account_type === 'admin' && (
          <MenuItem
            disabled={currentRoute === '/admin-dashboard'}
            onClick={() => {
              navigate('/admin-dashboard');
            }}
          >
            Admin Dashboard
          </MenuItem>
        )}
        {user && JSON.parse(user).account_type === 'admin' && (
          <MenuItem
            disabled={currentRoute === '/manageUsers'}
            onClick={() => {
              navigate('/manageUsers');
            }}
          >
            Manage Users
          </MenuItem>
        )}
        {token && (
          <MenuItem
            disabled={currentRoute === '/search'}
            onClick={() => {
              navigate('/search');
            }}
          >
            Rate Someone - Search
          </MenuItem>
        )}
        {token && (
          <MenuItem
            disabled={currentRoute === '/mydashboard'}
            onClick={() => {
              navigate('/mydashboard');
            }}
          >
            My Dashboard
          </MenuItem>
        )}
        {token && (
          <MenuItem
            disabled={currentRoute === '/'}
            onClick={() => {
              navigate('/');
            }}
          >
            Five Words
          </MenuItem>
        )}
        {token && (
          <MenuItem
            disabled={currentRoute === '/leaderboard'}
            onClick={() => {
              navigate('/leaderboard');
            }}
          >
            Leaderboard
          </MenuItem>
        )}
        {!token && (
          <MenuItem
            disabled={currentRoute === '/signup'}
            onClick={() => {
              navigate('/signup');
            }}
          >
            Signup
          </MenuItem>
        )}
        {!token && (
          <MenuItem
            disabled={currentRoute === '/login'}
            onClick={() => {
              navigate('/login');
            }}
          >
            Login
          </MenuItem>
        )}
        {token && (
          <MenuItem
            disabled={currentRoute === '/settings'}
            onClick={() => {
              navigate('/settings');
            }}
          >
            Settings
          </MenuItem>
        )}
        <MenuItem
          disabled={currentRoute === '/aboutus'}
          onClick={() => {
            navigate('/aboutus');
          }}
        >
          About Upstar
        </MenuItem>
        {token && (
          <MenuItem
            onClick={() => {
              localStorage.removeItem('token');
              state2.socket.emit('user-click-event', {
                event: 'User-Logout',
                user: localStorage.getItem('user'),
                id: localStorage.getItem('astrangething'),
              });
              localStorage.removeItem('astrangething');
              localStorage.removeItem('user');
              localStorage.removeItem('msgs');
              localStorage.removeItem('vote');
              state2.socket.close();
              window.location = '/';
            }}
          >
            Logout
          </MenuItem>
        )}
      </Drawer>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={newMessage}
        autoHideDuration={6000}
        onClose={handleCloseSB}
      >
        <SnackbarContent
          onClose={handleCloseSB}
          variant='success'
          message='You have a new message!'
        />
      </Snackbar>
    </div>
  );
};

export default NavBar;
