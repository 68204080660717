import React, { useState, useEffect } from 'react';
import {inviteFriends} from '../utils';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import './mydb.css';
import { FormGroup } from '@mui/material';



const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 200,},
            
    },

    main:{
        padding: theme.spacing(3, 2),
        width:"auto",
        margin:"auto",
        '@media (min-width: 400px)':{
            width:'80%',
        },
        '@media (min-width: 500px)':{
            width:'70%',
        },
        '@media (min-width: 700px)':{
            width:'50%',
        },

        '@media (min-width: 900px)':{
            width:'40%',
        },

        
        
    },
    formGroup:{
        alignItems:'center',
        margin: theme.spacing(1,3),
    }
  }));

export default function RateMe(props){

    var x = localStorage.getItem("user");
    props.socket.emit("user-click-event" , {event:"complete-profile", user:x} );

    const [emails, setE] = useState({            
        email1: '',
        email2: '',
        email3: '',
        email4: '',
        email5: '',
    }); 

    const [errors, setEr] = useState({            
        email1: false,
        email2: false,
        email3: false,
        email4: false,
        email5: false,
    }); 

    const [e1, setEr1] = useState(false);
    const [e2, setEr2] = useState(false);
    const [e3, setEr3] = useState(false);
    const [e4, setEr4] = useState(false);
    const [e5, setEr5] = useState(false);

    const [err, setErr] = useState(false);
    const [profile_loading_update, setPLU] = useState(false);
    const [open, setOpen] = useState(false);
    const [feedback, setFeed] = useState('');


    const classes= useStyles();






    const handleChangeEmail = (e) => {
        var ret = ""
        if (e.target.value){
            var email = e.target.value
            if (email && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                ret = 'valid email'
                var x = emails;
                x[e.target.name] = email;
                
                var xx = errors;
                xx[e.target.name] = false;
                
                setEr(xx);
                setE(x);
              //  console.log("valid mail");
              //  console.log(emails);
              setEr1( errors["email1"] );
            setEr2( errors["email2"] )
            setEr3( errors["email3"] )
            setEr4( errors["email4"] )
            setEr5( errors["email5"] )

                }
            else{
                ret='invalid email'
                var x = emails;
                x[e.target.name] = '';
                
                var xx = errors;
                xx[e.target.name] = true;
                
                setEr(xx);
                setE(x);
              //  console.log("invalid mail");
                
              setEr1( errors["email1"] );
        setEr2( errors["email2"] )
        setEr3( errors["email3"] )
        setEr4( errors["email4"] )
        setEr5( errors["email5"] )
            }
        }
        else{
            var x = emails;
            x[e.target.name] = '';

            var xx = errors;
            xx[e.target.name] = false;
            
            setEr(xx);
            setE(x);

            setEr1( errors["email1"] );
            setEr2( errors["email2"] )
            setEr3( errors["email3"] )
            setEr4( errors["email4"] )
            setEr5( errors["email5"] )
    
            console.log("no mail -",xx);
        }

    }

    const handleProfileClose = (e)=>{
        setOpen(false);
        props.history.push('/mydashboard');
    };

    const handleCancel = (e)=>{
        props.history.push('/');
    };

    const count = () =>{
        let x = 0;
        if (e1) x++;
        if (e2) x++;
        if (e3) x++;
        if (e4) x++;
        if (e5) x++;
        
        return x;

    }



    const handleShowPrompt = (e) =>{
        setOpen(true);
        setPLU(true);

        var recepients = [];
        if (emails['email1'])
            recepients.push(emails['email1']);
            
        if (emails['email2'])
            recepients.push(emails['email2']);
            
        if (emails['email3'])
            recepients.push(emails['email3']);
            
        if (emails['email4'])
            recepients.push(emails['email4']);
            
        if (emails['email5'])
            recepients.push(emails['email5']);
            
        console.log(recepients , recepients.length);
    //req.body.recepients && req.body.from && req.body.from_name
        const user = JSON.parse(localStorage.getItem('user'));

        inviteFriends({
            recepients: recepients.length===1? recepients[0] : recepients,
            from: user.email,
            from_name: user.first_name
        }).
        then((res)=>{
            

            setFeed(res);
            setPLU(false);

        })
    };


    return (
        <Paper className={classes.main}>
        
        <form className={classes.root} noValidate autoComplete="off">
        <FormGroup className={classes.formGroup}>
            <h4>Invite people to rate you</h4>
            <br/>

            <TextField id="standard-basic" name='email1' label="Email #1" onChange={handleChangeEmail} error={e1} inputProps={{min: 0, style: { textAlign: 'center' }}}/>
            <TextField id="standard-basic" name='email2' label="Email #2" onChange={handleChangeEmail} error={e2} inputProps={{min: 0, style: { textAlign: 'center' }}}/>
            <TextField id="standard-basic" name='email3' label="Email #3" onChange={handleChangeEmail} error={e3} inputProps={{min: 0, style: { textAlign: 'center' }}}/>
            <TextField id="standard-basic" name='email4' label="Email #4" onChange={handleChangeEmail} error={e4} inputProps={{min: 0, style: { textAlign: 'center' }}}/>
            <TextField id="standard-basic" name='email5' label="Email #5" onChange={handleChangeEmail} error={e5} inputProps={{min: 0, style: { textAlign: 'center' }}}/>
        
            <br/>
            {(e1||e2||e3||e4||e5) && <div>
                <p style={{fontSize:13, color:"red"}}>You've entered an invalid email. Please correct that!</p>
                <br/>
                </div>}
            <Button
                style={{marginBottom:'10px'}}
                secondary={true}
                className={"invite-btn"}
                //style={{marginLeft:'50px'}}
                label= "Cancel"
                onClick={handleCancel}
            />
            <Button
                style={{marginBottom:'10px'}}
                secondary={true}
                disabled = {(e1||e2||e3||e4||e5) || 
                    !(emails["email1"] || emails["email2"] || emails["email3"] || emails["email4"] || emails["email5"])
                }
                className={"invite-btn"}
                //style={{marginLeft:'50px'}}
                label= "Invite friends"
                onClick={handleShowPrompt}
            />
        
        </FormGroup>
            

        </form>

                                  {
                                    profile_loading_update ?
                                    <Dialog
                                        modal={true}
                                        open={open}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description">

                                        <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                        Updating.....
                                        </DialogContentText>
                                        </DialogContent>

                                    </Dialog>
                                         :
                                    <Dialog
                                        className='center'
                                        modal={false}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                        open={open}
                                        onRequestClose={handleProfileClose}
                                    >
                                        <DialogTitle id="alert-dialog-title">Status</DialogTitle>
                                        <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                        {feedback? feedback.msg? feedback.msg : feedback.err : "Error, code 01"}
                                        </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                        <Button onClick={handleProfileClose} color="primary" autoFocus>
                                            Okay
                                        </Button>
                                        </DialogActions>


                                    </Dialog>
                                }
        </Paper>
    );


}