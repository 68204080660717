import React , {useState, useEffect} from 'react';
import { green, orange, red, blue } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import TrafficIcon from '@mui/icons-material/Traffic';
import ShareIcon from '@mui/icons-material/Share';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TrafficData from './traffic';
import ListDisp from './list';
import StarsIcon from '@mui/icons-material/Stars';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import SearchIcon from '@mui/icons-material/Search';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import MostP from './mostpopular';

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    '@media (min-width: 100px)':{
        marginBottom:'20px'
    },
    '@media (min-width: 700px)':{
        float:'left',
        marginLeft:'50px'
    },
    '@media (min-width: 730px)':{
        float:'left',
        marginLeft:'10%'
    },

    '@media (min-width: 800px)':{
        float:'left',
        marginLeft:'14%'
    },

    
    '@media (min-width: 1200px)':{
        float:'left',
        marginLeft:'20px',
    }
  },

  green: {
    backgroundColor: green[500],
    float:'right'
  },

  orange: {
    backgroundColor: orange[500],
    float:'right'
  },

  red: {
    backgroundColor: red[500],
  },

  blue: {
    backgroundColor: blue[500],
  },

  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },

});


const useStyles2 = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));



export default function SimpleCard(props) {
  const classes = useStyles();
  const classes2 = useStyles2();
  const [traffic , setShowTraffic] = useState(false);
  const [loading , setLoading] = useState(false);


  const Summary = (arr) => {
    console.log("summary received" , arr)
    if (arr && arr.length){


      var sum = 0;

      arr.map((d)=>{
        sum+= d.number;
      })

      console.log("value of summary" , sum);

      var d = (

      <Typography variant="body2" component="p" style={{fontSize:38, textAlign:'center'}}>
        {sum}
      </Typography>

      );      
      return d
    }

    return "Error!"

  }

  const handleClick = function(event){

    if (props.kind==0 || props.kind==1 || props.kind==3 || props.kind==8 || props.kind==7 || props.kind==4 || props.kind==5 && props.extra || props.kind==6 && props.extra){
      props.setOpen(true);
    }

  };

  const getTitle = function(){
    switch(props.kind){
        case 0: return "Website Traffic";
        case 1: return "Users Registered";
        case 2: return "# of Shares";
        case 3: return "Users Online";
        case 4: return "Over all most popular user";
        case 5: return "This week's most popular user";
        case 6: return "Newest Member to Join Us";
        case 7: return "Average Time Spent Online";
        case 8: return "Most Searched This Week";

    }
      
  };

  const getAvatar = function(){
      switch(props.kind){
          case 0: return (
            <Avatar className={classes.green}>
                <TrafficIcon />
            </Avatar>
          );
          case 1: return (
            <Avatar className={classes.orange}>
                <PeopleAltIcon />
            </Avatar>
          );
          case 2: return (
            <Avatar className={classes.red}>
                <ShareIcon />
            </Avatar>
          );
          case 3: return (
            <Avatar className={classes.blue}>
                <PersonPinIcon />
            </Avatar>
          );
          case 4: return (
            <Avatar className={classes.orange}>
                <WhatshotIcon/>
            </Avatar>
          );
          case 5: return (
            <Avatar className={classes.blue}>
                <StarsIcon />
            </Avatar>
          );
          case 6: return (
            <Avatar className={classes.red}>
                <FiberNewIcon />
            </Avatar>
          );
          case 7: return (
            <Avatar className={classes.green}>
                <TimelapseIcon />
            </Avatar>
          );
          case 8: return (
            <Avatar className={classes.blue}>
                <SearchIcon />
            </Avatar>
          );
          
      }
  };

  const determineLook = function(){
    if (!props.src || loading) {
      return(
      <div className='center'><CircularProgress size={40} thickness={5} /></div>
      )
    }
    else if (props.kind==0){

      console.log("kind 0 received" , props.src);
      if (props.src)
      return Summary(props.src)
      
    }


    else if (props.kind===4 && props.src && props.extra)
    {

      if (typeof (props.src) === "string"){
        return (        
          <Typography variant="body2" component="p" style={{fontSize:18, textAlign:'center'}}>
            {props.src}
          </Typography>
          )
      }
      else
      {

        return props.extra && props.src?

        <Paper style={{display:"inline-block", width:"70%"}}>
        <Grid container spacing={2} alignItems={"center"} justifyContent={"center"}>
          <Grid item>
          {
            props.extra.profile?
            <Avatar src={props.extra.profile}/>
            :
          <Avatar>
              <AccountBoxIcon />
          </Avatar>
          }
          </Grid>

        
        </Grid>
          <br/>
          <Typography variant="body2" component="p" style={{fontSize:18, textAlign:'center'}}>
            { props.extra.first_name +" "+ props.extra.last_name} 
          </Typography>
        </Paper>
                  :
          null;


      }


    }

    else if (props.kind===5 && props.src)
    {

      if (typeof(props.src) == "string" && !props.extra){
        return (        
        <Typography variant="body2" component="p" style={{fontSize:18, textAlign:'center'}}>
          {props.src}
        </Typography>
        )
      }
      else
      {

        return props.extra && props.src?

        <Paper style={{display:"inline-block", width:"70%"}}>
        <Grid container spacing={2} alignItems={"center"} justifyContent={"center"}>
          <Grid item>
          {
            props.extra.profile?
            <Avatar src={props.extra.profile}/>
            :
          <Avatar>
              <AccountBoxIcon />
          </Avatar>
          }
          </Grid>

        
        </Grid>
          <br/>
          <Typography variant="body2" component="p" style={{fontSize:18, textAlign:'center'}}>
            { props.extra.first_name +" "+ props.extra.last_name} 
          </Typography>
        </Paper>
                  :
          null;


      }


    }

    else if (props.kind===6 && props.src && props.extra)
    {

      if (typeof (props.src) === "string"){
        return (        
        <Typography variant="body2" component="p" style={{fontSize:18, textAlign:'center'}}>
        {props.src}
        </Typography>
        )
      }
      else
      {

        return props.extra && props.src?

        <Paper style={{display:"inline-block", width:"70%"}}>
        <Grid container spacing={2} alignItems={"center"} justifyContent={"center"}>
          <Grid item>
          {
            props.extra.profile?
            <Avatar src={props.extra.profile}/>
            :
          <Avatar>
              <AccountBoxIcon />
          </Avatar>
          }
          </Grid>

        
        </Grid>
          <br/>
          <Typography variant="body2" component="p" style={{fontSize:18, textAlign:'center'}}>
            { props.extra.first_name +" "+ props.extra.last_name} 
          </Typography>
        </Paper>
                  :
          null;


      }


    }

    else if (props.kind===7){

      if (props.src){
      return (
                <Typography variant="body2" component="p" style={{fontSize:25, textAlign:'center'}}>
                { typeof(props.src)!=="string"?  Math.ceil(props.src.avgH)+" H : "+ Math.ceil(props.src.avgM) + " M : "+ Math.ceil(props.src.avgS)+" S" : props.src}
                </Typography>
      )
      }
  


    }


    else if (props.kind===8){

      if (props.src){
      return (
                <Typography variant="body2" component="p" style={{fontSize:18, textAlign:'center'}}>
                  { typeof(props.src)!=="string"?  props.src[0]._id+ " : "+props.src[0].number+" counts." : props.src}
                </Typography>
      )
      }
  


    }

    else

    if (props.kind==1|| props.kind==2 || props.kind==3){

      return ( 
          <Typography variant="body2" component="p" style={{fontSize:38, textAlign:'center'}}>
              {props.src.length}
          </Typography>
      )

    }


  }

  return (
    <Card className={classes.card}>
    
    
    <Backdrop
                className={classes2.backdrop}
                open={props.open}
        
                onClick={() => {
                  props.setOpen(false);
                }}
            >

{/*            <CircularProgress color="inherit" />*/}
      {
        props.kind===0 && props.open?
        <TrafficData data={props.kind==0? props.src : null} text={"Most Visited Pages (MVP)"} vf={"number"} af={"_id"}/>
        :

        (props.kind ===1 || props.kind ==3) && props.open?
        <ListDisp title={props.kind ===1? "Registered Users": "Users Online"} data={props.src} kind={props.kind} />
        :
        
        (props.kind===8 && props.open)?
        <TrafficData data={props.src} text={"Most Searched Items (MSI)"} vf={"number"} af={"_id"} />

        :
        (props.kind===7 && props.open && props.more)?
        <ListDisp title={"Average Time Spent Online By Each User"} data={props.more} kind={props.kind} />
        
        :
        (props.kind===4 && props.open && props.extra)?

        <MostP user={props.extra} extra={props.src} icon={props.kind} title={"Most Popular User Of All Time"}/>

        :
        (props.kind===5 && props.open && props.src && props.extra)?

        <MostP user={props.extra} extra={props.src} icon={props.kind} title={"Most Popular User This Week"}/>

        :
        (props.kind===6 && props.open && props.src && props.extra)?

        <MostP user={props.extra} extra={props.src} icon={props.kind} title={"Newest User To Join Us"}/>

        :
        null
        
      }

    </Backdrop>

    
    <CardHeader
        avatar={
          
            getAvatar()

        }
        title={getTitle()}
      />
      <CardContent>
          {
            determineLook()
          }

      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleClick}>View More</Button>
      </CardActions>
    </Card>
  );
}