import React, { Component } from 'react'
import { 
    List, 
    ListItem, 
    TextField,
    Dialog,
    Button,
    CircularProgress
} from '@mui/material'

import {Card} from '@mui/material/';

import {
    getEmails,
    addEmail,
    deleteEmail,
    updateEmail
} from '../../utils'

export default class Settings extends Component {
    
    constructor(props){
        super(props);
        var x = localStorage.getItem("user");
        props.socket.emit("user-click-event" , {event:"settings", user:x} );
    }
    
    state = {
        selected: 0,
        emails: [],
        loading: false,

        add_email: false,
        email: '',
        msg: null
    }

    componentDidMount() {
       this.getEmails()
    }

    getEmails = () => {
        const user = localStorage.getItem('user')
        const parsed_user = user && JSON.parse(user);
        this.setState({ loading: true })
        getEmails(parsed_user.id)
        .then(res => {
            this.setState({ emails: res, loading: false })
        })
    }
  render() {
      const { loading, emails, err, msg } = this.state

    return (
      <div className='container'>
            <h3>Settings</h3>
            <div className='row'>
                <div className='col-4'>
                    <List style={{
                        backgroundColor: '#fff',
                        paddingTop: 0,
                        paddingBottom: 0,
                        marginTop: '22px',
                        width: '100%'
                    }}>
                        <ListItem 
                            id='personal' 
                            style={{
                                color: this.state.selected === 0 ? '#fff' : '#343434', 
                                backgroundColor: this.state.selected === 0 ? 'rgb(46, 149, 198)' : null
                            }} 
                            onClick={() => this.setState({ selected: 0})} 
                            primaryText="Emails"  
                        />
                    </List>
                </div>
                <div className='col-8'>
                    {
                        <Card style={{ backgroundColor: '#fff', width: '100%'}}>
                            <div className='container'>
                                <br/>
                                <h3 style={{ color: '#343434'}}>Emails</h3>
                                <List style={{
                                    paddingTop: 0,
                                    marginTop: 0,
                                    color: '#000'
                                }}> 
                                    {
                                        loading ?
                                        <div className='center'><CircularProgress size={80} thickness={5} /></div> :
                                        emails.map((item, index) => (
                                            <ListItem style={{
                                                color: '#000'
                                            }} key={index} 
                                                primaryText={item.email}
                                                secondaryText={
                                                    <span style={{
                                                        color: '#000',
                                                    }}>
                                                        {
                                                            item.primary ?
                                                            "Primary " : null
                                                        }
                                                        {
                                                            item.verified ?
                                                            " Verified" : null
                                                        }
                                                        <br/>
                                                        {
                                                            !item.primary ? <Button onClick={() => {
                                                                this.setState({ delete_email: item.id })
                                                            }} secondary={true} label="Delete"/> : 
                                                            item.verified && !item.primary ? <Button onClick={() => {
                                                                this.setState({ update_email: item.id })
                                                            }} secondary={true} label="Primary"/> : null
                                                        }
                                                        <hr style={{border: '1px solid grey', borderRadius: '1px'}}/>
                                                    </span>
                                                }
                                            />
                                        ))
                                    }
                                    <Button
                                        style={{marginBottom: '10px'}}
                                        label='Add email' 
                                        secondary={true} 
                                        fullWidth={true}
                                        onClick={() => { this.setState({ add_email: true })}}
                                    />
                                </List>
                            </div>
                            </Card>
                    }
                </div>
            </div>

            <Dialog
                modal={true}
                open={this.state.add_email}
            >
                <div className='container center'>
                    <h3>Add new email</h3>
                    {
                        msg ?
                        <p>{msg}</p> : 
                        err ?
                        <p>{err}</p> : null
                    }
                    <br/>
                    {!msg && <TextField
                        id="email"
                        label="Email"
                        placeholder='Email'
                        value={this.state.email}
                        onChange={e => this.setState({ email: e.target.value })}
                    />}
                </div>
                <br/>
                {!msg && <Button
                    style={{marginBottom: '10px'}}
                    label='Add email' 
                    secondary={true} 
                    fullWidth={true}
                    disabled={loading}
                    onClick={() => { 
                        console.log("EMAIL TO ADD", this.state.email)
                        this.setState({ loading: true })
                        addEmail(this.state.email)
                        .then(res => {
                            console.log("ADD RESPONSE", res)
                            if (res.id) {
                                this.setState({ loading: false, msg: "Success! Please check email for verification message" })
                            } else {
                                this.setState({ loading: false, err: res.message })
                            }
                        })
                    }}
                />}
                <br/>
                <Button
                    style={{marginBottom: '10px'}}
                    label={msg ? "Okay" : 'Cancel'} 
                    fullWidth={true}
                    onClick={() => { 
                        this.getEmails()
                        this.setState({ add_email: false, err: null, msg: null, email: '' })
                    }}
                />
            </Dialog>

            <Dialog
                modal={true}
                open={this.state.delete_email ? true : false }
            >
                <div className='container center'>
                    <h3>Delete email</h3>
                    {
                        msg ?
                        <p>{msg}</p> : 
                        err ?
                        <p>{err}</p> : 
                        "Are you sure you want to delete this email?"
                    }
                    <br/>
                    
                </div>
                <br/>
                {!msg && <Button
                    style={{marginBottom: '10px'}}
                    label='Delete email' 
                    secondary={true} 
                    fullWidth={true}
                    disabled={loading}
                    onClick={() => { 
                        this.setState({ loading: true })
                        deleteEmail({ id: this.state.delete_email })
                        .then(res => {
                            if (!res.status) {
                                this.setState({ loading: false, msg: "Success! email deleted" })
                            } else {
                                this.setState({ loading: false, err: res.message })
                            }
                        })
                    }}
                />}
                <br/>
                <Button
                    style={{marginBottom: '10px'}}
                    label={msg ? "Okay" : 'Cancel'} 
                    fullWidth={true}
                    onClick={() => { 
                        this.getEmails()
                        this.setState({ delete_email: false, err: null, msg: null, email: '' })
                    }}
                />
            </Dialog>

            <Dialog
                modal={true}
                open={this.state.update_email ? true : false }
            >
                <div className='container center'>
                    <h3>Update email</h3>
                    {
                        msg ?
                        <p>{msg}</p> : 
                        err ?
                        <p>{err}</p> : 
                        "Are you sure you want to set this as your primary email?"
                    }
                    
                </div>
                <br/>
                {!msg && <Button
                    style={{marginBottom: '10px'}}
                    label='Update email' 
                    secondary={true} 
                    fullWidth={true}
                    disabled={loading}
                    onClick={() => { 
                        this.setState({ loading: true })
                        updateEmail({ id: this.state.update_email })
                        .then(res => {
                            console.log("UPDATE RESPONSE", res)
                            if (!res.status) {
                                this.setState({ loading: false, msg: "Success! Email updated" })
                            } else {
                                this.setState({ loading: false, err: res.message })
                            }
                        })
                    }}
                />}
                <br/>
                <Button
                    style={{marginBottom: '10px'}}
                    label={msg ? "Okay" : 'Cancel'} 
                    fullWidth={true}
                    onClick={() => { 
                        this.getEmails()
                        this.setState({ update_email: false, err: null, msg: null, email: '' })
                    }}
                />
            </Dialog>

      </div>
    )
  }
}
