import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import {Button} from '@mui/material/';
import { resetPasswordConfirm } from '../utils'
import './../simple-grid.css';

export default class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            new_password1: '',
            new_password2: '',
            open: false,
            err: null,
            show_password: false
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    onSubmit(e) {
        e.preventDefault();
        // console.log('State', this.state)
        this.setState({ loading: false })

        var urlParams = new URLSearchParams(this.props.location.search)

        const uid = urlParams.get('uid');
        const token = urlParams.get('token');
        
        const data = {
            new_password1: this.state.new_password1,
            new_password2: this.state.new_password2,
            uid,
            token
        }
        console.log('Data', data)
        resetPasswordConfirm(data)
        .then((res) => {
            if(res.status === 400) {
                this.setState({ open : false, err: res.message})
            } else {
                this.setState({ loading: false, open: true })
            } 
        })
    }

    render() {

        // let {  new_password1, new_password2} = this.state;
        // const greyButton = new_password1.length > 0 && new_password2.length > 0;

        const actions = [
            <Button
              label="Cool"
              primary={true}
              onClick={this.handleClose}
            />
          ];

        return (
            <div className='container center'>
                <div>
                    <img className='image-container' src='/img/logo.png' alt='' />
                </div>
                <form onSubmit= {this.onSubmit}>
                    <h2>Password reset</h2>
                    <TextField
                        name='new_password1'
                        placeholder='New password'
                        type={this.state.show_password ? 'text' : 'password'}
                        value={this.state.new_password1}
                        onChange={this.handleChange}
                    />
                    <br />
                    <TextField
                        name='new_password2'
                        placeholder='New password confirm'
                        type={this.state.show_password ? 'text' : 'password'}
                        value={this.state.new_password2}
                        onChange={this.handleChange}
                    />
                    <br /><br/>
                    <span style={{
                        cursor: 'pointer',
                        userSelect: 'none'
                    }} onClick={() => {
                        this.setState({ show_password: !this.state.show_password})
                    }}>
                        {
                            this.state.show_password ?
                            "Hide Password" :
                            "Show Password"
                        }
                    </span>
                    <br /><br/>
                    {
                        this.state.err ?
                        <span style={{ color: 'red' }}>{this.state.err}<br/></span> :
                        null
                    }
                    <br/>
                    <Button
                        type='submit'
                        style={{marginBottom:'10px'}}
                        primary={true}
                        label= 'Reset password'
                        // disabled= { !greyButton || this.state.loading }
                    />
                    {/* pop up */}
                    <Dialog
                        className= 'center'
                        title="All done! "
                        actions={actions}
                        modal={false}
                        open={this.state.open}
                        onRequestClose={this.handleClose}
                    >
                        {/* {this.state.msg} */}
                        Your password has been updated.
                    </Dialog>
                </form>
            </div>
        )
    }
}