import React , {useState , useEffect} from 'react';
import ViewUser from './view-user';
import FloatingActionButtons from './tools-menu';
import CheckboxListSecondary from './users-list';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import SimpleCard from './card';
import Typography from '@mui/material/Typography';
import {mostPopularThisWeek, mostPopular, newestUser , getUser, averageHours, mostSearched , averageHoursPP} from '../../utils';

export default function ManageUsers(){


    const [industry , setInd] = useState(""); 

    const [job , setJob] = useState(""); 

    const [otherFeature , setOF] = useState("Name");

    const [search , setSearch] = useState(null);

    const [user, setUser] = useState(null);

    const [enabled , setEnabled] = useState(null);

    const [avgTime , setAVG] = useState(null);
    const [avgTime2 , setAVG2] = useState(null);
    
    const [mostPoular1, setMP] = useState(null);
    const [mostPoular11, setMP1] = useState(null);

    const [mostPoular2, setMPTW] = useState(null);
    const [mostPoular21, setMPTW1] = useState(null);
    
    const [newestUser1, setNU] = useState(null);
    const [newestUser11, setNU1] = useState(null);

    const [searchStats , setSearch2] = useState(null);

    const [open1 , setOpen1] = useState(false);
    const [open2 , setOpen2] = useState(false);
    const [open3 , setOpen3] = useState(false);
    const [open4 , setOpen4] = useState(false);
    const [open5 , setOpen5] = useState(false);


    useEffect(()=>{

        mostPopularThisWeek().
        then(data=>{
            console.log("MPTW",data);
            if (data && data.length>0){

                getUser(data[0]._id).
                then(data2=>{

                    if (data2){

                        console.log("most popular this week", data);
                        console.log("most popular user this week", data2);
                        setMPTW(data[0]);
                        setMPTW1(data2);

                    }
                    else
                    {
                        console.log("There is no popular User this week else statement 1");
                        setMPTW("There is no popular User this week")        
                    }


                })
            }
            else
            {
                console.log("There is no popular user this week else statement 2");
                setMPTW("There is no popular User this week")
            }

        })

        mostPopular().
        then(data=>{
            if (data && data._id && data.number){
                getUser(data._id).
                then(data2=>{

                    if (data2){

                        console.log("most popular", data);
                        console.log("most popular user", data2);
                        setMP(data);
                        setMP1(data2);
                    }


                })

            }
            else{
                console.log("There is no popular User");
                setMP("There is no popular User");
            }
        })

        newestUser().
        then(data=>{
            if (data && data.rehive_user_id){
                getUser(data.rehive_user_id).
                then(data2=>{

                    if (data2){

                        console.log("newest", data);
                        console.log("newest user", data2);
                        setNU(data);
                        setNU1(data2);
                    }


                })

            }
            else{
                console.log("There is no newest User");
                setNU("There is no newest User");
            } 
        })


        averageHours().
        then(data=>{
            
            if (data && data.length>0){

                setAVG(data[0]);

            }
            else{

                setAVG("There is no average Time")

            }

        })


        averageHoursPP().
        then(data=>{

            if (data && data.length>0){

                setAVG2(data);

            }
            else{

                setAVG2("There is no average Time per user")

            }

        })


        mostSearched().
        then(data=>{

            if (data && data.length>0){
                setSearch2(data);
            }
            else
            {
                setSearch2("There are no searches made");
            }


        })




    }, [])
    


    return(


<div className={"manage-users"}>
<CssBaseline/>
<Paper style={{display:'inline-block', width:"100%", minHeight:'700px'}}>
<FloatingActionButtons industry={industry} setInd={setInd} job={job} setJob={setJob} 
otherFeature={otherFeature} setOF={setOF} search={search} setSearch={setSearch}/>
<br/>
<br/>
<div className={"mu-p2"}>

<CheckboxListSecondary  industry={industry} setInd={setInd} job={job} setJob={setJob} 
otherFeature={otherFeature} setOF={setOF} search={search} setSearch={setSearch}
setUser={setUser}
/>

<ViewUser  industry={industry} setInd={setInd} job={job} setJob={setJob} 
otherFeature={otherFeature} setOF={setOF} search={search} setSearch={setSearch} user={user} setEnabled={setEnabled} enabled={enabled}/>

</div>

</Paper>

<Paper style={{display:'inline-block', width:"100%", minHeight:300, marginTop:100 , }}>

    <Typography variant="h2" component="h2" gutterBottom style={{textAlign:'center' , marginTop:'50px' , marginBottom:'50px'}}>
            User Analytics
    </Typography>

    <SimpleCard kind= {4} src ={mostPoular1} extra={mostPoular11} open={open1} setOpen={(p)=> setOpen1(p)} />
    <SimpleCard kind= {5} src ={mostPoular2} extra={mostPoular21} open={open2} setOpen={(p)=> setOpen2(p)}/>
    <SimpleCard kind= {6} src ={newestUser1} extra={newestUser11} open={open3} setOpen={(p)=> setOpen3(p)}/>
    <SimpleCard kind= {7} src ={avgTime} more={avgTime2} open={open4} setOpen={(p)=> setOpen4(p)}/>
    <SimpleCard kind= {8} src ={searchStats} open={open5} setOpen={(p)=> setOpen5(p)}/>

</Paper>

</div>


    );


};