import React, {useState , useEffect} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import PersonIcon from '@mui/icons-material/Person';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

import CircularProgress from '@mui/material/CircularProgress'

import {navigationLogs , userActions,userlist } from '../../utils';
import {getObjects} from '../../jp';



const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 740,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

export default function AlignItemsList(props) {
  const classes = useStyles();
  const [list, setList] = useState(null);
  const [uList , setUList] = useState(null);

  const user_id = JSON.parse(localStorage.getItem("user")).id;

  var d = new Date();
  d.setDate(d.getDate() - 1);

  function timeSince(timeStamp) {
    var now = new Date(),
      secondsPast = (now.getTime() - timeStamp) / 1000;
    if (secondsPast < 60) {
      return parseInt(secondsPast) + 's ago';
    }
    if (secondsPast < 3600) {
      return parseInt(secondsPast / 60) + 'm ago';
    }
    if (secondsPast <= 86400) {
      return parseInt(secondsPast / 3600) + 'h ago';
    }
    if (secondsPast > 86400) {
      if (timeStamp.toDateString()=== d.toDateString()){

        return "yesterday"

      }
      else{
        var day = timeStamp.getDate();
        var month = timeStamp.toDateString().match(/ [a-zA-Z]*/)[0].replace(" ", "");
        var year = timeStamp.getFullYear() == now.getFullYear() ? "" : " " + timeStamp.getFullYear();
         return day + " " + month + year;
   
      }
    }
  }



useEffect(
  ()=>{

    function custom_sort(a, b) {
      return new Date(b.time).getTime() - new Date(a.time).getTime();
  }

  function assignNames(a2a2 , a2a4){

    for (var i=0; i< a2a2.length ; i++){

      if ((a2a2[i].by && a2a2[i].by!=="Anonymous") || !a2a2[i].by && a2a2[i].extra.adminId){
      var o = getObjects(a2a4 , "id", !a2a2[i].by && a2a2[i].extra.adminId? a2a2[i].extra.adminId : a2a2[i].by);
      if (o && o[0]){
      a2a2[i]["real_name"] = o[0].first_name + " " + o[0].last_name;
      a2a2[i]["profile"] = o[0].profile;

      if (a2a2[i].event=="User-Logout"){

        var o2 = a2a2.filter(a=> {return (a.extra.id && a.extra.id.toString()===a2a2[i].extra.id && a.action === "user-login")});
        
        if (o2 && o2.length==1){
        }


      }


      }
      else
      {
        a2a2[i]["real_name"] = "Anonymous"
      }

    }

  else
  {
    a2a2[i]["real_name"] = "Anonymous"
  }

  }
  }

  userlist().
  then(data1=>{

  navigationLogs({limit:20}).
  then(data=>{

      userActions({limit:20}).
      then(data2=>{

          var d = [];

          assignNames(data , data1);
          assignNames(data2 , data1);

          
          if (data){
              d = d.concat(data);
          }

          if (data2){
              d = d.concat(data2);
          }

          d.sort(custom_sort);
          setList(d);
          setUList(data1);
          

      })

  })

})

  //Fin
  },
  []
)

  return (
    <div style={{display:'inline-block' , 'width':'740px'}}>

    <Typography variant="h4" component="h4" gutterBottom style={{textAlign:'center'}}>
            Recent Activity
    </Typography>

    <Paper style={{display:'inline-block', height:"500px" , overflowY:"scroll","float":"left" , width:"100%"}}>
    <List className={classes.root}>

      {
        !list?
        <div className='center'><CircularProgress size={80} thickness={5} /></div> :

      list.map(d=>(
      <React.Fragment style={{width:"100%"}}>
      <ListItem alignItems="flex-start" style={{width:"100%"}}>
        <ListItemAvatar>
          {
            d.real_name==="Anonymous"?
            <Avatar>
              <PersonIcon/>
            </Avatar>
            :
            !d.profile?
            <Avatar>
              <AccountBoxIcon/>
            </Avatar>
            :
            <Avatar alt={d.real_name} src={d.profile} />}
        </ListItemAvatar>
        <ListItemText
          primary={d.event?d.event:d.action}
          onClick={() => {
                      if (d.event==='another-user page')
                      window.location.href = '#/user/' + d.extra
                      //  window.location.reload();
              
                }}

          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
                onClick={() => {
                    if (user_id === d.by) {
                      window.location.href = '#/mydashboard'
                    } else {
                      window.location.href = '/user/' + d.by
                      //  window.location.reload();
                    }
                }}

              >
                {d.real_name}
              </Typography>
              {"" /* Text can go here btw */}
            </React.Fragment>
          }
          style={{marginRight:"20px" , width:"33.33%"}}
        />



        <ListItemText
          primary={timeSince(new Date(d.time))}
          secondary={""
          /* Text can go here btw */
          }
          style={{width:"33.33%"}}
          />


          <ListItemText
          primary={d.extra ? d.event== "another-user page" || d.action==="search" || d.extra.status? "SUCCESS" : "ERROR" : "All good!"}
          secondary={
            d.extra ? 
              d.extra.msg? //true 0
                typeof(d.extra.msg)==="String"?d.extra.msg
                :
                d.extra.msg.name
                
                 : //true 1
                (d.action==="search")? //false 1 =2 
                  "Search Value: "+ d.extra :  //true 2
                  d.event=="User-Logout"? //false 2 = 3
                    d.Duration : //true 3
                  d.action==="update-profile-view" && d.extra.status? //false 3 =4
                    "Profile is now "+ (d.extra.newView ? "public" : "private") //true 4
                   : 

                   (d.action==="user-account-status-change")?
                   "User account is now "+ !d.extra.statusTo?"Blocked" : "Enabled" 
                   :
                   (d.action==="user-account-type-change")?
                   "User is now a "+ d.extra.typeTo 
                   :

                   null //false 4 == add new conditiion here
                   :
            null //false 0
          }
          style={{width:"33.33%"}}
          />


      </ListItem>
      <Divider variant="inset" component="li" />
      </React.Fragment>))
    }

    </List>
    </Paper>
    </div>
  );
}