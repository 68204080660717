import React, { Component } from 'react';
import './mydb.css';
import { Card } from '@mui/material';
import { CardContent } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { Chip } from '@mui/material';
import StarBorder from '@mui/icons-material/StarBorder';
import HalfStar from '@mui/icons-material/StarHalf';
import Star from '@mui/icons-material/Star';
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import Moment from 'react-moment';
import { getVotes, getUser, updateProfile } from '../utils';
import PicNWords from '../components/pic-n-words';
import PicNWordsSmall from '../components/pic-n-words-small';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    WhatsappIcon,
    EmailIcon,
} from 'react-share';


const styles = {
    chip: {
        margin: 4,
        display: 'inline-block'
    },
    wrapper: {
        display: 'inline-block'
    },
};

export default class RatingDash extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            votes_list: [],
            full_list_open: false,
            loading: false,
            avg_todays_vote: 0,
            avg_lastweek_vote: 0,
            avg_twoweeks_vote: 0,
            avg_threeweeks_vote: 0,
            profile_open: false,
            profile_loading_update: false,
            profile_message: null,
            sprompt: false,
        }
        var x = localStorage.getItem("user");
        props.socket.emit("user-click-event", { event: "my-dashboard", user: x });

    }

    componentDidMount() {
        this.setState({ loading: true })
        const user = JSON.parse(localStorage.getItem('user'));
        getUser(user.id)
            .then(userRes => {
                getVotes(user.id)
                    .then(results => {
                        // console.log('RESULTS MY DASHBOARD', results)
                        this.setState({
                            loading: false,
                            user: userRes,
                            votes_list: results.items,
                            avg_todays_vote: results.avg_todays_vote,
                            avg_lastweek_vote: results.avg_lastweek_vote,
                            avg_twoweeks_vote: results.avg_twoweeks_vote,
                            avg_threeweeks_vote: results.avg_threeweeks_vote
                        })
                    });
            });
    }

    toggle = () => {
        this.setState({
            full_list_open: !this.state.full_list_open
        });
    }

    navigate = (path) => {
        this.props.history.push(path)
    }

    handleShowPrompt = () => {
        this.setState({ sprompt: true });
    }

    handleHidePrompt = () => {
        this.setState({ sprompt: false });
    }


    handleProfileOpen = () => {
        this.setState({
            sprompt: false,
            profile_open: true,
            profile_loading_update: true,
        });

        //user.show_profile
        updateProfile({ show_profile: !this.state.user.show_profile, id: this.state.user.id })
            .then((res) => {
                if (res.status && res.status === "success") {
                    this.setState({
                        profile_loading_update: false
                    });
                    this.state.user.show_profile = !this.state.user.show_profile;
                }
                else {
                    this.setState({
                        profile_message: "An error occured whilst updating. try again later",
                        profile_loading_update: false,
                    });
                }

            })
            .catch((err) => {
                this.setState({
                    profile_message: "An error occured whilst updating. try again later",
                    profile_loading_update: false,
                });
            });
    };

    handleProfileClose = () => {
        this.setState({
            profile_open: false,
            profile_message: null,
        });
    };

    getVotes() {
        let vote_list = this.state.votes_list;
        let sort_list = vote_list.sort((a, b) => Date.parse(a.created) - Date.parse(b.created));
        let list = sort_list.reverse();

        if (this.state.full_list_open) {
            return list;
        }
        return list.slice(0, 6)
    }


    render() {
        const { user } = this.state

        const { votes_list } = this.state;
        // Handle case where votes_list is not yet defined or empty
  if (!votes_list || votes_list.length === 0) {
    return <div>No votes available</div>;
  }

  let scoreArray = votes_list.map((vote) => vote.score);
  let scoreCount = scoreArray.reduce((obj, item) => {
    if (!obj[item]) {
      obj[item] = 0;
    }
    obj[item]++;
    return obj;
  }, {});

        const actions = [
            <Button
                label="Cool"
                primary={true}
                onClick={this.handleProfileClose}

            />
        ];

        const data = [
            { name: '5', votes: scoreCount['5'] },
            { name: '4', votes: scoreCount['4'] },
            { name: '3', votes: scoreCount['3'] },
            { name: '2', votes: scoreCount['2'] },
            { name: '1', votes: scoreCount['1'] },
            { name: '0', votes: scoreCount['0'] }
        ]



        return (
            <div className='container'>
                {
                    this.state.loading ?
                        <div className='center'><CircularProgress size={80} thickness={5} /></div> :
                        user && <div className='center'>
                            <div className='row'>
                                <div className='col-12'>
                                    <Card style={{ backgroundColor: white, color: '#343434' }}>
                                        <PicNWords user={user} />
                                        {/* user name and surname under image */}
                                        <h1 style={{ marginTop: '-1px', marginBottom: '-15px' }}>{user.first_name} {user.last_name}</h1>

                                        {
                                            user.user_company === '' ?
                                                <div>
                                                    <p></p><br />
                                                </div> :
                                                <p>{user.user_company}</p>
                                        }

                                        {/* five words in chip */}
                                        <div className='center'>
                                            {
                                                user.five_words === undefined ?
                                                    null :
                                                    user.five_words && user.five_words.length > 0 ? user.five_words.map((word, index) => (
                                                        word && <div key={index} style={styles.wrapper}>
                                                            {
                                                                word === "" ?
                                                                    null :
                                                                    <Chip
                                                                        style={styles.chip}
                                                                    >
                                                                        {word}
                                                                    </Chip>
                                                            }
                                                        </div>
                                                    )) :
                                                        null
                                            }
                                        </div>

                                        <div className='container'>
                                            <div className='row'>
                                                {/* Show not yet rated if a user has not yet been rated */}
                                                <div className='col-6'>
                                                    {
                                                        user.number_of_votes ?
                                                            // user.vote_list !== 0 ?
                                                            <div>
                                                                <h5 style={{ marginBottom: '-15px' }}>{user.first_name}'s rating</h5>
                                                                <h1 style={{ marginBottom: '-3px' }}>{user.votes.toFixed(1)}</h1>
                                                            </div> :
                                                            null
                                                    }
                                                    {
                                                        // user.number_of_votes === 0 ?
                                                        // null :
                                                        user.votes > 0 && user.votes < 1 ?
                                                            <div>
                                                                <HalfStar style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                            </div> :
                                                            user.votes === 1 ?
                                                                <div>
                                                                    <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                    <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                    <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                    <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                    <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                </div> :
                                                                user.votes > 1 && user.votes < 2 ?
                                                                    <div>
                                                                        <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                        <HalfStar style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                        <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                        <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                        <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                    </div> :
                                                                    user.votes === 2 ?
                                                                        <div>
                                                                            <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                            <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                            <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                            <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                            <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                        </div> :
                                                                        user.votes > 2 && user.votes < 3 ?
                                                                            <div>
                                                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                <HalfStar style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                            </div> :
                                                                            user.votes === 3 ?
                                                                                <div>
                                                                                    <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                    <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                    <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                    <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                    <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                </div> :
                                                                                user.votes > 3 && user.votes <= 3.9 ?
                                                                                    <div>
                                                                                        <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                        <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                        <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                        <HalfStar style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                        <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                    </div> :
                                                                                    user.votes === 4 ?
                                                                                        <div>
                                                                                            <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                            <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                            <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                            <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                            <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                        </div> :
                                                                                        user.votes > 4 && user.votes < 5 ?
                                                                                            <div>
                                                                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                <HalfStar style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                            </div> :
                                                                                            user.votes === 5 ?
                                                                                                <div>
                                                                                                    <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                    <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                    <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                    <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                    <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                </div> : null
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        user.number_of_votes === 0 ?
                                                            <div className='col-12'>
                                                                <p style={{ marginBottom: '-15px', marginTop: '-15px' }} >Not yet rated</p>
                                                            </div> :
                                                            <div className='col-6'>
                                                                {
                                                                    user.position === '' || user.position === undefined ?
                                                                        <p style={{ paddingTop: '55px' }} >Position not provided for ranking</p> :
                                                                        <div>
                                                                            <h5 style={{ marginBottom: '-15px' }} >{user.first_name} is </h5>
                                                                            <h1 style={{ marginBottom: '-15px' }} > #{user.position_ranking}</h1>
                                                                            <h5>of all {user.position}'s</h5>
                                                                        </div>
                                                                }

                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <br />

                                        <Button
                                            style={{ marginBottom: '10px' }}
                                            secondary={true}
                                            className={"profile-btn pb1"}
                                            //marginRight:'50px'
                                            label={user.show_profile ? "Hide Profile" : "Show Profile"}
                                            onClick={this.handleShowPrompt}
                                        />

                                        {
                                            (user && user.five_words && user.five_words.length === 0) || (!user.five_words || user.profile === null || user.position === '') ?
                                                <Button
                                                    style={{ marginBottom: '10px' }}
                                                    className={"profile-btn pb2"}
                                                    secondary={true}
                                                    label='Complete profile'
                                                    onClick={() => { this.navigate('/completeprofile') }}
                                                /> :
                                                <Button
                                                    style={{ marginBottom: '10px' }}
                                                    secondary={true}
                                                    className={"profile-btn pb2"}
                                                    label='Update profile'
                                                    onClick={() => { this.navigate('/update') }}
                                                />
                                        }

                                        <Button
                                            style={{ marginBottom: '10px' }}
                                            secondary={true}
                                            className={"profile-btn"}
                                            //style={{marginLeft:'50px'}}
                                            label="Get Rated"
                                            onClick={() => { this.navigate('/rateme') }}
                                        />


                                        <br />
                                        <div className="Demo__some-network">
                                            <FacebookShareButton
                                                url={"https://upstar.co.za/#/"}
                                                quote={"check my upstar profile!"}
                                                className="Demo__some-network__share-button">
                                                <FacebookIcon
                                                    size={32}
                                                    round />
                                            </FacebookShareButton>

                                            <TwitterShareButton
                                                url={"https://upstar.co.za/#/"}
                                                title={"check my upstar profile!"}
                                                className="Demo__some-network__share-button">
                                                <TwitterIcon
                                                    size={32}
                                                    round />
                                            </TwitterShareButton>

                                            <WhatsappShareButton
                                                url={"https://upstar.co.za/#/"}
                                                title={"check my upstar profile!"}
                                                separator=":: "
                                                className="Demo__some-network__share-button">
                                                <WhatsappIcon size={32} round />
                                            </WhatsappShareButton>


                                            <LinkedinShareButton
                                                url={"https://upstar.co.za/#/"}
                                                windowWidth={750}
                                                windowHeight={600}
                                                className="Demo__some-network__share-button">
                                                <LinkedinIcon
                                                    size={32}
                                                    round />
                                            </LinkedinShareButton>


                                            <EmailShareButton
                                                url={"https://upstar.co.za/#/"}
                                                subject={"check my upstar profile!"}
                                                body="body"
                                                className="Demo__some-network__share-button">
                                                <EmailIcon
                                                    size={32}
                                                    round />
                                            </EmailShareButton>
                                        </div>
                                        {
                                            this.state.sprompt &&

                                            <Dialog
                                                open={this.state.sprompt}
                                                onClose={this.handleHidePrompt}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogTitle id="alert-dialog-title">{this.state.user.show_profile ? "Confirm to hide profile?" : "Confirm to show profile?"}</DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="alert-dialog-description">
                                                        {
                                                            this.state.user.show_profile ?

                                                                "This means that you're making your profile private and so it won't be visible to future recruiters and it will neither be on the main Dashboard or the Leaderboard." +
                                                                "Also that users who view your profile won't see your ratings or reviews made on your profile."

                                                                :

                                                                "This means that you're making your profile public and your profile can once again be seen by other users and so will your Reviews and Ratings. It will also appear on the Leaderboard."
                                                        }
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={this.handleHidePrompt} color="primary">
                                                        Disagree
                                                    </Button>
                                                    <Button onClick={this.handleProfileOpen} color="primary" autoFocus>
                                                        Agree
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>

                                        }
                                        {
                                            this.state.profile_loading_update ?
                                                <Dialog
                                                    modal={true}
                                                    open={this.state.profile_open}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description">

                                                    <DialogContent>
                                                        <DialogContentText id="alert-dialog-description">
                                                            Updating.....
                                                        </DialogContentText>
                                                    </DialogContent>

                                                </Dialog>
                                                :
                                                <Dialog
                                                    className='center'
                                                    modal={false}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                    open={this.state.profile_open}
                                                    onRequestClose={this.handleProfileClose}
                                                >
                                                    <DialogTitle id="alert-dialog-title">Status</DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="alert-dialog-description">
                                                            {this.state.profile_message ? this.state.profile_message : "Your profile been successful updated."}
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={this.handleProfileClose} color="primary" autoFocus>
                                                            Okay
                                                        </Button>
                                                    </DialogActions>


                                                </Dialog>
                                        }
                                    </Card>
                                </div>

                                {/* Graphs */}
                                <div className='col-12'>
                                    {
                                        user && user.number_of_votes ?
                                            // user && user.vote_list.length ?
                                            <Card style={{ backgroundColor: '#FAFAFA' }}>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='row'>
                                                            <div className='col-6 center' style={{ marginBottom: '-30px', marginLeft: '-40px' }}>
                                                                <h4 style={{ color: '#343434', marginBottom: '-15px' }}>Review summary</h4>
                                                                <ComposedChart layout="vertical" width={300} height={240} data={data} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                                                                    <CartesianGrid stroke='#f5f5f5' />
                                                                    <XAxis display='none' type="number" />
                                                                    <YAxis dataKey="name" type="category" />
                                                                    <Tooltip />
                                                                    <Bar dataKey='votes' barSize={20} fill='#2E95C6' />
                                                                </ComposedChart>
                                                            </div>
                                                            <div className='col-6'>
                                                                <div style={{ color: '#343434' }}>
                                                                    <h2 style={{ marginBottom: '-5px' }}>{user.votes.toFixed(1)}</h2>
                                                                    <div style={{ marginBottom: '-20px' }}>
                                                                        {/* stars with graph */}
                                                                        {
                                                                            user.votes === 0 ?
                                                                                <div>
                                                                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                </div> :
                                                                                user.votes > 0 && user.votes < 1 ?
                                                                                    <div>
                                                                                        <HalfStar style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                        <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                        <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                        <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                        <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                    </div> :
                                                                                    user.votes === 1 ?
                                                                                        <div>
                                                                                            <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                            <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                            <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                            <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                            <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                        </div> :
                                                                                        user.votes > 1 && user.votes < 2 ?
                                                                                            <div>
                                                                                                <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                <HalfStar style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                            </div> :
                                                                                            user.votes === 2 ?
                                                                                                <div>
                                                                                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                </div> :
                                                                                                user.votes > 2 && user.votes <= 2.9 ?
                                                                                                    <div>
                                                                                                        <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                        <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                        <HalfStar style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                        <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                        <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                    </div> :
                                                                                                    user.votes === 3 ?
                                                                                                        <div>
                                                                                                            <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                            <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                            <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                            <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                            <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                        </div> :
                                                                                                        user.votes > 3 && user.votes < 4 ?
                                                                                                            <div>
                                                                                                                <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                <HalfStar style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                            </div> :
                                                                                                            user.votes === 4 ?
                                                                                                                <div>
                                                                                                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                </div> :
                                                                                                                user.votes > 4 && user.votes < 5 ?
                                                                                                                    <div>
                                                                                                                        <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                        <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                        <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                        <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                        <HalfStar style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                    </div> :
                                                                                                                    user.votes === 5 ?
                                                                                                                        <div>
                                                                                                                            <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                            <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                            <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                            <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                            <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                        </div> : null
                                                                        }
                                                                    </div>
                                                                    <br />
                                                                    {/* number of reviews */}
                                                                    {
                                                                        user.number_of_votes === 1 ?
                                                                            <small>{user.number_of_votes + ' '}review</small> :
                                                                            <small>{user.number_of_votes + ' '}reviews</small>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card> :
                                            null

                                    }

                                </div>
                                {/* Graph Ends */}
                            </div>

                            {/* Reviews */}
                            <div>
                                <h3>Reviews</h3>
                                <div style={{ marginBottom: '30px' }} className=''>
                                    {
                                        this.state.loading ?
                                            <p>Loading reviews...</p> :

                                            this.state.votes_list.length === 0 ?
                                                <div>
                                                    <Card style={{ backgroundColor: white }}>
                                                        <div className='row'>
                                                            <div className='col-8-sm'>
                                                                <CardContent style={{ color: black }}>
                                                                    <h4>No reviews have been made yet</h4>
                                                                </CardContent>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div> :

                                                this.getVotes().map((vote, i) => (
                                                    <div key={i}>
                                                        <Card
                                                            style={{ backgroundColor: white }}
                                                        >
                                                            <div className='row'>
                                                                <div className='col-4-sm'>
                                                                    <PicNWordsSmall push={this.props.history.push} user_id={vote.created_by} />

                                                                </div>
                                                                <div className='col-8-sm left'>
                                                                    <CardContent style={{ color: black }}>
                                                                        <span
                                                                            style={{ color: black }}
                                                                            onClick={() => {
                                                                                this.props.history.push('/user/' + vote.created_by)
                                                                            }}
                                                                        >
                                                                            {vote.created_by_name}
                                                                        </span>
                                                                        <br />
                                                                        <span style={{ color: black }}><Moment fromNow>{vote.created}</Moment></span>
                                                                        <br />
                                                                        {vote.review}
                                                                        <br /><br />
                                                                        {/* <Star style={{color: '#2e95c6', marginTop: '3px'}}/> */}
                                                                        <p style={{
                                                                            marginTop: '-5px',
                                                                            fontSize: '20px',
                                                                            display: 'inline-block'
                                                                        }}>
                                                                            {vote.score}
                                                                        </p>
                                                                        <div style={{
                                                                            display: 'inline-block',
                                                                            marginBottom: '-10px',
                                                                            paddingLeft: '5px'
                                                                        }}>
                                                                            {
                                                                                vote.score === 0 ?
                                                                                    <div>
                                                                                        <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                        <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                        <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                        <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                        <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                    </div> :
                                                                                    vote.score === 1 ?
                                                                                        <div>
                                                                                            <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                            <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                            <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                            <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                            <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                        </div> :
                                                                                        vote.score === 2 ?
                                                                                            <div>
                                                                                                <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                            </div> :
                                                                                            vote.score === 3 ?
                                                                                                <div>
                                                                                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                </div> :
                                                                                                vote.score === 4 ?
                                                                                                    <div>
                                                                                                        <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                        <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                        <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                        <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                        <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                    </div> :
                                                                                                    vote.score === 5 ?
                                                                                                        <div>
                                                                                                            <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                            <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                            <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                            <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                            <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                        </div> : null
                                                                            }
                                                                        </div>
                                                                    </CardContent>
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                ))}

                                    {
                                        this.state.votes_list.length >= 7 ?
                                            <Button
                                                onClick={this.toggle}
                                                label={this.state.full_list_open ? 'less reviews' : 'more reviews'}
                                                secondary={true}
                                                fullWidth={true}
                                            >
                                            </Button> : null
                                    }
                                    <div>

                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
        )
    }
}