import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import ReactDOM from 'react-dom';
import App from './App';

// Define your Material-UI theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#2E95C6',
    },
    secondary: {
      main: '#fafafa',
    },
    text: {
      primary: '#fff',
      secondary: '#fafafa',
    },
    background: {
      default: '#343434',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

// Render the app with Material-UI theme and Router
ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router>
          <App />
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
