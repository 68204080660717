import React, { useState, useEffect } from 'react';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Menu, MenuItem, CircularProgress } from '@mui/material';
import { getUser, changeAccountStatus, changeAccountType, adminSendMail } from '../../utils';
import SettingsIcon from '@mui/icons-material/Settings';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    table: {
        width: "500px",
    },
    icbtn: {
        backgroundColor: "green"
    }
});

function createData(field, value) {
    return { field, value };
}

export default function UserActivity(props) {
    const classes = useStyles();
    const [data, setData] = useState(null);
    const [rows, setRows] = useState(null);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);

    const reload = () => {
        if (props.user) {
            setLoading(true);
            getUser(props.user).then((data) => {
                setData(data);
                setLoading(false);
            });
        }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose = (e) => {
        const options = ["activate", "deactivate"];
        const i = options.indexOf(e.target.id.toLowerCase());
        if (e.target.id && i > -1) {
            const status = i === 0 ? true : false;
            const user = JSON.parse(localStorage.getItem('user'));
            changeAccountStatus({ id: props.user, account_status: status, by: user.id }).then((data3) => {
                if (data3.status === true) {
                    adminSendMail({
                        b_id: user.id,
                        by: user.email,
                        to_id: props.user,
                        to: data.email,
                        name: data.first_name,
                        subject: `Your account has been ${status ? "Activated" : "Deactivated"}`,
                        message: `We would like to inform you that your account has been ${status ? "Activated" : "Deactivated"} by us.`,
                        message2: status ? "You may now use your account again and this time please abide by our rules." : "If you believe this is an error or would like to know why you've been blocked, please contact us at: hello@upstar.co.za ."
                    }).then((data2) => {
                        if (data2.status) {
                            // Handle success
                        }
                    });
                    props.setEnabled(status);
                    reload();
                }
            });
        }
        setAnchorEl(null);
    };

    const handleClose2 = (e) => {
        const options = ["admin", "user"];
        const i = options.indexOf(e.target.id.toLowerCase());
        if (e.target.id && i > -1) {
            const acc = options[i];
            const user = JSON.parse(localStorage.getItem('user'));
            changeAccountType({ id: props.user, account_type: acc, by: user.id }).then((data2) => {
                if (data2.status === true) {
                    adminSendMail({
                        b_id: user.id,
                        by: user.email,
                        to_id: props.user,
                        to: data.email,
                        name: data.first_name,
                        subject: `You have been made a ${acc}`,
                        message: `We would like to inform you that you have been made a ${acc} by admin user ${user.first_name} ${user.last_name}.`,
                        message2: i === 0 ? "You may now use services and tools that are only available to admins. Login to your account now and explore!" :
                            "If you believe this is an error or would like to know why you are no longer an admin, contact us at: hello@upstar.co.za ."
                    }).then((data3) => {
                        if (data3.status) {
                            // Handle success
                        }
                    });
                    reload();
                }
            });
        }
        setAnchorEl2(null);
    };

    useEffect(() => {
        reload();
    }, [props.user]);

    useEffect(() => {
        if (data) {
            const x = [
                createData("Name", data.first_name + " " + data.last_name),
                createData("Email Address", data.email),
                createData("Cellphone Number", data.mobile ? data.mobile : "N/A"),
                createData("User Privacy", data.show_profile ? "Public" : "Private"),
                createData("Account Status", data.account_status ? "Active" : "Blocked"),
                createData("Account Type", data.account_type),
                createData("Last Login", new Date(data.last_login).toLocaleDateString()),
                createData("Visit Profile", <a href={`www.upstar.co.za/#/${data.id}`}>Click Here</a>)
            ];
            setRows(x);
            props.setEnabled(data.account_status);
        }
    }, [data]);

    return (
        <Paper style={{ display: "inline-block" }}>
            {loading ? <div className='center'><CircularProgress size={40} thickness={5} /></div> :
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Field</TableCell>
                            <TableCell align="right">Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows && props.user && rows.map((row, i) => (
                            <TableRow key={i}>
                                <TableCell component="th" scope="row">{row.field}</TableCell>
                                <TableCell align="right">
                                    {row.field === "Account Status" && props.user !== userObj.id ? (
                                        <div>
                                            {row.value}
                                            <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} aria-label="settings" className={classes.icbtn} size="small">
                                                <SettingsIcon fontSize="inherit" />
                                            </IconButton>
                                            <Menu
                                                id="simple-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}
                                            >
                                                <MenuItem onClick={handleClose} id={"activate"} disabled={row.value === "Active"}>Activate</MenuItem>
                                                <MenuItem onClick={handleClose} id={"deactivate"} disabled={row.value === "Blocked"}>Block</MenuItem>
                                            </Menu>
                                        </div>
                                    ) : (
                                        row.field === "Account Type" && props.user !== userObj.id ? (
                                            <div>
                                                {row.value}
                                                <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick2} aria-label="settings" className={classes.icbtn} size="small">
                                                    <SettingsIcon fontSize="inherit" />
                                                </IconButton>
                                                <Menu
                                                    id="simple-menu"
                                                    anchorEl={anchorEl2}
                                                    keepMounted
                                                    open={Boolean(anchorEl2)}
                                                    onClose={handleClose2}
                                                >
                                                    <MenuItem onClick={handleClose2} id={"admin"} disabled={row.value === "admin"}>make admin</MenuItem>
                                                    <MenuItem onClick={handleClose2} id={"user"} disabled={row.value === "user"}>make user</MenuItem>
                                                </Menu>
                                            </div>
                                        ) : (
                                            <div>{row.value}</div>
                                        )
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            }
        </Paper>
    );
}
