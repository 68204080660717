import React, { Component } from 'react';
import { userlistlanding } from '../../utils';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import PicNWords from '../../components/pic-n-words';

export default class ImageGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            loading: false,
            tile_width: 180
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        userlistlanding()
            .then(results => {
                this.setState({
                    loading: false,
                    users: results
                });
            })
            .catch(error => {
                console.error('Error fetching user list:', error);
                this.setState({ loading: false });
            });

        // Set up resize event listener
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        // Clean up resize event listener
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        if (window.innerWidth < 500) {
            this.setState({ tile_width: 100 });
        } else {
            this.setState({ tile_width: 200 });
        }
    };

    handleNavigation = (path) => {
        this.props.push(path);
    };

    render() {
        const { users, loading } = this.state;

        let userProfiles = users.err ? null : users.filter(u => u && u.profile).map((user, i) => ({
            profile: user.profile,
            five_words: user.five_words,
            user_id: user._id,
            rehive_user_id: user.rehive_user_id
        }));

        return (
            <div>
                {loading ? (
                    <div className='center' style={{ marginTop: '100px' }}>
                        <CircularProgress size={90} thickness={6} />
                    </div>
                ) : (
                    <div className='center'>
                        <h3>Our Latest Stars</h3>
                        <p>The latest additions to our star-studded community</p>
                        <div className='container center'>
                            <Button
                                style={{ margin: '15px' }}
                                variant="contained"
                                color="primary"
                                onClick={() => this.handleNavigation('/search')}
                            >
                                Rate someone
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => this.handleNavigation('/mydashboard')}
                            >
                                My dashboard
                            </Button>
                        </div>
                        <br /><br />
                        {userProfiles && userProfiles.length > 0 ? (
                            userProfiles.map((u, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: 'inline-block',
                                        padding: '0',
                                        marginTop: '-14px',
                                        color: "#000000"
                                    }}
                                >
                                    <PicNWords push={this.handleNavigation} landing={true} user={u} />
                                </div>
                            ))
                        ) : (
                            <p>Hooray! Welcome to the Team. Either you're our first user or there aren't any users with profile pictures. <br /> If you'd like to appear here as well, set a profile picture. Ciao</p>
                        )}
                    </div>
                )}
            </div>
        );
    }
}
