import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

export default class Terms extends Component {
    constructor(props) {
        super(props);
        const user = localStorage.getItem("user");
        this.props.socket.emit("user-click-event", { event: "terms page", user });
    }

    render() {
        return (
            <div className='container'>
                <Card sx={{ backgroundColor: '#fff', marginTop: '10px', position: 'relative' }}>
                    <IconButton
                        onClick={this.props.close}
                        sx={{
                            position: 'absolute',
                            right: '5px',
                            top: '5px',
                            color: '#000',
                            fontSize: '32px',
                            cursor: 'pointer'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <CardContent sx={{ color: '#343434' }}>
                        <p>Terms and conditions content goes here...</p>
                    </CardContent>
                    <br/>
                    <Button
                        sx={{
                            margin: 'auto',
                            display: 'block',
                            marginBottom: '10px'
                        }}
                        variant="contained"
                        color="primary"
                        onClick={this.props.close}
                    >
                        Back
                    </Button>
                    <br/>
                </Card>
            </div>
        );
    }
}
