import React, { Component } from 'react';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import Star from '@mui/icons-material/Star';
import StarBorder from '@mui/icons-material/StarBorder';
import HalfStar from '@mui/icons-material/StarHalf';
import StarComponent from '../../components/star_component';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { CircularProgress } from '@mui/material';
import Moment from 'react-moment';
import { getUser, votes, editVote } from '../../utils';
import RadioButton from '@mui/material/Radio';
import RadioButtonGroup from '@mui/material/RadioGroup';
// import ArrowUp from 'material-ui/svg-icons/navigation/arrow-drop-up';
// import ArrowDown from 'material-ui/svg-icons/navigation/arrow-drop-down';

const styles = {
    chip: {
        margin: 4,
        display: 'inline-block'
    },
    wrapper: {
        display: 'inline-block'
    },
    block: {
        maxWidth: 250,
    },
    radioButton: {
        marginBottom: 16,
    },
};


export default class RatingDash extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            open_thanks: false,
            edit_review_thanks: false,

            review: '',
            edit_review: '',
            votee: null,
            user: null,
            rating: 0,
            full_list_open: false,

            loading: false,
            loading_submit: false,
            loading_edit_submit: false,

            editReviewOpen: false,
            vote_id: '',

            relationship: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.onRatingChange = this.onRatingChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.editReviewSubmit = this.editReviewSubmit.bind(this);

        var x = localStorage.getItem("user");
        this.props.socket.emit("user-click-event", { event: "quick-rate-dash page", user: x });
    }

    componentDidMount() {
        this.setState({ loading: true })
        getUser(this.props.match.params.id)
            .then(result => {
                // console.log("RESULT RATING DASH", result);
                const voteeData = result
                voteeData.vote_list = voteeData && voteeData.vote_list && voteeData.vote_list;
                // console.log('vote_list', voteeData.vote_list);
                this.setState({
                    votee: voteeData,
                    user: JSON.parse(localStorage.getItem('user')),
                    loading: false,
                })
            })
    }

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleEditReviewOpen = () => {
        this.setState({ editReviewOpen: true });
    };

    handleClose = () => {
        this.setState({ open: false, review: '', rating: 0, err: false, open_thanks: false });
    };

    handleEditReviewClose = () => {
        this.setState({ editReviewData: false, err: false });
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    editReviewHandleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onRatingChange = (nextValue, prevValue, name) => {
        this.setState({
            rating: nextValue
        });
    }

    editRatingChange = (nextValue, prevValue, name) => {
        const voteeData = this.state.editReviewData
        voteeData.score = nextValue
        this.setState({ editReviewData: voteeData });
    }

    refresh = () => {
        window.location.reload();
    }

    onSubmit = (e) => {
        e.preventDefault();
        //console.log(this.state.review);
        // console.log(this.state);
        console.log('relationship', this.state.relationship)
        var data = {
            'created_for': this.state.votee.id,
            'created_by': this.state.user.id,
            'score': this.state.rating,
            'review': this.state.review,
            'created_by_name': this.state.user.first_name + ' ' + this.state.user.last_name,
            'profile': this.state.user.profile,
            'relationship': this.state.relationship
        }
        this.setState({ loading_submit: true });
        votes(data)
            .then(results => {
                // console.log('VOTES RESULTS', results);
                if (results.status === 400) {
                    this.setState({ loading: false, err: results.message })
                }
                if (results.err) {
                    this.setState({ err: results.err, loading_submit: false })
                } else {
                    this.setState({ open_thanks: true, loading_submit: false });
                }
            });
    }

    editReviewSubmit = (e) => {
        e.preventDefault();

        // console.log(this.state.edit_review);
        let voteID = this.state.vote_id;
        let data = {
            'score': this.state.editReviewData.score,
            'review': this.state.edit_review,
            'created_for': this.state.votee.id,
        }
        this.setState({ loading_edit_submit: true });
        editVote(data, voteID)
            .then(results => {
                if (results.status === 400) {
                    this.setState({ edit_review_thanks: false, err: results.message })
                }
                if (results.err) {
                    this.setState({ err: results.err, loading_edit_submit: false })
                }
                else {
                    this.setState({ edit_review_thanks: true, loading_edit_submit: false });
                }
            })
    }

    toggle = () => {
        this.setState({
            full_list_open: !this.state.full_list_open
        });
    }

    getVotes() {

        let vote_list = this.state.votee && this.state.votee.vote_list;
        let sort_list = vote_list.sort((a, b) => Date.parse(a.created) - Date.parse(b.created));
        // console.log('list by date', sort_list.reverse());
        let list = sort_list.reverse();

        if (this.state.full_list_open) {
            return list;
        }
        return list.slice(0, 6);
    }

    // limit_vote() {

    //     const logged_in_user = JSON.parse(localStorage.getItem('user'));
    //     const ratee = this.state.votee;


    // }

    // graph data
    // thisMonthRating() {
    //     if ((this.state.votee && this.state.votee.avg_last_month !== null) && (this.state.votee && this.state.votee.avg_last_month !== undefined) ) {
    //         let avg = (this.state.votee && this.state.votee.votes) - (this.state.votee && this.state.votee.avg_last_month);
    //         return avg.toFixed(1)
    //     }
    //     else {
    //         return this.state.votee && this.state.votee.votes.toFixed(1)
    //     }
    // }

    // today() {
    //     if ((this.state.votee && this.state.votee.averages.today_votes  !== null) && (this.state.votee && this.state.votee.averages.today_votes !== undefined)) {
    //         return this.state.votee && this.state.votee.averages.today_votes.toFixed(1)
    //     } else {
    //         return 0
    //     }
    // }

    // lastWeek() {
    //     if ((this.state.votee && this.state.votee.averages.last_week_votes  !== null) && (this.state.votee && this.state.votee.averages.last_week_votes !== undefined)) {
    //         return this.state.votee && this.state.votee.averages.last_week_votes.toFixed(1)
    //     } else {
    //         return 0
    //     }
    // }

    // twoWeeks() {
    //     if ((this.state.votee && this.state.votee.averages.two_week_votes  !== null) && (this.state.votee && this.state.votee.averages.two_week_votes !== undefined)) {
    //         return this.state.votee && this.state.votee.averages.two_week_votes.toFixed(1)
    //     } else {
    //         return 0
    //     }
    // }

    // threeWeeks() {
    //     if ((this.state.votee && this.state.votee.averages.three_week_votes  !== null) && (this.state.votee && this.state.votee.averages.three_week_votes !== undefined)) {
    //         return this.state.votee && this.state.votee.averages.three_week_votes.toFixed(1)
    //     } else {
    //         return 0
    //     }
    // }

    render() {

        const { votee } = this.state;
        // console.log('TODAY AVG', votee && votee.avg_todays_vote);
        // console.log('VOTEE',votee );
        // console.log('VOTEE', votee && votee.vote_list );

        // console.log('id', votee_id)

        const user = JSON.parse(localStorage.getItem('user'));
        let user_id = user.id;

        let scoreArray = votee && votee.vote_list.map((vote) => vote.score);
        //console.log('score array', scoreArray)

        let scoreCount = votee && scoreArray.reduce((obj, item) => {
            if (!obj[item]) {
                obj[item] = 0;
            }
            obj[item]++;
            return obj;
        }, {});

        const data = [
            { name: '5', votes: votee && scoreCount['5'] },
            { name: '4', votes: votee && scoreCount['4'] },
            { name: '3', votes: votee && scoreCount['3'] },
            { name: '2', votes: votee && scoreCount['2'] },
            { name: '1', votes: votee && scoreCount['1'] },
            { name: '0', votes: votee && scoreCount['0'] }
        ]


        // needed for the monthly graph
        // const data2 = [
        //     {name: '3Weeks', votes: this.threeWeeks() },
        //     {name: '2Weeks', votes: this.twoWeeks() },
        //     {name: 'LastWeek',  votes: this.lastWeek()},
        //     {name: 'Today',  votes: this.today() }
        // ]

        const actions = [
            <Button
                label="Close"
                primary={true}
                onClick={this.handleClose}
            />,
            <Button
                label="Submit"
                primary={true}
                onClick={(e => this.onSubmit(e))}
            />,
        ];

        const upstar_actions = [
            <Button
                label='cool'
                primary={true}
                onClick={() => window.location = '/quick-land'}
            />
        ];

        //   edit review action buttons
        const editReviewActions = [
            <Button
                label="Close"
                primary={true}
                onClick={this.handleEditReviewClose}
            />,
            <Button
                label="Submit"
                primary={true}
                keyboardFocused={true}
                onClick={(e => this.editReviewSubmit(e))}
            />,
        ];

        const editReview_actions = [
            <Button
                label='cool'
                primary={true}
                onClick={this.refresh}
            />
        ];

        return (
            <div className='container'>
                {
                    this.state.loading ?
                        <div className='center'><CircularProgress size={80} thickness={5} /></div> :
                        <div>
                            <div className='center'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <Card style={{ backgroundColor: white, color: '#343434' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                                                <img
                                                    style={{
                                                        marginTop: '10px',
                                                        height: '270px',
                                                        width: '270px',
                                                        borderRadius: '5%',
                                                        objectFit: 'cover'
                                                    }}
                                                    src={(votee && votee.profile) || '/img/avatar.png'}
                                                    className='container'
                                                    alt=''
                                                />
                                            </div>

                                            <h1 style={{ marginTop: '-1px', marginBottom: '-15px' }}>{votee && votee.first_name + ' ' + votee.last_name}</h1>
                                            {
                                                votee && votee.user_company === '' ?
                                                    <div>
                                                        <p></p><br />
                                                    </div> :
                                                    <p>{votee && votee.user_company}</p>
                                            }


                                            <div className='center'>
                                                {
                                                    votee && votee.five_words === undefined ?
                                                        null :
                                                        votee && votee.five_words.map((word, index) => (
                                                            <div key={index} style={styles.wrapper}>
                                                                {
                                                                    word === "" ?
                                                                        null :
                                                                        <Chip
                                                                            style={styles.chip}
                                                                        >
                                                                            {word}
                                                                        </Chip>
                                                                }
                                                            </div>
                                                        ))
                                                }
                                            </div>

                                            <div className='container'>
                                                <div className='row'>
                                                    {
                                                        votee && votee.vote_list.length === 0 ?
                                                            <div className='col-12'>
                                                                <p style={{ marginBottom: '-15px', marginTop: '-15px' }}>Not yet rated</p>
                                                            </div> :
                                                            <div className='col-6'>
                                                                {
                                                                    (votee && votee.position === '') || (votee && votee.position === undefined) ?
                                                                        <p style={{ paddingTop: '55px' }}>Position not provided for ranking</p> :
                                                                        <div>
                                                                            <h5 style={{ marginBottom: '-15px' }} >{votee && votee.first_name} is </h5>
                                                                            <h1 style={{ marginBottom: '-15px' }} > #1</h1>
                                                                            <h5>of all {votee && votee.position}'s</h5>
                                                                        </div>
                                                                }
                                                            </div>
                                                    }

                                                    <div className='col-6'>
                                                        {
                                                            votee && votee.vote_list.length === 0 ?
                                                                null :
                                                                <div>
                                                                    <h5 style={{ marginBottom: '-15px' }}>{votee && votee.first_name}'s rating</h5>
                                                                    <h1 style={{ marginBottom: '-3px' }}>{votee && votee.votes && votee.votes.toFixed(1)}</h1>
                                                                </div>
                                                        }

                                                        {
                                                            votee && votee.vote_list.length === 0 ?
                                                                null :
                                                                votee && votee.votes === 0 ?
                                                                    <div>
                                                                        <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                        <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                        <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                        <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                        <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                    </div> :
                                                                    votee && votee.votes > 0 && votee && votee.votes < 1 ?
                                                                        <div>
                                                                            <HalfStar style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                            <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                            <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                            <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                            <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                        </div> :
                                                                        votee && votee.votes === 1 ?
                                                                            <div>
                                                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                            </div> :
                                                                            votee && votee.votes > 1 && votee && votee.votes < 2 ?
                                                                                <div>
                                                                                    <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                    <HalfStar style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                    <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                    <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                    <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                </div> :
                                                                                votee && votee.votes === 2 ?
                                                                                    <div>
                                                                                        <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                        <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                        <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                        <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                        <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                    </div> :
                                                                                    votee && votee.votes > 2 && votee && votee.votes < 3 ?
                                                                                        <div>
                                                                                            <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                            <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                            <HalfStar style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                            <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                            <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                        </div> :
                                                                                        votee && votee.votes === 3 ?
                                                                                            <div>
                                                                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                            </div> :
                                                                                            votee && votee.votes > 3.0 && votee && votee.votes < 4 ?
                                                                                                <div>
                                                                                                    <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                    <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                    <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                    <HalfStar style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                    <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                </div> :
                                                                                                votee && votee.votes === 4 ?
                                                                                                    <div>
                                                                                                        <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                        <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                        <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                        <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                        <StarBorder style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                    </div> :
                                                                                                    votee && votee.votes > 4 && votee && votee.votes < 5 ?
                                                                                                        <div>
                                                                                                            <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                            <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                            <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                            <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                            <HalfStar style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                        </div> :
                                                                                                        votee && votee.votes === 5 ?
                                                                                                            <div>
                                                                                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                                <Star style={{ color: "#2E95C6", height: '40px', width: 'auto' }} />
                                                                                                            </div> : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            {/* Rate now button */}
                                            <div className='container'>
                                                <Button
                                                    onClick={this.handleOpen}
                                                    label="Rate now"
                                                    secondary={true}
                                                    type='submit'

                                                />
                                                {/* Rating pop up */}
                                                <div className='container'>
                                                    <Dialog
                                                        style={{ textAlign: 'center' }}
                                                        title={votee && votee.first_name + ' ' + votee.last_name}
                                                        actions={actions}
                                                        modal={false}
                                                        open={this.state.open}
                                                        autoScrollBodyContent={true}
                                                        onRequestClose={this.handleClose}
                                                    >
                                                        <div>
                                                            <p>Not clicking a star will result in a zero rating</p>
                                                            <StarComponent rating={this.state.score} onRatingChange={this.onRatingChange} />
                                                            <TextField
                                                                name='review'
                                                                placeholder='Review'
                                                                value={this.state.review}
                                                                onChange={this.handleChange}
                                                                multiLine={true}
                                                                rows={2}
                                                                maxRows={4}
                                                            />
                                                            <br />
                                                            {
                                                                this.state.err ?
                                                                    <span style={{ color: 'red' }}>{this.state.err}<br /></span> :
                                                                    null
                                                            }
                                                            <p>How do you know {votee && votee.first_name + ' ' + votee.last_name}?</p>
                                                            <RadioButtonGroup name="shipSpeed" className='left' >
                                                                <RadioButton
                                                                    value='friend'
                                                                    label="Friend"
                                                                    style={styles.radioButton}
                                                                    onClick={() => this.setState({ relationship: 'friend' })}
                                                                />
                                                                <RadioButton
                                                                    value='customer'
                                                                    label="Customer at their place of work"
                                                                    style={styles.radioButton}
                                                                    onClick={() => this.setState({ relationship: 'customer' })}
                                                                />
                                                                <RadioButton
                                                                    value='boss'
                                                                    label="I am their manager or boss"
                                                                    style={styles.radioButton}
                                                                    onClick={() => this.setState({ relationship: 'boss' })}
                                                                />
                                                                <RadioButton
                                                                    value='colleague'
                                                                    label="I am their work colleague"
                                                                    style={styles.radioButton}
                                                                    onClick={() => this.setState({ relationship: 'colleague' })}
                                                                />
                                                                <RadioButton
                                                                    value='subordinate'
                                                                    label="They are my manager or boss"
                                                                    style={styles.radioButton}
                                                                    onClick={() => this.setState({ relationship: 'subordinate' })}
                                                                />
                                                                <RadioButton
                                                                    value='other'
                                                                    label="Other"
                                                                    style={styles.radioButton}
                                                                    onClick={() => this.setState({ relationship: 'other' })}
                                                                />
                                                            </RadioButtonGroup>
                                                        </div>
                                                    </Dialog>
                                                </div>
                                                <div>
                                                    {
                                                        this.state.loading_submit ?
                                                            <Dialog
                                                                modal={true}
                                                                open={this.state.open}
                                                            >
                                                                <p>Submitting....</p>
                                                            </Dialog> :
                                                            <Dialog
                                                                style={{ textAlign: 'center' }}
                                                                title={"Thank you for rating"}
                                                                actions={upstar_actions}
                                                                modal={true}
                                                                open={this.state.open_thanks}
                                                            >
                                                            </Dialog>
                                                    }
                                                </div>
                                            </div>
                                            <br />
                                        </Card>
                                    </div>


                                    {/* Graphs */}
                                    <div className='col-12'>
                                        {
                                            votee && votee.vote_list.length === 0 ?
                                                null :
                                                <Card style={{ backgroundColor: '#FAFAFA' }}>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='row'>
                                                                <div className='col-6 center' style={{ marginBottom: '-30px', marginLeft: '-40px' }}>
                                                                    <h4 style={{ color: '#343434', marginBottom: '-15px' }}>Review summary</h4>
                                                                    <ComposedChart layout="vertical" width={300} height={240} data={data}
                                                                        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                                                                        <CartesianGrid stroke='#f5f5f5' />
                                                                        <XAxis display='none' type="number" />
                                                                        <YAxis dataKey="name" type="category" />
                                                                        <Tooltip />
                                                                        <Bar dataKey='votes' barSize={20} fill='#2E95C6' />
                                                                    </ComposedChart>
                                                                </div>
                                                                <div className='col-6 center'>
                                                                    <div style={{ color: '#343434' }}>
                                                                        <h2 style={{ marginBottom: '-5px' }}>{votee && votee.votes && votee.votes.toFixed(1)}</h2>

                                                                        <div style={{ marginBottom: '-20px' }}>
                                                                            {
                                                                                votee && votee.votes === 0 ?
                                                                                    <div>
                                                                                        <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                        <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                        <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                        <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                        <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                    </div> :
                                                                                    votee && votee.votes > 0 && votee && votee.votes < 1 ?
                                                                                        <div>
                                                                                            <HalfStar style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                            <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                            <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                            <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                            <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                        </div> :
                                                                                        votee && votee.votes === 1 ?
                                                                                            <div>
                                                                                                <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                            </div> :
                                                                                            votee && votee.votes > 1 && votee && votee.votes < 2 ?
                                                                                                <div>
                                                                                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                    <HalfStar style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                </div> :
                                                                                                votee && votee.votes === 2 ?
                                                                                                    <div>
                                                                                                        <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                        <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                        <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                        <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                        <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                    </div> :
                                                                                                    votee && votee.votes > 2 && votee && votee.votes < 3 ?
                                                                                                        <div>
                                                                                                            <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                            <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                            <HalfStar style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                            <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                            <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                        </div> :
                                                                                                        votee && votee.votes === 3 ?
                                                                                                            <div>
                                                                                                                <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                            </div> :
                                                                                                            votee && votee.votes > 3 && votee && votee.votes < 4 ?
                                                                                                                <div>
                                                                                                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                    <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                    <HalfStar style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                    <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                </div> :
                                                                                                                votee && votee.votes === 4 ?
                                                                                                                    <div>
                                                                                                                        <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                        <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                        <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                        <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                        <StarBorder style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                    </div> :
                                                                                                                    votee && votee.votes > 4 && votee && votee.votes < 5 ?
                                                                                                                        <div>
                                                                                                                            <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                            <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                            <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                            <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                            <HalfStar style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                        </div> :
                                                                                                                        votee && votee.votes === 5 ?
                                                                                                                            <div>
                                                                                                                                <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                                <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                                <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                                <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                                <Star style={{ color: "#2E95C6", height: '20px', width: 'auto' }} />
                                                                                                                            </div> : null
                                                                            }
                                                                        </div>

                                                                        <br />

                                                                        {
                                                                            votee && votee.vote_list.length === 1 ?
                                                                                <small>{votee && votee.vote_list.length + ' '}review </small> :
                                                                                <small>{votee && votee.vote_list.length + ' '}reviews </small>
                                                                        }
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                        }
                                        {/* This is the monthly graph */}
                                        {/* {
                                    votee && votee.vote_list.length  === 0 ?
                                    null : 
                                    <Card style={{ backgroundColor: '#FAFAFA', marginTop: '10px'}}>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='row'>
                                                    <div className='col-6 center' style={{ marginLeft: '-40px'}}>
                                                        <h4 style={{color: '#343434', marginBottom: '-4px' }}>This month</h4>
                                                        <BarChart width={330} height={240} data={data2} margin={{top: 5, right: 20, left: 10, bottom: 5}}>
                                                            <CartesianGrid strokeDasharray="3 3"/>
                                                            < XAxis dataKey="name" />
                                                            <YAxis/>
                                                            <Tooltip/>
                                                            <Bar dataKey='votes' barSize={25} fill='#2E95C6'/>
                                                        </BarChart>
                                                    </div>
                                                    <div className="col-6 center">
                                                            <div style={{ marginBottom: '-25px' }}>
                                                            {
                                                                this.thisMonthRating() === null ?
                                                                null:
                                                                <h2 style={{  color: '#343434'}}>
                                                                    { this.thisMonthRating() } 
                                                                    {
                                                                        this.thisMonthRating() > 0 ?
                                                                        <ArrowUp style={{ color: '#2E95C6', height: '60px', width: 'auto',  marginBottom: '-25px' }}/> :
                                                                        this.thisMonthRating() < 0 ?
                                                                        <ArrowDown style={{ color: '#2E95C6', height: '50px', width: 'auto',  marginBottom: '-18px' }}/> :
                                                                        null
                                                                    }
                                                                </h2>
                                                            }
                                                            </div>
                                                            <div>
                                                                {
                                                                    this.thisMonthRating() > 0 ?
                                                                    <small style={{ color: '#343434'}}>rating increase this month</small> :
                                                                    this.thisMonthRating() < 0 ?
                                                                    <small style={{ color: '#343434'}}>rating decrease this month</small> :
                                                                    null
                                                                }
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                } */}
                                    </div>

                                </div>
                            </div>


                            {/* Reviews */}
                            <div>
                                <div style={{ marginBottom: '30px' }} className='center'>
                                    <h3>Reviews</h3>
                                    {

                                        votee && votee.vote_list.length === 0 ?
                                            <div>
                                                <Card style={{ backgroundColor: white }}>
                                                    <div className='row'>
                                                        <div className='col-8-sm'>
                                                            <CardContent style={{ color: black }}>
                                                                <h4>No reviews have been made yet</h4>
                                                            </CardContent>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div> :

                                            votee && this.getVotes().map((vote, i) => (
                                                <div key={i}>
                                                    <Card
                                                        style={{ backgroundColor: white }}
                                                    >
                                                        <div className='row'>
                                                            <div className='col-4-sm'>
                                                                <img
                                                                    style={{
                                                                        marginTop: '10px',
                                                                        height: '80px',
                                                                        width: '80px',
                                                                        borderRadius: '100%',
                                                                        objectFit: 'cover'
                                                                    }}
                                                                    src={vote.profile || '/img/avatar.png'}
                                                                    className='container'
                                                                    alt=''
                                                                />
                                                            </div>
                                                            <div className='col-8-sm left' style={{ position: 'relative' }}>
                                                                <CardContent style={{ color: black }}>
                                                                    <span
                                                                        style={{ color: black }}
                                                                        onClick={() => {
                                                                            if (user_id === vote.created_by) {
                                                                                this.props.history.push('/mydashboard')
                                                                            } else {
                                                                                this.props.history.push('/user/' + vote.created_by)
                                                                                window.location.reload();
                                                                            }
                                                                        }}
                                                                    >
                                                                        {vote.created_by_name}
                                                                    </span>
                                                                    <br />
                                                                    <span style={{ color: black }}><Moment fromNow>{vote.created}</Moment></span>
                                                                    <br />
                                                                    {vote.review}
                                                                    <br />
                                                                    <Star style={{ color: '#2e95c6' }} />
                                                                    {vote.score}
                                                                </CardContent>
                                                                {
                                                                    user.id === vote.created_by ?
                                                                        <div>
                                                                            <div>
                                                                                {/* Edit review button */}
                                                                                <Button style={{ position: 'absolute', right: '10px', top: '80px' }}
                                                                                    label="Edit review" primary={true} onClick={() => {
                                                                                        // console.log("VOTE!!!", vote);
                                                                                        this.setState({
                                                                                            editReviewData: vote,
                                                                                            edit_review: vote.review,
                                                                                            vote_id: vote._id
                                                                                        })
                                                                                    }}
                                                                                />
                                                                                <Dialog
                                                                                    style={{ textAlign: 'center' }}
                                                                                    actions={editReviewActions}
                                                                                    modal={false}
                                                                                    open={this.state.editReviewData ? true : false}
                                                                                    onRequestClose={() => this.setState({ editReviewData: null })}
                                                                                >
                                                                                    <p> Edit rating for {votee && votee.first_name + ' ' + votee.last_name} </p>
                                                                                    Not clicking a star will result in a zero rating
                                                                                    <br />
                                                                                    <br />
                                                                                    {
                                                                                        this.state.editReviewData &&
                                                                                        <StarComponent rating={this.state.editReviewData.score} onRatingChange={this.editRatingChange} />
                                                                                    }

                                                                                    <TextField
                                                                                        name='edit_review'
                                                                                        value={this.state.edit_review}
                                                                                        onChange={this.editReviewHandleChange}
                                                                                        multiLine={true}
                                                                                        rows={2}
                                                                                        maxRows={4}
                                                                                    />
                                                                                    <br />
                                                                                    {
                                                                                        this.state.err ?
                                                                                            <span style={{ color: 'red' }}>{this.state.err}<br /></span> :
                                                                                            null
                                                                                    }
                                                                                </Dialog>
                                                                            </div>
                                                                            <div>
                                                                                {
                                                                                    this.state.loading_edit_submit ?
                                                                                        <Dialog
                                                                                            modal={true}
                                                                                            open={this.state.open}
                                                                                        >
                                                                                            <p>Updating your review</p>
                                                                                        </Dialog> :
                                                                                        <Dialog
                                                                                            style={{ textAlign: 'center' }}
                                                                                            title={"Your review has been changed. Thank You!"}
                                                                                            actions={editReview_actions}
                                                                                            modal={true}
                                                                                            open={this.state.edit_review_thanks}
                                                                                        >
                                                                                        </Dialog>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }

                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            ))}
                                    {
                                        // More or less button
                                        votee && votee.vote_list.length >= 7 ?
                                            <Button
                                                onClick={this.toggle}
                                                label={this.state.full_list_open ? 'less reviews' : 'more reviews'}
                                                secondary={true}
                                                fullWidth={true}
                                            >
                                            </Button>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                }
            </div>
        );
    }
}